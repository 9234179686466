import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import {
  getSubscriptionTextData,
  subscribeLoading,
} from "../../../store/actions/SubscribeAction";

import TabExam from "./TabExam";
import TabLibrary from "./TabLibrary";
import TabClasses from "./TabClasses";

const tabData = [
  {
    name: "Online Exam",
    icon: "digital-tachograph",
  },
  {
    name: "e-Library",
    icon: "icons",
  },
  {
    name: "Online Classes",
    icon: "chalkboard-teacher",
  },
];

const Subscribe = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(tabData[0].name.toLowerCase());

  const scholasticSubscriptionSourceData = useSelector(
    (state) => state.subscribe.scholasticSubscriptionSourceData
  );

  useEffect(() => {
    dispatch(subscribeLoading(true));
    dispatch(getSubscriptionTextData(history));
  }, [dispatch, history]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    // You can dispatch an action or do something when the tab changes
  };

  return (
    <Fragment>
      <Row>
        <Col className="top-heading-subtitle mb-3">
          <div className="text_blue">Subscription</div>
          <div className="text_blue">
            <strong>Academic Year:</strong> {props.currentSession ?props.currentSession: "NA"}
          </div>
        </Col>
      </Row>

      {props.scrollingText && (
        <Row>
          <Col xl={12}>
            <Marquee
              speed={100}
              gradient={false}
              style={{
                fontSize: 16,
                color: "#fff",
                backgroundColor: "#2e637b",
              }}
              pauseOnHover={true}
            >
              {props.scrollingText}
            </Marquee>
          </Col>
        </Row>
      )}

      <Row>
        <Col xl={12}>
          <div className="subscribe-tab">
            <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="online exam">
                  {activeTab === "online exam" ? <TabExam /> : null}
                </Tab.Pane>
                <Tab.Pane eventKey="e-library">
                  {activeTab === "e-library" ? <TabLibrary /> : null}
                </Tab.Pane>
                <Tab.Pane eventKey="online classes">
                  {activeTab === "online classes" ? <TabClasses /> : null}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.subscribe.showLoading,
    scrollingText: state.subscribe.scrollingText,
    currentSession: state.subscribe.currentSession,
    cartList: state.subscribe.cartList,
  };
};

export default connect(mapStateToProps)(Subscribe);
