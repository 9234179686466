import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"
import {
	Row,
	Col,
} from "react-bootstrap";

//Import Components
import ArchiveChartCard from '../../pages/PerformanceScoreNewUI/performanceComponent/ArchiveChartCard';
import ChartCard1 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard1';
import ChartCard2 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard2';
import ChartCard3 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard3';
import setBodyImage from '../../../setBodyImage';

import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'
import logofull from '../../../images/dashboard.png';

import { getgetScholasticSetModuleMockSubjectWiseDataDetails, performanceRequestAction, getCompareScholasticCompetitiveDataDataDetails, getCompareScholasticCompetitiveDataAction } from '../../../store/actions/ArchivePerformanceScoreAction';


const ArchiveOverallScholasticScore = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state)

	useEffect(() => {
		dispatch(getCompareScholasticCompetitiveDataAction([])); /* previous data blank */
		dispatch(performanceRequestAction(true))
		dispatch(getgetScholasticSetModuleMockSubjectWiseDataDetails(previousValue.group_subject_id,previousValue.main_state.standard, props.history)) /* get subject wise mock, module chapter data */
		dispatch(getCompareScholasticCompetitiveDataDataDetails('Competitive', previousValue.group_subject_id,previousValue.main_state.standard, props.history)) /* get Scholastic Competitive compare data  */

		setBodyImage(performerBackground) /* set change new background image */
		return () => {
			setBodyImage(dashboardBackground) /* quit page and restoring old background image */
		};

	}, []);

	const onShowDetailsHandler =(val, group_subject_id, group_name) => {
     history.push({ pathname: "/archive-subjectwise-scholastic-score", state: { cardno: val, group_subject_id: group_subject_id, group_name: group_name,class_no:previousValue.main_state.standard } })
	}

	const goPrevious = () => {
		history.goBack()
	}

	return (
		<>
			<div className="row ">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Archive performance score <i className="bi bi-chevron-right"></i> Archive scholastic performance score({location.state && location.state.group_name}) <i className="bi bi-chevron-right"></i> Archive  Overall Scholastic Performance Score</div>
				</div>
			</div>
			<div className="d-flex flex-row justify-content-between mb-auto p-2 align-items-center">
				{/* <div className="col-md-1"> */}
				<div className="col-md-1">
					<div className="top_back_container_overall" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
				<div className='d-flex flex-row align-items-center'>
					<div className="overall_right_heading mr-auto p-1">
					{location.state && location.state.group_name}
					</div>
					<img
						src={location.state && location.state.subject_image != '' ? location.state.subject_image : logofull}
						alt="creastest"
						// color={props.leftColor}
						className="overall_sub_image"
					/>
				</div>
			</div>

			<Row>
				<Col xl={6}>
					<ArchiveChartCard
					    standard={previousValue.main_state.standard}
						pageHeading="Score Spectrum"
						group_subject_id={previousValue && previousValue.group_subject_id}
					/>
				</Col>
				<Col xl={6}>
					{props.MockData != '' && props.ModuleData != '' && props.SetData != '' && props.scholasticLabel ?
						<ChartCard1
							pageHeading="Strength Analysis"
							scholasticLabel={props.scholasticLabel}
							MockData={props.MockData}
							ModuleData={props.ModuleData}
							SetData={props.SetData}
							group_subject_id={location.state.group_subject_id}
							group_name={location.state.group_name}
							showDetailsHandler={(val, group_subject_id, group_name)=>onShowDetailsHandler(val, group_subject_id, group_name)}
						/>
						: null}
				</Col>
				<Col xl={6}>
					{props.compareScholasticCompetitiveLabels != '' && props.compareScholasticCompetitiveDatasets != '' && !!props.compareScholasticCompetitiveDatasets ?
						<ChartCard2
							pageHeading="Scholastic/Competitive Index (%)"
							label={props.compareScholasticCompetitiveLabels}
							dataSets={props.compareScholasticCompetitiveDatasets}
						/>
						: null
					}
				</Col>
				<Col xl={6}>
					<ChartCard3
						pageHeading="Performance Analysis"
						scholasticLabel={props.scholasticLabel}
						MockData={props.MockData}
						ModuleData={props.ModuleData}
						SetData={props.SetData}
					/>
					{/* : null} */}
				</Col>

			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		MockData: state.archivePerformance.MockData,
		ModuleData: state.archivePerformance.ModuleData,
		SetData: state.archivePerformance.SetData,
		scholasticLabel: state.archivePerformance.scholasticLabel,
		compareScholasticCompetitiveDatasets: state.archivePerformance.compareScholasticCompetitiveDatasets,
		compareScholasticCompetitiveLabels: state.archivePerformance.compareScholasticCompetitiveLabels
	};
};
export default connect(mapStateToProps)(ArchiveOverallScholasticScore);