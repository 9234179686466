import React, { useContext, useEffect, useState } from "react";

/// React router dom
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  unlockScreenAction
} from '../store/actions/AuthActions';

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";


/// Charts
import ChartJs from "./components/charts/Chartjs";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ComingSoon from "./pages/ComingSoon";

import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

import Error404 from "./pages/Error404";

import LockScreen from "./pages/LockScreen";
import { ThemeContext } from "../context/ThemeContext";
/* ------ */
import DemoExam from "./pages/DemoExam";
// import ExamDetails from "./pages/ExamDetails";
import DemoScholasticExamStart from "./pages/DemoScholasticExamStart";
import DemoCompetitiveExamStart from "./pages/DemoCompetitiveExamStart";
import CompetitiveTypeLibrary from "./pages/CompetitiveTypeLibrary";
import CompetitiveNtseTypeLibrary from "./pages/CompetitiveNtseTypeLibrary";
import CompetitiveExamSelect from "./pages/CompetitiveExamSelect";
import CompetitiveAssessmentSelect from "./components/exam/CompetitiveAssessmentSelect";
import PdfView from "./components/exam/PdfView";
import AssessmentDetails from "./components/exam/AssessmentDetails";
import AssessmentList from "./components/exam/AssessmentList";
import AssessmentNewList from "./components/exam/AssessmentNewList";

// import ExamStartTest from "./pages/ExamStartTest";

import OnlineAssessmentDetails from "./components/exam/OnlineAssessmentDetails";
import OnlineAsscessmentCategory from "./components/exam/OnlineAsscessmentCategory";
import AssessmentCategoryWiseDetails from "./components/exam/AssessmentCategoryWiseDetails";
import CompetiTypeAssessment from "./components/exam/CompetiTypeAssessment";
import CompetitiveNtseType from "./components/exam/CompetitiveNtseType";

import ScholasticExam from "./pages/ScholasticExam";
import ScholasticExamStart from "./pages/OnlineExam/ScholasticExamStart";
import MoudleMockScholasticExamStart from "./pages/OnlineExam/MoudleMockScholasticExamStart";
import CompetitiveExamStart from "./pages/OnlineExam/CompetitiveExamStart";

///E-library
import DemoLibrary from "./pages/E-library/DemoLibrary";
import CompetitiveLibrary from "./pages/E-library/CompetitiveLibrary";
import ScholasticLibrary from "./pages/E-library/ScholasticLibrary";
import TypeLibrary from "./pages/E-library/TypeLibrary";
import BranchLibrary from "./pages/E-library/BranchLibrary";
import ExamDemoLibrary from "./pages/E-library/ExamDemoLibrary";
import LibraryCategoryWiseDetails from "./pages/E-library/LibraryCategoryWiseDetails";
import CompetiTypeLibrary from "./pages/E-library/CompetiTypeLibrary";
import PdfViewElibrary from "./pages/E-library/PdfViewElibrary";
import OnlineElibrarySubject from "./pages/E-library/OnlineElibrarySubject";
import LibraryScholasticList from "./pages/E-library/LibraryScholasticList";
import LibraryCompetitiveList from "./pages/E-library/LibraryCompetitiveList";
import CompetitiveTypeList from "./pages/E-library/CompetitiveTypeList";

///Subscribe
import Subscribe from "./pages/Subscription/Subscribe";
import PaymentGateWay from "./pages/Subscription/PaymentGateWay";
import PaymentSuccessful from "./pages/Subscription/PaymentSuccessful";
import PaymentFailure from "./pages/Subscription/PaymentFailure";
import SubscribeList from "./pages/Subscription/SubscribeList";
import PurchasedList from "./pages/Subscription/PurchasedList";
import PaymentAborted from "./pages/Subscription/PaymentAborted";
import OnlinePaymentIllegalAccess from "./pages/Subscription/OnlinePaymentIllegalAccess";

///PerformanceScore
// import PerformanceScore from "./pages/PerformanceScore/PerformanceScore";
// import OverallScholasticPerformanceScore from "./pages/PerformanceScore/OverallScholasticPerformanceScore";
// import SubjectWiseComparisonOn from "./pages/PerformanceScore/SubjectWiseComparisonOn";
// import AvgSetExams from "./pages/PerformanceScore/AvgSetExams";
// import SubjectScorecardSetWise from "./pages/PerformanceScore/SubjectScorecardSetWise";
// import ComparativeStudySetExams from "./pages/PerformanceScore/ComparativeStudySetExams";
// import SubjectModule from "./pages/PerformanceScore/SubjectModule";
// import OverallCompititivePerformanceScore from "./pages/PerformanceScore/OverallCompititivePerformanceScore";
// import CompititiveSetWiseScoreComparison from "./pages/PerformanceScore/CompititiveSetWiseScoreComparison";
// import PerformanceCategory from "./pages/PerformanceScore/PerformanceCategory";
// import ComparisonAverageScoreDifferentSubjects from "./pages/PerformanceScore/ComparisonAverageScoreDifferentSubjects";
// import ComparativeStudySetNtse from "./pages/PerformanceScore/ComparativeStudySetNtse";

///PerformanceScore New UI
import PerformanceCategoryPage from "./pages/PerformanceScoreNewUI/PerformanceCategoryPage";
import PerformanceCategoryOnlineExam from "./pages/PerformanceScoreNewUI/PerformanceCategoryOnlineExam";
import PerformanceCategoryElibrary from "./pages/PerformanceScoreNewUI/PerformanceCategoryElibrary";
import OverallScholasticScore from "./pages/PerformanceScoreNewUI/OverallScholasticScore";
import SubjectwiseScholasticScore from "./pages/PerformanceScoreNewUI/SubjectwiseScholasticScore";
import SubjectwiseAnalysisOnSet from "./pages/PerformanceScoreNewUI/SubjectwiseAnalysisOnSet";
import SubjectwiseAnalysisOnModule from "./pages/PerformanceScoreNewUI/SubjectwiseAnalysisOnModule";
import SubjectwiseAnalysisOnMock from "./pages/PerformanceScoreNewUI/SubjectwiseAnalysisOnMock";

import ChapterwiseAnalysisOnSet from "./pages/PerformanceScoreNewUI/ChapterwiseAnalysisOnSet";
import ChapterwiseAnalysisOnModule from "./pages/PerformanceScoreNewUI/ChapterwiseAnalysisOnModule";
import ChapterwiseAnalysisOnMock from "./pages/PerformanceScoreNewUI/ChapterwiseAnalysisOnMock";

import OverallCompetitiveScore from "./pages/PerformanceScoreNewUI/OverallCompetitiveScore";
import SubjectwiseCompetitiveScore from "./pages/PerformanceScoreNewUI/SubjectwiseCompetitiveScore";
import CompetitiveSubjectwiseAnalysisOnSet from "./pages/PerformanceScoreNewUI/CompetitiveSubjectwiseAnalysisOnSet";
import ElibraryPerformanceScore from "./pages/PerformanceScoreNewUI/ElibraryPerformanceScore";
import ElibraryPerformanceScoreScholasticExams from "./pages/PerformanceScoreNewUI/ElibraryPerformanceScoreScholasticExams";
import ElibraryPerformanceScoreCompetitiveExams from "./pages/PerformanceScoreNewUI/ElibraryPerformanceScoreCompetitiveExams";
import CompetitivePerformanceTypeLibrary from "./pages/PerformanceScoreNewUI/CompetitivePerformanceTypeLibrary";
import CompetitiveScore from "./pages/PerformanceScoreNewUI/CompetitiveScore";
import ElibraryCompetitiveTypeList from "./pages/PerformanceScoreNewUI/ElibraryCompetitiveTypeList";

import ScholasticPerformanceScore from "./pages/PerformanceScoreNewUI/ScholasticPerformanceScore";


//profile
import Profile from "./pages/Profile/Profile";

//Archive Performance Score
import ArchivePerformanceScore from "./components/archive/ArchivePerformanceScore";
import ArchiveScholasticPerformanceScore from "./components/archive/ArchiveScholasticPerformanceScore";
import ArchiveCompetitivePerformanceTypeLibrary from "./components/archive/ArchiveCompetitivePerformanceTypeLibrary";
import ArchiveOverallScholasticScore from "./components/archive/ArchiveOverallScholasticScore";
import ArchiveCompetitiveScore from "./components/archive/ArchiveCompetitiveScore";
import ArchiveElibraryPerformanceScoreScholasticExams from "./components/archive/ArchiveElibraryPerformanceScoreScholasticExams";
import ArchiveElibraryCompetitiveTypeList from "./components/archive/ArchiveElibraryCompetitiveTypeList";
import ArchiveElibraryPerformanceScoreCompetitiveExams from "./components/archive/ArchiveElibraryPerformanceScoreCompetitiveExams";
import ArchiveSubjectwiseScholasticScore from "./components/archive/ArchiveSubjectwiseScholasticScore";
import ArchiveSubjectwiseAnalysisOnSet from "./components/archive/ArchiveSubjectwiseAnalysisOnSet";
import ArchiveChapterwiseAnalysisOnSet from "./components/archive/ArchiveChapterwiseAnalysisOnSet";
import ArchiveSubjectwiseCompetitiveScore from "./components/archive/ArchiveSubjectwiseCompetitiveScore";
import ArchiveCompetitiveSubjectwiseAnalysisOnSet from "./components/archive/ArchiveCompetitiveSubjectwiseAnalysisOnSet";
import ArchiveSubjectwiseAnalysisOnModule from "./components/archive/ArchiveSubjectwiseAnalysisOnModule";
import ArchiveSubjectwiseAnalysisOnMock from "./components/archive/ArchiveSubjectwiseAnalysisOnMock";
import ArchiveChapterwiseAnalysisOnModule from "./components/archive/ArchiveChapterwiseAnalysisOnModule";
import ArchiveChapterwiseAnalysisOnMock from "./components/archive/ArchiveChapterwiseAnalysisOnMock";

///OnlineClass
import OnlineClass from "./pages/OnlineClass/OnlineClass";
let interval;
let _idealTime = 0;

const Markup = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [time, setTime] = useState(0);
  const { menuToggle } = useContext(ThemeContext);
  const unlock = useSelector(state => state.auth.unLock);
  const demoUser = useSelector(state => state.auth.user_id);
  
  const elibraryPathLocalstorage = JSON.parse(localStorage.getItem('elibraryPath'));


  useEffect(() => {
    interval = setInterval(() => {
      if (demoUser != 0 && _idealTime >= 600) { // for 10 minutes
        // if (demoUser != 0 && _idealTime >= 60) { // for 10 second
        history.push("/page-lock-screen");
        _idealTime = 0;
        clearInterval(interval);
      }
      _idealTime++;
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [_idealTime]); /* _idealTime */


  useEffect(() => {
    localStorage.openpages = Date.now();
    window.addEventListener('storage', function (e) {
      if (e.key == "openpages") {
        localStorage.page_available = Date.now();
      }
      if (e.key == "page_available") {
        alert("One more page already open");
        // window.location.href = "https://clvdev.in/";
        // href='https://clvdev.in/'
        this.window.close();
        // this.close();
        var win = window.open("http://crestest.com/", "_self");
        win.close();

      }
    }, false);
    return () => {
      // window.removeEventListener("storage");
    };

  }, [])


  const onMouseMoveHandler = () => {
    if (!unlock) {
      setTime(0);
      _idealTime = 0;
    }
  }
  
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "/", component: Home },
    { url: "dashboard", component: Home },
    
    { url: "demo-exams", component: DemoExam }, 
    // { url: "exam-details", component: ExamDetails },
    { url: "demo-assessment-details", component: AssessmentDetails },
    { url: "demo-assessment-list", component: AssessmentList }, 
    // { url: "scholastic-exam-subjects", component: ScholasticExam }, 
    { url: "scholastic-exams", component: ScholasticExam }, 
    { url: "competitive-exams", component: CompetitiveTypeLibrary }, 
    { url: "competitive-types", component: CompetitiveNtseTypeLibrary }, 
    { url: "competitive-exam-Select", component: CompetitiveExamSelect }, 
    { url: "competitive-assessment-Select", component: CompetitiveAssessmentSelect }, 
    { url: "online-assessment-details", component: OnlineAssessmentDetails }, 
    { url: "online-assessment-category", component: OnlineAsscessmentCategory }, 
    { url: "online-assessment-category-details", component: AssessmentCategoryWiseDetails }, 
    { url: "online-assessment-category-type", component: CompetiTypeAssessment }, 
    { url: "online-assessment-ntse-type", component: CompetitiveNtseType }, 
    // { url: "online-performance-category", component: PerformanceCategory },

    { url: "assessment-list", component: AssessmentNewList }, 

    /// Chart
    { url: "chart-chartjs", component: ChartJs },
    

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-login", component: Login },
    { url: "page-login-guest", component: Login },
    { url: "page-coming-soon", component: ComingSoon },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-reset-password/:userInfo", component: ResetPassword },
    
    { url: "page-lock-screen", component: LockScreen },

    { url: "page-demo-scholastic-exam", component: DemoScholasticExamStart }, 
    { url: "page-demo-competitive-exam", component: DemoCompetitiveExamStart }, 
    { url: "page-scholastic-exam", component: ScholasticExamStart },
    { url: "page-scholastic-exam-moudle-mock", component: MoudleMockScholasticExamStart }, 
    { url: "page-competitive-exam", component: CompetitiveExamStart }, 

    // { url: "page-test", component: ExamStartTest },

    ///E-library
    { url: "e-library", component: DemoLibrary },
    { url: "e-library-competitive", component: CompetitiveLibrary },
    { url: "e-library-scholastic", component: ScholasticLibrary },
    { url: "e-library-typeLibrary", component: TypeLibrary },
    { url: "e-library-branch-Library", component: BranchLibrary },
    { url: "page-e-library-exam", component: ExamDemoLibrary },
    { url: "e-library-category", component: LibraryCategoryWiseDetails },
    { url: "e-library-category-type", component: CompetiTypeLibrary },
    { url: "demo-e-library-pdf/:infoid", component: PdfViewElibrary },
    { url: "e-library-subject", component: OnlineElibrarySubject },
    { url: "e-library-scholastic-list", component: LibraryScholasticList },
    { url: `${elibraryPathLocalstorage}`, component: LibraryCompetitiveList },
    { url: "e-library-competitive-segments", component: CompetitiveTypeList },

    //Subscribe
    { url: "subscription", component: Subscribe }, 
    { url: "online-PaymentGateWay", component: PaymentGateWay },
    { url: "online-payment-success", component: PaymentSuccessful },
    { url: "online-payment-aborted", component: PaymentAborted },
    { url: "online-payment-failure", component: PaymentFailure },
    { url: "online-payment-illegal-access", component: OnlinePaymentIllegalAccess },
    { url: "subscribe-list", component: SubscribeList }, 
    { url: "my-purchase", component: PurchasedList },

    //PerformanceScore

    // { url: "online-overall-scholastic-performance-score", component: OverallScholasticPerformanceScore },
    // { url: "online-subject-wise-comparison-on", component: SubjectWiseComparisonOn },
    // { url: "online-avg-set-exams", component: AvgSetExams },
    // { url: "online-subject-scorecard-set-wise", component: SubjectScorecardSetWise },
    // { url: "online-comparative-study-set-exams", component: ComparativeStudySetExams },
    // { url: "online-subject-module", component: SubjectModule },
    // { url: "online-overall-compititive-performance-score", component: OverallCompititivePerformanceScore },
    // { url: "online-compititive-set-wise-score-comparison", component: CompititiveSetWiseScoreComparison },
    // { url: "online-comparison-average-score", component: ComparisonAverageScoreDifferentSubjects },
    // { url: "online-comparative-study-set-ntse", component: ComparativeStudySetNtse },

    //PerformanceScore New UI
    { url: "performance-category-select", component: PerformanceCategoryPage }, 
    { url: "performance-score", component: PerformanceCategoryPage }, 
    { url: "online-exam-performance-score", component: PerformanceCategoryOnlineExam }, 
    { url: "e-library-performance-score", component: PerformanceCategoryElibrary }, 
    { url: "overall-scholastic-performance-score", component: OverallScholasticScore }, 
    { url: "subjectwise-scholastic-score", component: SubjectwiseScholasticScore },
    { url: "subjectwise-analysis-ctl", component: SubjectwiseAnalysisOnSet }, 
    /* =============================== */
    { url: "subjectwise-analysis-mol", component: SubjectwiseAnalysisOnModule }, 
    { url: "subjectwise-analysis-mcl", component: SubjectwiseAnalysisOnMock },

    { url: "chapterwise-analysis-ctl", component: ChapterwiseAnalysisOnSet }, 
    { url: "chapterwise-analysis-mol", component: ChapterwiseAnalysisOnModule },
    { url: "chapterwise-analysis-mcl", component: ChapterwiseAnalysisOnMock }, 


    { url: "overall-competitive-performance-score", component: OverallCompetitiveScore }, 
    { url: "subjectwise-competitive-score/:typeId", component: SubjectwiseCompetitiveScore },
    { url: "competitive-subjectwise-analysis-on-set/:typeId", component: CompetitiveSubjectwiseAnalysisOnSet },
    { url: "e-library-scholastic-performance-score", component: ElibraryPerformanceScoreScholasticExams }, 
    { url: "e-library-competitive-performance-score", component: ElibraryPerformanceScoreCompetitiveExams }, 

    { url: "competitive-performance-score", component: CompetitivePerformanceTypeLibrary },
    { url: "competitive-performance-score/:typeId", component: CompetitiveScore },
    { url: "e-library-competitive-type-list", component: ElibraryCompetitiveTypeList },

    { url: "scholastic-performance-score", component: ScholasticPerformanceScore },

    //Profile
    { url: "profile", component: Profile },

    //Achive
    { url: "archive-performance-score", component: ArchivePerformanceScore },
    { url: "archive-scholastic-performance-score", component: ArchiveScholasticPerformanceScore },
    { url: "archive-competitive-performance-score", component: ArchiveCompetitivePerformanceTypeLibrary },
    { url: "archive-overall-scholastic-score", component: ArchiveOverallScholasticScore },
    { url: "archive-competitive-performance-score/:typeId", component: ArchiveCompetitiveScore },
    { url: "e-library-archive-competitive-type-list", component: ArchiveElibraryCompetitiveTypeList },
    { url: "e-library-archive-scholastic-performance-score", component: ArchiveElibraryPerformanceScoreScholasticExams },
    { url: "e-library-archive-competitive-performance-score", component: ArchiveElibraryPerformanceScoreCompetitiveExams }, 
    { url: "archive-subjectwise-scholastic-score", component: ArchiveSubjectwiseScholasticScore }, 
    { url: "archive-subjectwise-analysis-ctl", component: ArchiveSubjectwiseAnalysisOnSet }, 
    { url: "archive-chapterwise-analysis-ctl", component: ArchiveChapterwiseAnalysisOnSet }, 
    { url: "archive-subjectwise-competitive-score/:typeId", component: ArchiveSubjectwiseCompetitiveScore },
    { url: "archive-competitive-subjectwise-analysis-on-set/:typeId", component: ArchiveCompetitiveSubjectwiseAnalysisOnSet },
    { url: "archive-subjectwise-analysis-mol", component: ArchiveSubjectwiseAnalysisOnModule }, 
    { url: "archive-subjectwise-analysis-mcl", component: ArchiveSubjectwiseAnalysisOnMock }, 
    { url: "archive-chapterwise-analysis-mol", component: ArchiveChapterwiseAnalysisOnModule },
    { url: "archive-chapterwise-analysis-mcl", component: ArchiveChapterwiseAnalysisOnMock }, 
    
    //Online Class
    { url: "online-class", component: OnlineClass }, 
    { url: "page-error-404", component: Error404 },
    { url: "*", component: Error404 },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");



  return (
    <>
      <div
        onMouseMove={() => onMouseMoveHandler()}
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}
        <div className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: 'calc(100vh - 50px)' }}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
          >
            <Switch>

              {routes.map((data, i) => (

                /* data.url == "page-error-404" ?
                <>
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
                  <Redirect to="/page-error-404" /></>
                  : */
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
