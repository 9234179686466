import {
    getExamCategories, getAllExamCategories, getLibraryCategories,getArchiveCategory,getArchiveLibraryCategory
} from '../../services/ExamCategoryService';

import {
    logout,
} from '../actions/AuthActions';

import {
    GET_EXAM_CATEGORIES_LIST,
    EXAM_CATEGORIES_LIST_SUCCESS,
    EXAM_CATEGORIES_LIST_FAILURE,
    CHOOSE_EXAM_CATEGORY,
    CHOOSE_EXAM_CATEGORY_REQUEST,
    GET_ALLEXAM_CATEGORIES,
    GET_EXAM_CATEGORIES_LIBRARY
} from '../constants';

import * as utility from '../../utility/Utility';

export function getCategoryData(history) {
    return (dispatch) => {
        getExamCategories()
            .then((response) => {
                if(response.data.status == 200){
                    dispatch(getExamCategoriesAction(response.data.data));
                }else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getAllexamCategories(history) {
    return (dispatch) => {
        getAllExamCategories()
            .then((response) => {
                if(response.data.status == 200){
                    dispatch(getAllExamCategoriesAction(response.data.data));
                }else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getLibraryCategoryData(history) {
    return (dispatch) => {
        getLibraryCategories()
            .then((response) => {
                if(response.data.status == 200){
                    dispatch(getLibraryCategoriesAction(response.data.data));
                }else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getArchiveCategoryData(class_no,archiveCategoryCallBack,history) {
    return (dispatch) => {
        getArchiveCategory(class_no)
            .then((response) => {
                if(response.data.status == 200){
                    archiveCategoryCallBack(response.data.data);
                }else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getArchiveLibraryCategoryData(class_no,archiveLibraryCategoryCallBack,history) {
    return (dispatch) => {
        getArchiveLibraryCategory(class_no)
            .then((response) => {
                if(response.data.status == 200){
                    archiveLibraryCategoryCallBack(response.data.data);
                }else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getExamCategoriesAction(data) {
    return {
        type: GET_EXAM_CATEGORIES_LIST,
        payload: data,
    };
}

export function examCategoriesListSuccessAction(data) {
    return {
        type: EXAM_CATEGORIES_LIST_SUCCESS,
        payload: data,
    };
}

export function examCategoriesListFailureAction(data) {
    return {
        type: EXAM_CATEGORIES_LIST_FAILURE,
        payload: data,
    };
}

export function selectExamCategoryAction(data) {
    return {
        type: CHOOSE_EXAM_CATEGORY,
        payload: data,
    };
}

export function selectExamCategoryRequestAction(data) {
    return {
        type: CHOOSE_EXAM_CATEGORY_REQUEST,
        payload: data,
    };
}

export function getAllExamCategoriesAction(data) {
    return {
        type: GET_ALLEXAM_CATEGORIES,
        payload: data,
    };
}

export function getLibraryCategoriesAction(data) {
    return {
        type: GET_EXAM_CATEGORIES_LIBRARY,
        payload: data,
    };
}
