import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

import { performanceRequestAction, getCompetitiveSetwiseScoreDataDetails } from '../../../../store/actions/ArchivePerformanceScoreAction';

const ArchiveChartCard19 = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
    const [chartData, setChartData] = useState([60, 60, 70, 70, 80, 80, 80, 90,])
    const [backgroundColor, setBackgroundColor] = useState(['#CBF1FA',])

    const [overallAvg, setOverallAvg] = useState(0)
    const chartRef = useRef();
    const [showTableModal, setShowTableModal] = useState(false);
    const [standard, setStandard] = useState('')
    const [board_name, setBoard_name] = useState('')

    ChartJS.register(ArcElement, Tooltip, Legend);


    useEffect(() => {

        dispatch(performanceRequestAction(true));
        dispatch(getCompetitiveSetwiseScoreDataDetails(props.exam_type,props.classNo, props.history));
        let getData = localStorage.getItem('userDetails');

        let board_name = JSON.parse(getData).board_name;
        let standard = JSON.parse(getData).standard;
        setBoard_name(board_name);
        setStandard(standard);

    }, []);

    const showSetTableData = () => {
        // console.log("Show Table Data");
        setShowTableModal(true)
    }
    const closeTableModal = () => {
        setShowTableModal(false)
    }

    const lineData = {

        series: [{
            name: "Score",
            // data: [34, 44, 84, 21, 90, 43, 33, 65],
            // data: props.compititiveSetwiseScore != '' ? props.compititiveSetwiseScore.series[0].data : []
            data: props.dataSet.series[0].data
        }],
        options: {
            chart: {
                type: 'line',
                height: 350,
                toolbar: {
                    show: false
                },
                /* events: {
                    click(event, chartContext, config) {
            
                        console.log(config.config.series[config.seriesIndex])
                        console.log(config.config.series[config.seriesIndex].name[config.dataPointIndex])
                        console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
                        
                        // yourFunctionHere();
                        
                    }
                } */
            },
            stroke: {
                curve: 'stepline',
            },
            dataLabels: {
                enabled: false
            },
            /* title: {
              text: 'Stepline Chart',
              align: 'left'
            }, */
            markers: {
                hover: {
                    sizeOffset: 4
                }
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 8,
            },
            colors: ["#D69165"],
            fill: {
                type: ['gradient',],
                colors: ["#FFEB8C"],
                opacity: 1
            },
            xaxis: {
                type: 'text',
                // categories: ["Set1", "Set2", "Set3", "Set4", "Set5", "Set6", "Set7", "Set8"]
                // categories: props.compititiveSetwiseScore != '' ? props.compititiveSetwiseScore.categories : null
                categories: props.dataSet.categories
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 8,
            },
        },
    };


    const showDetails = (item) => {
        props.showDetailsHandler(item);
        // const typeName = props.exam_type.toUpperCase()
        // history.push({ pathname: `/archive-subjectwise-competitive-score/${typeName}-subjectwise-competitive-score`, state: { set_no: item.slice(3), exam_type: props.exam_type } })
    }


    return (
        <>
            <Row>
                <Col xl={12}>
                    <div className="chart_card_parent">
                        <div className="chart_card_heading">{props.pageHeading}</div>
                        {props.compititiveSetwiseScore != '' ?
                            <>
                                <div className="chart_body" >
                                    <ReactApexChart options={lineData.options} series={lineData.series} type="area" height={260} />
                                </div>

                                <div className="bottom_container">
                                    <div className="data_label_parent_container">
                                        <div className={`data_label  ${props.dataSet.categories.length > 6 ? "justify-content-start" : "justify-content-center"}`}>

                                            {props.dataSet != '' && props.dataSet != null && props.dataSet != undefined ?
                                                props.dataSet.categories.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className='item_box cursorPointer' style={{ background: `${index % 2 == 0 ? '#3851AB' : '#8592CB'}` }} onClick={() => showDetails(item)}>{item}</div>
                                                        </React.Fragment>
                                                    )
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="bottom_details">
                                    {/* <div className="btn_details back_btn_color text_black">Back</div> */}
                                    <div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
                                </div>
                            </>
                            :
                            <div className='dflex flex1 jcc aic'>
                                No Data Available
                            </div>
                        }
                    </div>
                </Col>
            </Row>
            <Modal
                className="fade bd-example-modal-lg subscribe-modal"
                //size="sm"
                size="lg"
                show={showTableModal}
            >
                <>
                    <div className='modal_container'>
                        <div className='modal_Header_container'>
                            <div>
                                {/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
                                <div className='performer_table_heading'>Performance Activity</div>
                                <p>Competitive <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}{/* {board_name}: {standard} */}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
                            </div>
                            <div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
                                <i className="fa fa-times" ></i>
                            </div>
                        </div>
                        <div className='modal_body_container'>
                            <table cellPadding="10px" className="performance_table_data">
                                <thead>
                                    <tr>
                                        <th className="text-center light_sky">Sets</th>
                                        <th className="text-center light_sky">Marks</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {lineData.options.xaxis.categories.map((item, index) => {
                                        return (
                                            <React.Fragment key={index} >
                                                <tr>
                                                    <td className="text-center light_gray_1">{item}</td>
                                                    <td className="text-center light_gray_2">{lineData.series[0].data[index]}</td>

                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                    }

                                </tbody>

                            </table>

                        </div>

                    </div>
                </>
            </Modal>
        </>
    )
}

export default ArchiveChartCard19;