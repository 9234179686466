import React, { Fragment, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Tab,
  Badge,
  Dropdown,
  Tooltip,
  Modal,
  Nav,
  Button,
} from "react-bootstrap";

import {
  addToCartData,
  subscribeLoading,
  showRightNavAction,
  compititiveSubscriptionSourceAction,
} from "../../../store/actions/SubscribeAction";

import AddProfileDetailsCard from "./AddProfileDetailsCard";

import {
  updateStudentProfileOfSubscription,
} from "../../../store/actions/ProfileAction";

const SubcribeCompetitiveCard = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [isLibrary, setIsLibrary] = useState(
    props.data.has_library == 0 ? false : true
  );
  const [totalAmount, setTotalAmount] = useState(props.amount);

  const [isProfileUpdated, setIsProfileUpdated] = useState('');

  const competitiveSubscriptionSourceData = useSelector(
    (state) => state.subscribe.competitiveSubscriptionSourceData
  );
  const competitiveSubscriptionSourceDataColl = useSelector(
    (state) => state.subscribe.competitiveSubscriptionSourceDataColl
  );

  useEffect(() => {
    // console.log("competitiveSubscriptionSourceDataColl----", competitiveSubscriptionSourceDataColl)
  }, [competitiveSubscriptionSourceDataColl]);

  useEffect(() => {
    if (props.cartList != "") {
      props.cartList.find(function (e) {
        if (
          e.subscription_id == props.id &&
          e.exam_type_id == props.data.exam_type_id &&
          e.has_library == 1 &&
          e.exam_type_id == props.type &&
          e.only_elibrary == 0
        ) {
          setIsLibrary(true);
          // setTotalAmount(totalAmount + props.library);
        } else {
          setIsLibrary(false);
          //
        }
      });
    } else {
      setIsLibrary(false);
      setTotalAmount(props.amount);
    }
  }, [props.cartList]);

  /*  */

  /* useEffect(() => {
    setIsProfileUpdated(props?.checkProfile?.isComplete)
  }, [props?.checkProfile?.isComplete]); */

  const onProgress = () => {
    dispatch(subscribeLoading(true));
    dispatch(
      addToCartData(
        props.category_id,
        props.id,
        props.setCount,
        0,
        0,
        props.type,
        props.class_no ? props.class_no : 0,
        totalAmount,
        0,
        0,
        isLibrary ? 1 : 0,
        props.history
      )
    );
    dispatch(showRightNavAction(true));
    setModal(false);
    if (!isLibrary) {
      dispatch(compititiveSubscriptionSourceAction([]));
    }
  };

  const onLibraryHandler = (e) => {
    setIsLibrary(e.target.checked);
    if (e.target.checked == true) {
      setTotalAmount(totalAmount + props.library);
      props.competitiv_e_library_select_handaler(
        e.target.checked,
        props.id,
        props.type
      );
    } else {
      setTotalAmount(totalAmount - props.library);
      props.competitiv_e_library_unselect_handaler(
        e.target.checked,
        props.id,
        props.type
      );
    }
  };

  /* const onProfileHandler = (data) => {
    console.log("hello profile...", data);
    setIsDetails(false);
  }; */

  const onCartHandler = () => {
    setModal(true);
    // setIsDetails(true);
  };

  const updatedProfileInfo = () => {
    setIsDetails(true);
    return;
  }

  const onProfileHandler = (data) => {
    dispatch(
      updateStudentProfileOfSubscription(
        data.pincode,
        data.schoolname,
        data.schooladd,
        updataedStudentProfileDetails,
        props.history
      ))
  }

  const updataedStudentProfileDetails = (data) => {
    if(data != ''){
      setIsDetails(false);
      setIsProfileUpdated(0)
      props.isCompleteStatusUpdate()
      onCartHandler();
    }
      
  }

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <div
            className={`subcribe-competitive-card  ${
              props.cartList.length == 0
                ? props.isPurchased == 1
                  ? "subscription-disable"
                  : null
                : props.isPurchased == 1 ||
                  props.cartList.find(
                    (x) =>
                      x.exam_type_id === props.data.exam_type_id &&
                      x.exam_category_id === props.data.exam_category_id &&
                      x.subscription_id != props.id &&
                      x.only_elibrary != 1
                  )
                ? "subscription-disable"
                : null
            }`}
          >
            <div className="subcribe-competitive-details">
              {props.sticker_text != "" ? (
                <div className="sticker_base">
                  <div className="sticker_text">{props.sticker_text}</div>
                </div>
              ) : null}
              <h4>{props.setCount} Sets </h4>{" "}
              <h4>{props.questionPerset * props.setCount} Questions</h4>
              <p>( {props.questionPerset} Questions per set )</p>
              <div
                className={`set-elibrary-content ${
                  props.data.disabled_library == 1
                    ? "subscription-disable"
                    : null
                }`}
              >
                <div className="set-scholastic-content-lft">
                  <div className="form-check custom-checkbox checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={"e-library" + props.id}
                      name={"e-library" + props.id}
                      checked={
                        props.cartList != ""
                          ? props.cartList.find(
                              (e) =>
                                e.subscription_id == props.id &&
                                e.exam_type_id == props.data.exam_type_id &&
                                e.has_library == 1 &&
                                e.exam_type_id == props.type &&
                                e.exam_category_id == 2 &&
                                e.only_elibrary == 0
                            )
                            ? true
                            : props.cartList.find(
                                (e) =>
                                  e.only_elibrary == 1 &&
                                  e.exam_type_id == props.type &&
                                  e.exam_category_id == 2
                              ) && isLibrary
                            ? false
                            : isLibrary
                            ? true
                            : false
                          : isLibrary
                      }
                      onChange={onLibraryHandler}
                      disabled={
                        props.cartList != ""
                          ? props.cartList.find(
                              (e) =>
                                e.subscription_id == props.id &&
                                e.exam_type_id == props.data.exam_type_id &&
                                e.exam_type_id == props.type &&
                                e.exam_category_id == 2
                            )
                            ? true
                            : props.cartList.find((e) =>
                                e.only_elibrary == 1 &&
                                e.exam_category_id == 2 &&
                                e.exam_type_id == props.type
                                  ? true
                                  : props.selectSubId &&
                                    props.selectSubId != props.id &&
                                    competitiveSubscriptionSourceDataColl.exam_type ==
                                      props.type
                                  ? true
                                  : false
                              )
                          : props.selectSubId &&
                            props.selectSubId != props.id &&
                            competitiveSubscriptionSourceDataColl.exam_type ==
                              props.type
                          ? true
                          : false
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"e-library" + props.id}
                    >
                      e-Library {/* {isLibrary ? "true" : "false"} */}{" "}
                      {props.data.disabled_library == 1 ? (
                        <span className="small_text_left">
                          (Already purchased)
                        </span>
                      ) : null}
                    </label>
                  </div>
                </div>

                <div className="set-elibrary-content-rght">
                  {isLibrary ? (
                    <p className="">
                      <strike>Rs.{props.data.only_elibrary_price}/-</strike>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="subcribe-competitive-price-details">
              <div>
                <div>
                  {props.cartList != "" ? (
                    props.cartList.find(
                      (e) =>
                        e.subscription_id == props.id &&
                        e.exam_type_id == props.data.exam_type_id &&
                        e.has_library == 1 &&
                        e.exam_type_id == props.type
                    ) ? (
                      <h4>
                        Rs.
                        {
                          props.cartList.find(
                            (e) =>
                              e.subscription_id == props.id &&
                              e.exam_type_id == props.data.exam_type_id &&
                              e.has_library == 1 &&
                              e.exam_type_id == props.type
                          ).cart_amount
                        }
                        /-{" "}
                      </h4>
                    ) : (
                      <h4>Rs.{totalAmount}/- </h4>
                    )
                  ) : (
                    <h4>Rs.{isLibrary ? totalAmount : props.amount}/- </h4>
                  )}
                </div>
                <div className="small_text_left ">(Including GST)</div>
              </div>

              <div className="displayNone">{props.exam_type_id}</div>
              {props.courseAvailable == 1 ? (
                <>
                  {props.isPurchased == 1 ? null : (
                    <>
                      {!props.cartList.find(
                        (element) =>
                          element.subscription_id === props.id &&
                          element.exam_category_id == 2 &&
                          element.exam_type_id == props.exam_type_id &&
                          element.only_elibrary != 1
                      ) ? (
                        <Button onClick={() => props.isComplete == 0 ? onCartHandler() : updatedProfileInfo() }>
                          Add to cart
                        </Button>
                      ) : (
                        <Button disabled>Add to cart</Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      You cannot add any items because the course validity is
                      expiring soon.
                    </Tooltip>
                  )}
                  placement="top"
                >
                  <Button className="disable_cart_btn">Add to cart</Button>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        className="fade bd-example-modal-sm subscribe-modal"
        //size="sm"
        show={modal}
      >
        <Modal.Header>
          <Modal.Title>Cart Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!props.data && props.data != undefined ? (
            <p>
              Details : Set {props.setCount} {!!isLibrary ? " + " : null}{" "}
              {!!isLibrary ? "e-Library" : null}
            </p>
          ) : null}
          {/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. */}
        </Modal.Body>
        <Modal.Footer>
          <div className="subscribe-modal-footer">
            <Button className="cancelBtn" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button className="proceedBtn" onClick={onProgress}>
              Proceed
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AddProfileDetailsCard
        show={isDetails}
        onProfileSubmit={(data) => onProfileHandler(data)}
        profileData={props.checkProfile}
        closeHandler={() => setIsDetails(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.subscribe.showLoading,
    cartList: state.subscribe.cartList,
  };
};
export default connect(mapStateToProps)(SubcribeCompetitiveCard);
