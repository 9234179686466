import { storelocalstorage } from "../../services/GetLocalStoreService";

export function storelocalstorageAction(api_name, history) {
  return (dispatch) => {
    storelocalstorage(api_name)
      .then((response) => {
        // console.log("response...", response.data);
      })
      .catch((error) => {
        console.log(error);
        //utility.showError(error.response.data);
      });
  };
}
