import React, { Fragment, useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {
  getBoardData,
  loadingBoardAction,
  getSchoolListData,
  getSchoolListFailureAction,
  selectSchoolNameListAction,
  schoolNameShowingLoaderAction,
} from "../../../store/actions/BoardActions";

const AddProfileDetailsCard = (props) => {
  const dispatch = useDispatch();

  let errorsObj = {
    pincode: "",
    schoolname: "",
    schooladd: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [options, setOptions] = useState([]);
  const [schoolNameList, setSchoolNameList] = useState([]);
  const [userDetails, setUserDetails] = useState({
    schoolname: "",
    schooladd: "",
    pincode: "",
  });
  const [manualSchoolName, setManualSchoolName] = useState("");
  const [ismanualSchoolName, setIsManualSchoolName] = useState(0);

  const [selectedOption, setSelectedOption] = useState(
    props?.profileData.data[0]?.school_name ?? null
  );

  useEffect(() => {
    dispatch(getSchoolListData(1, props.history));
  }, []);

  useEffect(() => {
    console.log("(props?.profileData.data[0]?.school_name-----", props?.profileData.data[0]?.school_name)
    if (props?.profileData.data[0]?.school_name) {
      setSelectedOption({
        label: props?.profileData.data[0]?.school_name == null || props?.profileData.data[0]?.school_name == 'null' ? '' : props?.profileData.data[0]?.school_name,
        value: props?.profileData.data[0]?.school_name == null || props?.profileData.data[0]?.school_name == 'null' ? '' : props?.profileData.data[0]?.school_name,
      });
    }
    if (
      props?.profileData.data[0]?.pincode != 0 &&
      props?.profileData.data[0]?.pincode != undefined
    ) {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        pincode: props?.profileData.data[0]?.pincode,
      }));
    }
    if (
      props?.profileData.data[0]?.school_name != "" &&
      props?.profileData.data[0]?.school_name != undefined
    ) {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        schoolname: props?.profileData.data[0]?.school_name == null || props?.profileData.data[0]?.school_name == 'null' ? '' : props?.profileData.data[0]?.school_name,
        schooladd: props?.profileData.data[0]?.school_address,
      }));
    }
    setManualSchoolName(props?.profileData.data[0]?.school_name == null || props?.profileData.data[0]?.school_name == 'null' ? '' : props?.profileData.data[0]?.school_name)
  }, [props?.profileData]);

  useEffect(() => {
    return () => {
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        pincode: "",
        schoolname: "",
        schooladd: "",
      }));
    }
  }, [props?.profileData]);



  useEffect(() => {
    if (props.schoolList.length > 0) {
      let selectSchoolNameBoardWise = [];
      let selectSchoolName = {};
      let creact_new = {}
      creact_new["value"] = "Create new";
      creact_new["label"] = "Create new";

      props.schoolList.forEach((elm) => {
        selectSchoolName = {};
        selectSchoolName["value"] = elm.school_name;
        selectSchoolName["label"] = elm.school_name;
        selectSchoolNameBoardWise.push(selectSchoolName);
      });
      selectSchoolNameBoardWise.unshift(creact_new)
      setSchoolNameList(props.schoolList);
      setOptions(selectSchoolNameBoardWise);
    }
  }, [props.schoolList]);

  const onSubmitHandler = () => {
    const onlyNumber = /^[0-9]+$/;
    const { pincode, schoolname, schooladd } = userDetails;
    let error = false;

    const errorObj = { ...errorsObj };
    if (pincode === "") {
      errorObj.pincode = "Postal Code is Required";
      error = true;
    } else if (!onlyNumber.test(pincode)) {
      errorObj.pincode = "Allow only numeric";
      error = true;
    } else if (pincode.length < 6) {
      errorObj.pincode = "Minimum 6 Character is Required";
      error = true;
    } else if (pincode == "000000") {
      errorObj.pincode = "Not a Valid Postal Code";
      error = true;
    }
    if (schoolname === "" && ismanualSchoolName === 0) {
      errorObj.schoolname = "School Name is Required";
      error = true;
    }
    if (manualSchoolName === "" && ismanualSchoolName === 1) {
      errorObj.schoolname = "School Name is Required";
      error = true;
    }
    
    if (schooladd == null || schooladd == 'null' || schooladd === "") {
      errorObj.schooladd = "School Address is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
  
    props.onProfileSubmit(userDetails);
  };

  const onPincodeHandle = (val) => {
    setUserDetails((prevDetails) => ({ ...prevDetails, pincode: val }));
  };

  /* school name select */
  const getSelectedValue = (item) => {
    if (ismanualSchoolName === 0 && item.value != 'Create new') {

      if (item) {
        // Update school name and address
        const selectSchoolAddress = schoolNameList.find(
          (i) => i.school_name === item.value
        );
        
        setUserDetails((userDetails) => ({
          ...userDetails,
          schoolname: item.value,
          schooladd: selectSchoolAddress
            ? selectSchoolAddress.school_address
            : "",
        }));

        setSelectedOption({ label: item.label, value: item.value });
      } else {
        console.log("@12")
        // Reset school name and address
        setUserDetails((userDetails) => ({
          ...userDetails,
          schoolname: "",
          schooladd: "",
        }));

        setSelectedOption(null);
      }
    } else {
      console.log("@44444")
      /* setUserDetails((userDetails) => ({
        ...userDetails,
        schooladd: userDetails.schooladd,
      })); */
      setIsManualSchoolName(1)
    }
  };

  const onSchoolAddHandler = (val) => {
    setUserDetails((userDetails) => ({
      ...userDetails,
      schooladd: val,
    }));
  };

  const getManualSchoolNameChange = (value) => {
    // setSchoolName(value)
    setManualSchoolName(value)
    setSelectedOption({
      label: value,
      value: value,
    });

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      schoolname: value,
      // schooladd: props?.profileData.data[0]?.school_address,
      schooladd: "",
    }));

  };

  const showSchoolList = () => {
    setIsManualSchoolName(0)
  }

  const clearErrorText = () => {
    let errorObj = { ...errorsObj };
    errorObj.pincode = "";
    errorObj.schoolname = "";
    errorObj.schooladd = "";
    setErrors(errorObj);
  }


  return (
    <Fragment>
      <Modal
        className="fade bd-example-modal-sm subscribe-modal"
        size="md"
        show={props.show}
      >
        <Modal.Header>
          <Modal.Title>Student Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="registration">
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <label>
                    Postal Code <span className="mandatory_color">*</span>
                  </label>
                  <input
                    //ref={pincodeRef}
                    type="text"
                    className="form-control input_value"
                    placeholder="Enter Postal Code"
                    onChange={(e) => onPincodeHandle(e.target.value)}
                    value={userDetails.pincode}
                    name="pincode"
                    autoComplete="off"
                    maxLength={6}
                  //onKeyPress={utility.inputNumberOnly}
                  />
                  {errors.pincode && (
                    <div className="text-danger fs-12">{errors.pincode}</div>
                  )}
                </div>
                {console.log("ismanualSchoolName=======", ismanualSchoolName)}
                {ismanualSchoolName === 0 ?
                  <div className="col-sm-12 mb-2">
                    <label>
                      School Name <span className="mandatory_color">*</span>
                    </label>
                    <CreatableSelect
                      value={selectedOption?.value == "" ? "" : selectedOption}
                      placeholder="Select School"
                      isClearable
                      onChange={getSelectedValue}
                      options={options}
                      defaultValue={selectedOption}
                    />
                    {errors.schoolname && (
                      <div className="text-danger fs-12">{errors.schoolname}</div>
                    )}
                  </div>
                  :
                  <>
                    <label>
                      School Name <span className="mandatory_color">*</span>
                    </label>
                    <div className="d-flex align-items-center mb-2">
                      <div
                        className="view_password_reset "
                        onClick={() => showSchoolList()}
                      >
                        <i
                          className={"bi bi-x-circle-fill"}
                        ></i>
                      </div>

                      <input
                        type="text"
                        className="form-control input_value school_name"
                        placeholder="School name"
                        value={manualSchoolName}
                        onChange={(e) => getManualSchoolNameChange(e.target.value)}
                      />
                    </div>
                    {errors.schoolname && (
                      <div className="text-danger fs-12">{errors.schoolname}</div>
                    )}
                  </>
                }

                <div className="col-sm-12 mb-2">
                  <label>
                    School Address <span className="mandatory_color">*</span>
                  </label>
                  <input
                    //ref={schooladdRef}
                    type="text"
                    className="form-control input_value school_name"
                    placeholder="Enter school address"
                    onChange={(e) => onSchoolAddHandler(e.target.value)}
                    value={userDetails.schooladd}
                    name="schooladd"
                    autoComplete="off"
                    disabled={ismanualSchoolName === 0 ? true : false}
                  />
                  {errors.schooladd && (
                    <div className="text-danger fs-12">{errors.schooladd}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="subscribe-modal-footer">
            <Button className="cancelBtn" onClick={() => { props.closeHandler(); clearErrorText() }}>
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => onSubmitHandler()}
              className="proceedBtn"
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    schoolList: state.board.schoolList,
    schoolNameShowingLoader: state.board.schoolNameShowingLoader,
  };
};
export default connect(mapStateToProps)(AddProfileDetailsCard);
