import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"

import {
	Row,
	Col,
	Spinner
} from "react-bootstrap";

//Import Components
import ChartCard25 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard25';
import ChartCard26 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard26';
import ChartCard27 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard27';

import setBodyImage from '../../../setBodyImage';

import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_3background.png'

import { performanceRequestAction, getelibrarySessionTimeDataDetails, elibraryMostVisitedSubjectsDataDetails, elibraryMostSearchQuestionsDataDetails } from '../../../store/actions/ArchivePerformanceScoreAction';


const ArchiveElibraryPerformanceScoreScholasticExams = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const { state } = useLocation();

	useEffect(() => {
		dispatch(performanceRequestAction(true))
		dispatch(getelibrarySessionTimeDataDetails(state.id, 0,state.standard, props.history)) /* call Session Time data */
		dispatch(elibraryMostVisitedSubjectsDataDetails(state.id, 0,state.standard,props.history)) /* call Most Visited Subject data */
		dispatch(elibraryMostSearchQuestionsDataDetails(state.id, 0,state.standard,props.history)) /* call Most Search Questions data */

		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};
	}, []);

	const goBackHandler = () => {
		history.push({
		  pathname: "/archive-performance-score",
		  state: state,
		});
	  };

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Archive Performance Score <i className="bi bi-chevron-right"></i> <span className='text-lowercase'>e</span>-Library Archive Scholastic Performance Score  </div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => goBackHandler()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>

			<Row>

				<Col xl={12}>
					{props.elibrarySessionTime != '' && props.elibrarySessionTime != undefined
						?
						<ChartCard25
							pageHeading="Session Time"
							series={props.elibrarySessionTime.series[0]}
							categories={props.elibrarySessionTime.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>

				<Col xl={12}>
					{props.elibraryMostVisitedSubjects != '' && props.elibraryMostVisitedSubjects != undefined
						?
						<ChartCard26
							pageHeading="Most Visited Subject"
							series={props.elibraryMostVisitedSubjects.series[0]}
							categories={props.elibraryMostVisitedSubjects.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>
				<Col xl={12}>
					{props.elibraryMostSearchQuestions != '' && props.elibraryMostSearchQuestions != undefined
						?
						<ChartCard27
							pageHeading="Most Search Questions"
							series={props.elibraryMostSearchQuestions.series[0]}
							categories={props.elibraryMostSearchQuestions.categories}
						/>
						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</Col>

			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		loading: state.archivePerformance.loading,
		elibrarySessionTime: state.archivePerformance.elibrarySessionTime,
		elibraryMostVisitedSubjects: state.archivePerformance.elibraryMostVisitedSubjects,
		elibraryMostSearchQuestions: state.archivePerformance.elibraryMostSearchQuestions,
	};
};
export default connect(mapStateToProps)(ArchiveElibraryPerformanceScoreScholasticExams);