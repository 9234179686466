import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// image
import logofull from '../../images/clv_logo.png';
import * as utility from '../../utility/Utility';
import Loader from "../components/Loader"

import { resetPassword, loadingToggleAction, checkPasswordLinkExistDetails, resetLinkExistOrNotAction } from "../../store/actions/AuthActions";

// const ForgotPassword = ({ props }) => {
function ResetPassword(props) {

  const dispatch = useDispatch();
  let errorsObj = { password: '', cpassword: '', };

  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('')
  const [cpassword, setCpassword] = useState('')
  const [userInformation, setUserInformation] = useState('')
  const [viewPassword, setViewPassword] = useState("password");
  const [infoBox, setInfoBox] = useState(false)

  const resetLinkExistOrNot = useSelector(state => state.auth.resetLinkExistOrNot);

  useEffect(() => {
    const userInfo = window.location.href.split('/');
    const userInfoForResetPassword = userInfo[userInfo.length - 1]
    setUserInformation(userInfoForResetPassword);
    // console.log("userInfoForResetPassword----", userInfoForResetPassword)
    dispatch(checkPasswordLinkExistDetails(userInfoForResetPassword, props.history));
  }, [])

  /* on form submit */
  const onSubmit = (e) => {
    e.preventDefault();
    let error = false;
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    const errorObj = { ...errorsObj };
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    } else if (password.length < 6 || password.length > 16) {
      errorObj.password = 'Password minimum 6 and maximum 16 characters Required';
      error = true;
    } /* else if (!regularExpression.test(password)) {
      errorObj.password = 'Password not in pattern';
      error = true;
    } */

    if (cpassword === '') {
      errorObj.cpassword = 'Confirm Password is Required';
      error = true;
    } else if (cpassword !== password) {
      errorObj.cpassword = 'Mismatched with password';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(resetPassword(password, userInformation, props.history));
    dispatch(resetLinkExistOrNotAction({ statuPosition: 1, msg: '' }));
  };

  /* show password */
  const showPassword = () => {
    if (viewPassword == "password") {
      setViewPassword("text")
    } else {
      setViewPassword("password")
    }
  }

  const handleMouseOver = () => {
    setInfoBox(true)

  };

  const handleMouseOut = () => {
    setInfoBox(false)
  };

  const newPasswordWhiteSpaceRemoveHandle = (value) => {
    setPassword(value.replace(/\s/g, ''))
  }

  const newConfirmPasswordWhiteSpaceRemoveHandle = (value) => {
    setCpassword(value.replace(/\s/g, ''))
  }



  return (
    <>
      <div className="authincation h-100 p-meddle">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        <Link to="/page-login">
                          <img src={logofull} alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center">Reset Password</h4>
                      <h6 className={`text-center mb-4 ${resetLinkExistOrNot.statuPosition == 0 ? "text-danger" : null}`}>{`${resetLinkExistOrNot.statuPosition == 0 ? `${resetLinkExistOrNot.msg}` : `Enter a new password`}`}</h6>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div className={`form-group mb-4 eye_parent_container ${resetLinkExistOrNot.statuPosition == 0 ? "subscription-disable" : null}`}>
                          {/* <label className="">
                            <strong>New Password <span className="mandatory_color">*</span></strong>
                          </label> */}
                          <div className='d-flex justify-content-between align-items-center flex-row'>
                            {infoBox ?
                              <div className='info_box'>
                                {/* Must contain at least one number and one uppercase and one special character, and min 8  and max 16 characters require */}
                                Minimum length of 6 characters and maximum length of at least 15 characters
                              </div>
                              : null}
                            <div>
                              <label className="">
                                <strong>New Password <span className="mandatory_color">*</span></strong>
                              </label>
                            </div>
                            <div
                              onMouseOver={handleMouseOver}
                              onMouseOut={handleMouseOut}
                            >
                              <i className="bi bi-info-circle-fill blue_color"></i>
                            </div>

                          </div>
                          <div className='d-flex align-items-center'>
                            <div className="view_password_reset" onClick={() => showPassword()}><i className={viewPassword == "password" ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i></div>

                            <input
                              type={viewPassword}
                              className="form-control"
                              // defaultValue="hello@example.com"
                              placeholder="New Password"
                              onChange={(e) => { setPassword(e.target.value); newPasswordWhiteSpaceRemoveHandle(e.target.value) }}
                              value={password}
                              minLength={6}
                              maxLength={15}
                              onKeyDown={utility.whiteSpacenotAllow}

                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }} onCopy={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                            />
                          </div>
                          {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                        </div>
                        <div className={`form-group mb-4 ${resetLinkExistOrNot.statuPosition == 0 ? "subscription-disable" : null}`}>
                          <label className="">
                            <strong>Confirm New Password<span className="mandatory_color">*</span></strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            // defaultValue="hello@example.com"
                            placeholder="Confirm New Password"
                            onChange={(e) => { setCpassword(e.target.value); newConfirmPasswordWhiteSpaceRemoveHandle(e.target.value) }}
                            value={cpassword}
                            onKeyDown={utility.whiteSpacenotAllow}
                            onPaste={(e) => {
                              e.preventDefault()
                              return false;
                            }} onCopy={(e) => {
                              e.preventDefault()
                              return false;
                            }}
                          />
                          {errors.cpassword && <div className="text-danger fs-12">{errors.cpassword}</div>}
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className={`btn btn-primary btn-block text-center ${resetLinkExistOrNot.statuPosition == 0 ? "subscription-disable" : null}`}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {props.showLoading ?
          <Loader />
          : null}
      </div>
      <div><ToastContainer /></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ResetPassword);

