import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import GaugeChart from 'react-gauge-chart'

import clv from "../../../../images/needle_clv.png"
import {
	Row,
	Col,
	Modal
} from "react-bootstrap";

import { performanceRequestAction, getScholasticOverAllPerformanceData } from '../../../../store/actions/ArchivePerformanceScoreAction';


const ArchiveChartCard = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const functionalCallFirstTimeRef = useRef(true);
	const [chartSeries, setChartSeries] = useState();
	const [showTableModal, setShowTableModal] = useState(false)
	const [standard, setStandard] = useState('')
	const [board_name, setBoard_name] = useState('')
	const totalRef = useRef(true);

	const scholasticOverAllPerformanceTotal = useSelector((state) => state.archivePerformance.scholasticOverAllPerformance);

	useEffect(() => {
		if (functionalCallFirstTimeRef.current) {
			dispatch(performanceRequestAction(true))
			dispatch(getScholasticOverAllPerformanceData(props.group_subject_id,props.standard, props.history))  /* get performance details */
		}
		functionalCallFirstTimeRef.current = false;
		if (scholasticOverAllPerformanceTotal != '') {
			if (totalRef.current) {
				setChartSeries(props.scholasticOverAllPerformance[1].total) /* set chart data store */
			}
			totalRef.current = false;
		}
	}, [scholasticOverAllPerformanceTotal]);

	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);

	}, []);


	const chartStyle = {
		height: 157,
		width: 390,
	}

	/* show graphice table modal open */
	const showSetTableData = () => {
		setShowTableModal(true)
	}

	/* show graphice table modal close */
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>

						{chartSeries !== '' && chartSeries !== undefined ?
							<>
								<div className='chart_container flex-1  jcc aic text-center flex_column relative_container justify_self_center performance_score'>
									<div id="chart speed_chart">
										<GaugeChart id="gauge-chart1"
											nrOfLevels={400}
											arcWidth={0.15}
											arcsLength={[0.9, 0.15, 0.15, 0.15]}
											colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
											// percent={chartSeries / 100}
											percent={props.scholasticOverAllPerformance[1].total / 100}
											arcPadding={0.00}
											textColor="#000"
											needleColor="#275D79"
											needleBaseColor="#275D79"
											cornerRadius={0}
											style={chartStyle}
										/>
									</div>
									<div className='needle_base_image_container'>
										<img src={clv} alt="" className='needle_base_image' />
									</div>
								</div>
								<div className="text-center chart_details_text dflex jcc aic">
									{/* <p className="box_details">{chartSeries}%</p> */}
									<p className="box_details">{props.scholasticOverAllPerformance[1].total}%</p>
								</div>
								<div className="bottom_details">
									{/* <div className="btn_details back_btn_color text_black cursorPointer" onClick={MoreDetails}>More Details</div> */}
									{/* <div className="btn_details details_btn_color text_white cursorPointer" onClick={props.showTableData}>Details</div> */}
									<div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
								</div>
							</>
							:
							<div>
								No Data Available
							</div>

						}
					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>Weighted Average Performance</div>
								<p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i> {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i> Weighted Average Performance</p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="10px" className="performance_table_data">
								<thead>
									<tr>
										<th className="text-center light_sky">Label</th>
										<th className="text-center light_sky">Set</th>
										<th className="text-center light_sky">Module</th>
										<th className="text-center light_sky">Mock</th>
										<th className="text-center light_sky">Total</th>

									</tr>

								</thead>
								<tbody>

									{props.scholasticOverAllPerformance.map((item, index) => {
										return (
											<tr className='table-cell' key={index}>
												<td className="text-center light_gray_1">{item.label}</td>
												<td className="text-center light_gray_1">{item.set}</td>
												<td className="text-center light_gray_1">{item.module}</td>
												<td className="text-center light_gray_1">{item.mock}</td>
												<td className="text-center light_gray_1">{item.total}</td>
											</tr>
										)
									})
									}

								</tbody>

							</table>

						</div>

					</div>
				</>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		scholasticOverAllPerformance: state.archivePerformance.scholasticOverAllPerformance,
	};
};
export default connect(mapStateToProps)(ArchiveChartCard);