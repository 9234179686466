import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom"
import { connect, useDispatch, useSelector } from 'react-redux';

import co_logo from "../../../images/clv_logo.png";
import demo_user from "../../../images/demo_user.png";
import dashboard from "../../../images/dashboard.png";
import instruction from "../../../images/Instruction.png";
import { ToastContainer } from 'react-toastify';

import Loader from "../../components/Loader"
import * as utility from '../../../utility/Utility'

import CounterClockComponent from "../../components/exam/components/CounterClockComponent"
import CounterClockSoundComponent from "../../components/exam/components/CounterClockSoundComponent"


import { getScholasticExamQuestionsDataForSubscriber, scholoasticQuestionListForSubscriberRequestAction, getScholasticExamAnswerSubmitForSubscriber, onlineAssessmentListRequestAction, getscholasticIntermExamSubmitforSubscriber, totalAttemptsAction, scholoasticQuestionListForSubscriberSuccessAction, getScholasticExamAnswerSubmitForSubscriberTimeupSubmit, getExamDetailsByExamNoDetails, getOnlineExamWrongWay } from '../../../store/actions/ScholasticAction';
import { selectDemoQuestionNumber, selectPrevousDemoQuestion, selectNextDemoQuestion, timeUpAction, timeUsedForExamAction, alertSoundAction } from '../../../store/actions/demoExamAction';

import ExamInstruction from '../../components/ExamInstruction';

import swal from "sweetalert";
let _visitTime = 0;
let _interval;

const ScholasticExamStart = (props) => {
    const status = useSelector(state => state.student.status);
    const newStudentid = useSelector(state => state.student.newStudentid);

    const board_name = useSelector((state) => state.auth.board_name);
    const standard = useSelector((state) => state.auth.standard);
    const warningSound = useSelector((state) => state.questionNo.warningSound);
    const timeUpWarning = useSelector((state) => state.questionNo.timeUpWarning);
    const time_used = useSelector((state) => state.questionNo.time_used);
    const currentQestionNo = useSelector((state) => state.questionNo.currentQestionNo);
    const total_attempts = useSelector((state) => state.questionNo.total_attempts);
    const total_attempts_count = useSelector((state) => state.questionNo.total_attempts_count);


    const totalAttemptsRef = useRef();
    totalAttemptsRef.current = total_attempts;

    const [isChecked, setIsChecked] = useState(false)
    const [instructionExamModal, setInstructionExamModal] = useState(false);
    const [informationModal, setInformationModal] = useState(false);
    const [timeUpModal, setTimeUpModal] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [is_visited_count, setIs_visited_count] = useState(0)
    const [is_answered_count, setIs_answered_count] = useState(0)
    const mounted = useRef(false);


    const [previousValue, setPrevousValue] = useState(location.state)

    const [selected, setSelected] = useState([]);
    const [showQuestion, setShowQuestion] = useState(false)

    const [examTime, setExamTime] = useState(0)
    const [pendingTime, setPendingTime] = useState(0)
    const [lastQuestionNumber, setLastQuestionNumber] = useState()

    const InstructionExamModalRef = useRef(true);
    const OneTimeQuestionCalledRef = useRef(true);

    const currentTimeRef = useRef();
    currentTimeRef.current = pendingTime;

    const currentQuestionLengthRef = useRef();
    currentQuestionLengthRef.current = props.scholasticQuestionListForSubscriber

    const [isFullScreen, setIsFullScreen] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [alertSound, setAlertSound] = useState(0);
    const [intermData, setIntermData] = useState(1) //intermData
    const [calllCurrentQuestionNo, setCalllCurrentQuestionNo] = useState(true) //intermData

    const [userImage, setUserImage] = useState(demo_user);

    /* fullScreen  off and on */
    const fullScreeTtoggleChange = () => {
        setIsFullScreen(!isFullScreen)
        if (isFullScreen == true) {
            launchFullscreen(document.documentElement)
        } else {
            exitFullscreen();
        }
    }

    useEffect(() => {
        let getData = localStorage.getItem('userDetails');
        let profile_pic = JSON.parse(getData).profile_pic;

        setUserImage(profile_pic != '' && profile_pic != undefined && profile_pic != null ? profile_pic : demo_user)

        if (location.state == undefined) {
            let getData = localStorage.getItem('examConditionData');
            setPrevousValue(JSON.parse(getData));
        }

        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)

        if (InstructionExamModalRef.current) {
            setInstructionExamModal(true);
        }
        InstructionExamModalRef.current = false;

        const interval = setInterval(() => {
            showEquition()
        }, 100);

        showEquition()

        return () => {
            document.removeEventListener('contextmenu', handleContextmenu)
            clearInterval(interval)
        };
    }, [board_name, standard, isPlaying]);

    useEffect(() => {
        const preventUnload = (event) => {
            // NOTE: This message isn't used in modern browsers, but is required
            const message = 'Sure you want to leave?';
            event.preventDefault();
            return event.returnValue = message;
        };

        window.addEventListener('beforeunload', preventUnload);

        return () => {
            window.removeEventListener('beforeunload', preventUnload);
            dispatch(scholoasticQuestionListForSubscriberSuccessAction([]))
            exitFullscreen();
            dispatch(alertSoundAction(0))
        };
    }, []);

    //-----
    useEffect(() => {
        // document.addEventListener('keydown', detectKeyDown, true)

        if (timeUpWarning == 1) {
            setIsPlaying(false);
            setTimeUpModal(true);
            ScholasticExamSubmitTimeup()
        }
        if (timeUpWarning == 0) {
            setTimeUpModal(false)
        }

        /* return () => {

        } */
    }, [warningSound, timeUpWarning,]);

    useEffect(() => {
        setPendingTime(time_used)

    }, [time_used, calllCurrentQuestionNo,]); //

    useEffect(() => {

        if (total_attempts == 1) { // reload exam intermData
            props.scholasticQuestionListForSubscriber[props.questionNo].is_visited = 1; // reload exam intermData
            ScholasticExamintermSubmit(currentQestionNo) /* save interm Data */
        }

        visited_count();

        if (total_attempts > 1) { // reload exam intermData
            dispatch(selectDemoQuestionNumber(props.scholasticQuestionListForSubscriber[0].last_visited_ques_no))// intermData reload exam -- current question number
            storeAnswerValue()
        }

        if (total_attempts > 3) { // reload exam intermData if tatal attempts > 3 exam auto submit
            exitFullscreen();
            ScholasticExamSubmit()
        }

    }, [total_attempts]); //



    useEffect(() => {
        _interval = setInterval(() => {
            _visitTime++;
            ScholasticExamintermSubmitInterval(currentQestionNo) /* auto save interm Data after 20 sec */
        }, 20000);

        if (timeUpWarning == 1) {
            clearInterval(_interval);
        }

        const preventUnload = (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
            ScholasticExamintermSubmitInterval(currentQestionNo);
        };

        window.addEventListener('beforeunload', preventUnload);

        return () => {
            window.removeEventListener('beforeunload', preventUnload);
            clearInterval(_interval);
        }
    }, [timeUpWarning, currentQestionNo])

    /* intermData upload*/
    useEffect(() => {

        answer_count(); // reload exam 
        visited_count(); // reload exam 
        return () => {
            dispatch(totalAttemptsAction(0));
            // dispatch(timeUsedForExamAction(''))
        };
    }, []);


    /* TimeupModal Close */
    useEffect(() => {
        // setTimeUpModal(false)
        return () => {
            dispatch(timeUpAction(0))
            setTimeUpModal(false)
        };
    }, []);

    useEffect(() => {
        if (props.scholasticQuestionListForSubscriber != '' && props.scholasticQuestionListForSubscriber[0].total_attempts <= 3) {
            launchFullscreen(document.documentElement);
        }

    }, [props.scholasticQuestionListForSubscriber]);
    //-----

    useEffect(() => {
        // setTimeout(() => {
        if (!!props.scholasticQuestionListForSubscriber.length) {

            var handler = document.querySelector('.handler');
            var wrapper = document.querySelector('.answer_top_container');
            var boxA = wrapper.querySelector('.question_heading');
            var boxB = wrapper.querySelector('.checkbox_from_container');
            var isHandlerDragging = false;

            document.addEventListener('mousedown', function (e) {
                // If mousedown event is fired from .handler, toggle flag to true
                if (e.target === handler) {
                    isHandlerDragging = true;
                }
            });

            document.addEventListener('mousemove', function (e) {
                // Don't do anything if dragging flag is false
                if (!isHandlerDragging) {
                    return false;
                }

                e.preventDefault();

                // Get offset
                var containerOffsetTop = wrapper.offsetTop;
                var containerOffsetBottom = wrapper.offsetBottom;


                // Get x-coordinate of pointer relative to container
                var pointerRelativeXpos = e.clientY - containerOffsetTop;
                var pointerRelativeXpos2 = e.clientY - e.offsetTop + e.offsetHeight;


                var boxAminWidth = 30;


                boxA.style.height = (Math.max(boxAminWidth, pointerRelativeXpos - 2)) + 'px';
                boxB.style.height = (Math.max(boxAminWidth, pointerRelativeXpos2 - 8)) + 'px';
            });
            document.addEventListener('mouseup', function (e) {
                // Turn off dragging flag when user mouse is up
                isHandlerDragging = false;
            });
        }
        // }, 5000)
    }, [props.scholasticQuestionListForSubscriber]);

    useEffect(() => {
        if (!!props.scholasticQuestionListForSubscriber.length) {
            var wrapper = document.querySelector('.answer_top_container');
            var boxA = wrapper.querySelector('.question_heading');
            boxA.style.height = '250px';
        }
    }, [props.questionNo]);

    /* show Math equition */
    const showEquition = () => {
        if (typeof window?.MathJax !== undefined) {
            window.MathJax.typesetClear();
            window.MathJax.typeset();
            window.MathJax.typesetPromise();
        }
    }

    /* show Full Screen */
    const launchFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }

    /* show exit Full Screen */
    const exitFullscreen = () => {
        // if (document.exitFullscreen) {
        if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    /* upload question after click start exam */
    const uploadQuestion = () => {
        if (OneTimeQuestionCalledRef.current && location.state != undefined) {
            dispatch(selectDemoQuestionNumber(0)) /* question reset */

            // setInstructionExamModal(true);
            dispatch(scholoasticQuestionListForSubscriberRequestAction(true));/* question download loader */
            dispatch(getScholasticExamQuestionsDataForSubscriber(previousValue.branchSortCode, previousValue.chapter, previousValue.subject_id, previousValue.set_no, previousValue.chapter_no, previousValue.group_subject_id, props.history));  /* download question */
        } else {
            /*  utility.showError("Sorry! You have entered in worng way");
             history.push({ pathname: "/dashboard" }) */
            dispatch(getOnlineExamWrongWay(props.history)) /* if url copy and paste then go to dashboard  */

        }

        OneTimeQuestionCalledRef.current = false;
    }

    /* instruction off and start Exam */
    const startExam = () => {
        uploadQuestion();
        setInstructionExamModal(false)
        setShowQuestion(true)
        // launchFullscreen(document.documentElement);
        visited_count();
    }

    let counterTimerobj;

    /* start Exam time */
    const startTimer = () => {
        var examTime = 60 * props.scholasticQuestionListForSubscriber[0].exam_duration;
        var timer = examTime, minutes, seconds;

        counterTimerobj = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            setExamTime(minutes + ":" + seconds)

            if (timer <= 300 && timer >= 295) {
                setAlertSound(1);
            } else if (timer <= 294) {
                setAlertSound(0);
            }

            if (--timer == 0) {
                minutes = "00";
                seconds = "00"
                setExamTime(minutes + ":" + seconds)
                setTimeUpModal(true);
                clearInterval(counterTimerobj);
            }
        }, 1000);
    }



    // reload exam  intermData
    const storeAnswerValue = () => {
        var ansdata = [];
        var id_wise_value = null;

        for (var i = 0; i < props.scholasticQuestionListForSubscriber.length; i++) {
            id_wise_value = {};
            id_wise_value = props.scholasticQuestionListForSubscriber[i].is_answered_data //guest_post_ans;
            // id_wise_value['is_visit'] = props.scholasticQuestionList[i].is_visited;
            ansdata.push(id_wise_value);
        }
        setSelected(ansdata);
        visited_count();
        answer_count();
    }
    // reload exam  intermData

    /* updated select question number */
    const questNumber = (id) => {
        dispatch(selectDemoQuestionNumber(id))
        if (props.scholasticQuestionListForSubscriber[id].is_visited == 1) {
            if (props.scholasticQuestionListForSubscriber[id].is_answered == 1) {
                props.scholasticQuestionListForSubscriber[id].is_visited = 0;
            } else {
                //do nothing
            }
        } else {
            if (props.scholasticQuestionListForSubscriber[id].is_answered && props.scholasticQuestionListForSubscriber[id].is_answered == 1) {
                props.scholasticQuestionListForSubscriber[id].is_visited = 0;

            } else {
                props.scholasticQuestionListForSubscriber[id].is_visited = 1;
            }
            answer_count();
            visited_count();
        }
        setCalllCurrentQuestionNo(!calllCurrentQuestionNo) //intermData
        ScholasticExamintermSubmit(id) //intermData
    }

    /* quit from exam and back to dashboard */
    const backToDashboard = () => {
        exitFullscreen();
        dispatch(scholoasticQuestionListForSubscriberSuccessAction([])) /* question data store as blank */
        history.push({ pathname: "/dashboard" })
    }

    /* Select previous Question */
    const prevousQuestion = (currentionNumver) => {
        dispatch(selectDemoQuestionNumber(currentionNumver - 1))
        dispatch(selectPrevousDemoQuestion(currentionNumver - 1));
        props.scholasticQuestionListForSubscriber[currentionNumver - 1].is_visited = 1;
        visited_count();
        if (props.scholasticQuestionListForSubscriber[currentionNumver - 1].is_answered == 1) {
            props.scholasticQuestionListForSubscriber[currentionNumver - 1].is_visited = 0;
        } else {
            //do nothing
        }
        setCalllCurrentQuestionNo(!calllCurrentQuestionNo) // intermData
        ScholasticExamintermSubmit(currentionNumver - 1) //intermData
    }

    /* Select next Question */
    const nextQuestion = (currentionNumver) => {
        dispatch(getExamDetailsByExamNoDetails());

        dispatch(selectDemoQuestionNumber(currentionNumver + 1))
        dispatch(selectNextDemoQuestion(currentionNumver + 1))
        props.scholasticQuestionListForSubscriber[currentionNumver + 1].is_visited = 1;
        visited_count();
        if (props.scholasticQuestionListForSubscriber[currentionNumver + 1].is_answered == 1) {
            props.scholasticQuestionListForSubscriber[currentionNumver + 1].is_visited = 0;
        } else {
            //do nothing
        }
        setCalllCurrentQuestionNo(!calllCurrentQuestionNo) //intermData
        ScholasticExamintermSubmit(currentionNumver + 1) //intermData
    }

    /* Select answer option Question */
    const answerSetSelected = (data, index) => {

        dispatch(selectDemoQuestionNumber(props.questionNo))
        let temp_data = [...selected]
        temp_data[props.questionNo] = data

        setSelected(temp_data)
        props.scholasticQuestionListForSubscriber[props.questionNo].is_answered = 1;
        props.scholasticQuestionListForSubscriber[props.questionNo].is_answered_data = data;
        props.scholasticQuestionListForSubscriber[props.questionNo].is_visited = 0;
        answer_count();
        visited_count();
        if (utility.decryptAES(props.scholasticQuestionListForSubscriber[props.questionNo].answer) == data) {
            props.scholasticQuestionListForSubscriber[props.questionNo].is_corrected = 1;
        } else {
            props.scholasticQuestionListForSubscriber[props.questionNo].is_corrected = 0;
        }
        setCalllCurrentQuestionNo(!calllCurrentQuestionNo) //intermData
        ScholasticExamintermSubmit(props.questionNo) //intermData

    }

    /* how many qustion visit this exam */
    const visited_count = () => {
        var rez = 0;
        props.scholasticQuestionListForSubscriber.forEach(function (item) {
            if (item.is_visited >= 0) {
                rez++;
            }
        });
        setIs_visited_count(rez)
    }

    /* how many questions give answer this exam */
    const answer_count = () => {
        var rez = 0;
        props.scholasticQuestionListForSubscriber.forEach(function (item) {
            if (item.is_answered >= 0) {
                rez++;
            }
        });
        setIs_answered_count(rez);
    }

    /* Exam interm data Submit */
    const ScholasticExamintermSubmit = (current_question_number) => {
        // console.log("ScholasticExamintermSubmit==============", props.questionNo, props.scholasticQuestionListForSubscriber.length)
        var examdata = [];
        var id_wise_value = null;

        for (var i = 0; i < props.scholasticQuestionListForSubscriber.length; i++) {
            id_wise_value = {};
            id_wise_value['question_id'] = props.scholasticQuestionListForSubscriber[i].id;
            id_wise_value['question_no'] = props.scholasticQuestionListForSubscriber[i].question_no;
            id_wise_value['guest_post_ans'] = props.scholasticQuestionListForSubscriber[i].is_answered_data;
            id_wise_value['guest_post_ans_status'] = props.scholasticQuestionListForSubscriber[i].is_corrected;
            id_wise_value['is_visit'] = props.scholasticQuestionListForSubscriber[i].is_visited;
            id_wise_value['is_answered'] = props.scholasticQuestionListForSubscriber[i].is_answered;
            id_wise_value['is_answered_data'] = props.scholasticQuestionListForSubscriber[i].is_answered_data;
            id_wise_value['is_corrected'] = props.scholasticQuestionListForSubscriber[i].is_corrected;
            id_wise_value['is_visited'] = props.scholasticQuestionListForSubscriber[i].is_visited;
            examdata.push(id_wise_value);
        }

        if (examdata != "") {
            // console.log("ScholasticExamintermSubmit-----", pendingTime, time_used, currentTimeRef.current)
            // dispatch(onlineAssessmentListRequestAction(true))
            dispatch(getscholasticIntermExamSubmitforSubscriber(previousValue.exam_type, previousValue.branchSortCode, previousValue.chapter, previousValue.setlectSet, examdata, previousValue.subject_id, props.scholasticQuestionListForSubscriber[0].exam_category, time_used, current_question_number, previousValue.group_subject_id, history)); //props.questionNo
        }
    }

    /* Exam interm data Submit after a fix time */
    const ScholasticExamintermSubmitInterval = (current_question_number) => {
        // console.log("ScholasticExamintermSubmitInterval======333========", current_question_number, "=+=", currentQuestionLengthRef.current)
        var examdata = [];
        var id_wise_value = null;

        for (var i = 0; i < currentQuestionLengthRef.current.length; i++) {
            id_wise_value = {};
            id_wise_value['question_id'] = currentQuestionLengthRef.current[i].id;
            id_wise_value['question_no'] = currentQuestionLengthRef.current[i].question_no;
            id_wise_value['guest_post_ans'] = currentQuestionLengthRef.current[i].is_answered_data;
            id_wise_value['guest_post_ans_status'] = currentQuestionLengthRef.current[i].is_corrected;
            id_wise_value['is_visit'] = currentQuestionLengthRef.current[i].is_visited;
            id_wise_value['is_answered'] = currentQuestionLengthRef.current[i].is_answered;
            id_wise_value['is_answered_data'] = currentQuestionLengthRef.current[i].is_answered_data;
            id_wise_value['is_corrected'] = currentQuestionLengthRef.current[i].is_corrected;
            id_wise_value['is_visited'] = currentQuestionLengthRef.current[i].is_visited;
            examdata.push(id_wise_value);
        }

        if (examdata != "") {
            dispatch(getscholasticIntermExamSubmitforSubscriber(previousValue.exam_type, previousValue.branchSortCode, previousValue.chapter, previousValue.setlectSet, examdata, previousValue.subject_id, currentQuestionLengthRef.current[0].exam_category, currentTimeRef.current, current_question_number, previousValue.group_subject_id, history)); //props.questionNo
        }
    }

    /* Exam Submit */
    const ScholasticExamSubmit = () => {
        var examdata = [];
        var id_wise_value = null;

        for (var i = 0; i < props.scholasticQuestionListForSubscriber.length; i++) {
            id_wise_value = {};
            id_wise_value['question_id'] = props.scholasticQuestionListForSubscriber[i].id;
            id_wise_value['question_no'] = props.scholasticQuestionListForSubscriber[i].question_no;
            id_wise_value['guest_post_ans'] = props.scholasticQuestionListForSubscriber[i].is_answered_data;
            id_wise_value['guest_post_ans_status'] = props.scholasticQuestionListForSubscriber[i].is_corrected;
            examdata.push(id_wise_value);
        }
        if (examdata != "") {
            exitFullscreen();
            dispatch(scholoasticQuestionListForSubscriberSuccessAction([]))
            dispatch(onlineAssessmentListRequestAction(true))
            dispatch(getScholasticExamAnswerSubmitForSubscriber(previousValue.exam_type, previousValue.branchSortCode, previousValue.chapter, previousValue.setlectSet, examdata, previousValue.subject_id, previousValue.chapter_no, previousValue.group_subject_id, history));
        }
    }

    /* Exam Submit after exam time up */
    const ScholasticExamSubmitTimeup = () => {
        // console.log("Submit Fnal Answer Submit Timeup",)

        var examdata = [];
        var id_wise_value = null;

        for (var i = 0; i < props.scholasticQuestionListForSubscriber.length; i++) {
            id_wise_value = {};
            id_wise_value['question_id'] = props.scholasticQuestionListForSubscriber[i].id;
            id_wise_value['question_no'] = props.scholasticQuestionListForSubscriber[i].question_no;
            id_wise_value['guest_post_ans'] = props.scholasticQuestionListForSubscriber[i].is_answered_data;
            id_wise_value['guest_post_ans_status'] = props.scholasticQuestionListForSubscriber[i].is_corrected;
            examdata.push(id_wise_value);
        }
        if (examdata != "") {
            // exitFullscreen();
            // dispatch(scholoasticQuestionListForSubscriberSuccessAction([]))
            dispatch(onlineAssessmentListRequestAction(true))
            dispatch(getScholasticExamAnswerSubmitForSubscriberTimeupSubmit(previousValue.exam_type, previousValue.branchSortCode, previousValue.chapter, previousValue.setlectSet, examdata, previousValue.subject_id, previousValue.chapter_no, previousValue.group_subject_id, history));
        }
    }

    /* Exam Submit */
    const submitFinalAnswer = () => {
        setIsPlaying(false)
        ScholasticExamSubmit();
    }
    /* Exam Submit after exam time up */
    const timeUpSubmit = () => {
        setIsPlaying(false)
        dispatch(timeUpAction(0)) //-----
        submitFinalAnswer();
    }

    /* confirmation before Exam submit  */
    const submitFinalAnswerWithAlert = () => {
        swal({
            title: "Do you want to submit?",
            text:
                "",
            icon: "warning",
            buttons: true,
            dangerMode: false,
        }).then((willDelete) => {
            if (willDelete) {
                submitFinalAnswer();
            }
        })
    }

    /* confirmation before back to Dashboard  */
    const gobackDashboardWithAlert = () => {
        total_attempts > 2 ?
            swal({
                title: "This is your last attempts!",
                text:
                    "Are you sure you want to go back to dashboard?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    backToDashboard();
                }
            })
            :
            swal({
                title: "Do you want to go back to dashboard?",
                text:
                    "",
                icon: "warning",
                buttons: true,
                dangerMode: false,
            }).then((willDelete) => {
                if (willDelete) {
                    backToDashboard();
                }
            })
    }

    return (
        <>
            <div className="body_container">

                {props.scholasticQuestionListForSubscriber == "" || props.scholasticQuestionListForSubscriber == undefined && props.questionNo != undefined ?

                    null
                    :
                    <>
                        <div className="row">
                            <div className="exam_start_top_container">
                                <div className="col-md-2" >
                                    <img
                                        src={co_logo}
                                        className="exam_start_logo"
                                        alt="profile"
                                    />
                                </div>

                                <div className="col-md-4 subject_name_container">
                                    <div>
                                        <h4>Class: {standard}, Board: {board_name}</h4>{/*  */}
                                        <h5>Subject: {props.scholasticQuestionListForSubscriber[0].subject_name} {props.scholasticQuestionListForSubscriber[0].subject_name != props.scholasticQuestionListForSubscriber[0].branch_name && props.scholasticQuestionListForSubscriber[0].branch_name != '' ? <> {'/' + props.scholasticQuestionListForSubscriber[0].branch_name} </> : null} / Test: {previousValue.set_no == 'cs1' ? "Case Study" : previousValue.set_no} / {props.scholasticQuestionListForSubscriber[0].chapter_name}{`(${props.scholasticQuestionListForSubscriber[0].chapter_title})`
                                        }</h5>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="right_side_top_container">

                                        <div className={`attempt_text right_indidual_contaoiner ${props.scholasticQuestionListForSubscriber[0].total_attempts > 2 ? 'font_red' : null}`}>
                                            <div className='text-center'>No. of Attempts :</div>
                                            <div className='text-center'>{props.scholasticQuestionListForSubscriber[0].total_attempts} / 3</div>
                                        </div>

                                        <div className="right_indidual_contaoiner">
                                            <div className="timer_ounter">
                                                {showQuestion ?
                                                    <>
                                                        <CounterClockComponent examTime={props.scholasticQuestionListForSubscriber[0].exam_duration} isPlaying={isPlaying} />
                                                        {/* <CounterClockComponent examTime={310} isPlaying={isPlaying} /> */} 
                                                    </>
                                                    :
                                                    <div className="clock_icon">
                                                        <i className="mdi mdi-clock"></i>
                                                    </div>
                                                }
                                            </div>

                                        </div>

                                        <div className="right_indidual_contaoiner">
                                            <Link to="#" className="link_container">
                                                <div className="fullScreen" onClick={() => fullScreeTtoggleChange()}>
                                                    {isFullScreen ? <i className="bi bi-arrows-fullscreen text_blue"></i> : <i className="bi bi-fullscreen-exit text_blue"></i>}

                                                </div>
                                                <div className="icon_description text_blue">
                                                    Full Screen
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="right_indidual_contaoiner">
                                            <Link to="#" className="link_container">
                                                <div className="image-container" onClick={() => setInformationModal(true)}>
                                                    <img
                                                        src={instruction}
                                                        className="right_side_icon_size"
                                                        alt="CreaTest"
                                                    />
                                                </div>
                                                <div className="icon_description text_blue">
                                                    Instruction
                                                </div>
                                            </Link>
                                        </div>

                                        <Link to="#" className="link_container" onClick={() => gobackDashboardWithAlert()}>
                                            <div className="right_indidual_contaoiner">
                                                <div className="image-container">
                                                    <img
                                                        src={dashboard}
                                                        className="right_side_icon_size"
                                                        alt="CreaTest"
                                                    />
                                                </div>
                                                <div className="icon_description text_blue">
                                                    Dashboard
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="right_indidual_contaoiner">
                                            <Link to="#" className="link_container">
                                                <div className="image-container ">
                                                    <img

                                                        src={userImage}
                                                        className="right_side_icon_size border_radious_50 userImageSize"
                                                        alt="CreaTest"
                                                    />
                                                </div>
                                                <div className="icon_description text_blue text-center">

                                                    {props.user_name.split(' ')[0]}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="answer_container_parent">
                            <div className="answer_container">
                                <div className="answer_top_container parent_container_new">
                                    <div className="question_heading  precent_text"> {/* text_blue */}

                                        {showQuestion ?
                                            (props.scholasticQuestionListForSubscriber.length <= 0 && props.scholasticQuestionListForSubscriber == "") ? null : <div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionListForSubscriber[props.questionNo].question }}></div>
                                            : null}

                                    </div>
                                    <div class="handler"></div>

                                    {showQuestion ?

                                        props.scholasticQuestionListForSubscriber.length <= 0 ? null :
                                            <form className="checkbox_from_container" onSubmit={(e) => e.preventDefault()}>
                                                <div className="checkbox_container">

                                                    <div className="container">
                                                        <div className="checkbox_inside_container unselect_text">
                                                            <div className="row option_container">
                                                                {
                                                                    Object.keys(props.scholasticQuestionListForSubscriber[props.questionNo].options[0]).map(([key, value]) => {

                                                                        return (
                                                                            <React.Fragment key={key}>

                                                                                <div className="form-group col-md-12">
                                                                                    <div className="form-check mb-2 modern-radio-container"
                                                                                        onClick={() => { answerSetSelected(key) }}
                                                                                    >
                                                                                        {selected[props.questionNo] == key ?
                                                                                            <>
                                                                                                <div className="key_value">{key}{":"}</div>
                                                                                                <div className="radio-outer-circle unselected">
                                                                                                    <i className="mdi mdi-check check_mark"></i>
                                                                                                </div>

                                                                                                <div className="helper-text"><div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionListForSubscriber[props.questionNo].options[0][key] }}></div></div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <div className="key_value">{key}{":"}</div>
                                                                                                <div className="radio-outer-circle unselected" >

                                                                                                </div>

                                                                                                <div className="helper-text"><div dangerouslySetInnerHTML={{ __html: props.scholasticQuestionListForSubscriber[props.questionNo].options[0][key] }}></div> </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        : null}
                                    {/* </div> */}

                                </div>
                                <div className="display_question_number blue_background">
                                    {(props.scholasticQuestionListForSubscriber.length <= 0 && props.scholasticQuestionListForSubscriber == "") ? null :
                                        <>
                                            {showQuestion ?
                                                <div className="view_question_number_container">
                                                    <div className="left_arrow_container">
                                                        {props.questionNo === 0 ?
                                                            <div className="icon_container">
                                                                <div className="arrow_text text-muted">Prev</div>
                                                                <div><i className="bi bi-chevron-left text-warning text-muted"></i></div>
                                                            </div>
                                                            :
                                                            <Link to="#">

                                                                <div className="icon_container" onClick={() => prevousQuestion(props.questionNo)}>
                                                                    <div className="arrow_text">Prev</div>
                                                                    <div><i className="bi bi-chevron-left text-warning"></i></div>
                                                                </div>
                                                            </Link>
                                                        }
                                                    </div>
                                                    <div className="question_display">
                                                        Displaying <span className="text-warning count_number">{props.questionNo + 1}</span> of <span className="text-warning count_number">{props.scholasticQuestionListForSubscriber.length}</span> Questions
                                                    </div>



                                                    <div className="right_arrow_container">
                                                        {props.questionNo === props.scholasticQuestionListForSubscriber.length - 1 ?

                                                            <div className="icon_container">
                                                                <div><i className="bi bi-chevron-right text-warning text-muted" ></i></div>
                                                                <div className="arrow_text text-muted">Next</div>
                                                            </div>
                                                            :
                                                            <Link to="#">

                                                                <div className="icon_container" onClick={() => nextQuestion(props.questionNo)}>
                                                                    <div><i className="bi bi-chevron-right text-warning" ></i></div>
                                                                    <div className="arrow_text">Next</div>
                                                                </div>
                                                            </Link>
                                                        }
                                                    </div>
                                                </div>
                                                : null}


                                        </>
                                    }
                                </div>
                            </div>
                            <div className="question_no_container">
                                <div className="select_question_number">
                                    <div className="inside_select_question_number">
                                        {showQuestion ?
                                            props.scholasticQuestionListForSubscriber.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Link to="#">
                                                            <div className={`question_number ${item.is_visited === 1 ? `blue_background` : item.is_answered === 1 ? `green_background_one ` : `orange_background`} ${index == props.questionNo ? `selectQuestionBox` : ''}`} onClick={() => questNumber(index)}>

                                                                <div className="que_number" >{item.question_counter}</div>
                                                            </div>
                                                        </Link>
                                                    </React.Fragment>
                                                )
                                            })

                                            : null
                                        }

                                    </div>

                                </div>
                                <div className="count_status_question">
                                    <div className="count_container green_background_one">

                                        <div className="count_number">{showQuestion ? is_answered_count : 0}</div>
                                        <div className="count_status">Answered</div>
                                    </div>
                                    <div className="count_container blue_background">

                                        <div className="count_number">{showQuestion ? is_visited_count : 0}</div>
                                        <div className="count_status">Visited</div>
                                    </div>
                                    <div className="count_container orange_background">

                                        <div className="count_number">{showQuestion ? (props.scholasticQuestionListForSubscriber.length - is_visited_count) : 0}</div>
                                        <div className="count_status">Not Visited</div>
                                    </div>

                                </div>
                                <div className="submit_container">
                                    <Link to="#">

                                        <div className="btn btn-warning blue_background text-center" onClick={submitFinalAnswerWithAlert}>Submit</div>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </>

                }


                <Modal className="fade" show={instructionExamModal} scrollable={true}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Basic instructions for online examinations:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ExamInstruction />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="danger text-center modal_footer_button"
                                    onClick={() => history.goBack()}
                                >
                                    Quit exam
                                </Button>
                                <Button
                                    variant="success text-center modal_footer_button"
                                    onClick={startExam}
                                >
                                    Start exam
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={informationModal} scrollable={true} >
                    <Modal.Header>
                        <Modal.Title className="modal_title">Basic instructions for online examinations:</Modal.Title>
                        <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setInformationModal(false)}
                        ></Button>
                    </Modal.Header>
                    <Modal.Body >
                        <ExamInstruction />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="danger text-center modal_footer_button"
                                    onClick={() => setInformationModal(false)}
                                >
                                    Close
                                </Button>

                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                <Modal className="fade" show={timeUpModal}>
                    <Modal.Header>
                        <Modal.Title className="modal_title">Timeup</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        Click on submit button to view your assessment
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal_footer_container_info">
                            <div className="modal_footer_left_container">
                                <p className="footer_text">Good Luck</p>
                                <p className="footer_text">Team Crestest</p>
                            </div>
                            <div className="modal_footer_right_container">
                                <Button
                                    variant="warning text-center modal_footer_button"
                                    onClick={timeUpSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                {props.loading ?
                    <Loader /> : null}
            </div>
            <div>

                {warningSound == 1 ?
                    <>
                        <CounterClockSoundComponent isPlaying={warningSound == 1 ? true : false} />
                    </>

                    : null}
            </div>
            <div><ToastContainer /></div>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        user_name: state.auth.fname,
        questionNo: state.questionNo.currentQestionNo,
        scholasticQuestionListForSubscriber: state.branch.scholasticQuestionListForSubscriber,
        loading: state.onlineexam.loading
    };
};
export default connect(mapStateToProps)(ScholasticExamStart);