import React, { Fragment, useState, useEffect, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Row, Col, Modal, Button, Spinner, Tooltip } from "react-bootstrap";
import Loader from "../../components/Loader";
import AddProfileDetailsCard from "./AddProfileDetailsCard";

import {
  addToCartData,
  subscribeLoading,
  getScSubjectData,
  showRightNavAction,
  getSCholasticCombinePriceData,
  scholasticCombinationPriceIdAction,
  scholasticSubscriptionSourceAction,
  compititiveSubscriptionSourceAction,
} from "../../../store/actions/SubscribeAction";

import {
  updateStudentProfileOfSubscription,
} from "../../../store/actions/ProfileAction";

let checkBoxValue = [];

const SubcribeScholasticCard = (props) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  // const [sets, setSets] = useState(props.data.purchased_no_set ? props.data.purchased_no_set : 1);
  const [sets, setSets] = useState(0);
  const [isModules, setIsModules] = useState(
    props.data.purchased_no_module == 0 ? false : true
  );
  const [isMock, setIsMock] = useState(
    props.data.purchased_no_mock == 0 ? false : true
  );
  const [isBundle, setIsBundle] = useState(
    props.data.purchased_case_study == 0 ? false : true
  );
  // const [total, setTotal] = useState(props.amountperSet);
  const [mock, setMock] = useState(0);
  const [module, setModule] = useState(0);
  const [bundle, setBundle] = useState(0);
  const [setAmount, setSetAmount] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [isLibrary, setIsLibrary] = useState(
    props.data.has_library == 0 ? false : true
  );
  const [library, setLibrary] = useState(0);
  const [isDetails, setIsDetails] = useState(false);
  const [isLibrarySelected, setLibrarySelected] = useState(0);

  const [selectMock, setSelectMock] = useState(
    props.data.purchased_no_mock != 0 ? false : true
  );
  // const [selectMock, setSelectMock] = useState(true);
  const [case_studies, setCase_studies] = useState(0);
  const [optionChange, setOptionChange] = useState(false);
  const [totalCartPrice, setTotalCartPrice] = useState(props.combinePrice);
  const callOnUpdationRef = useRef(false);

  // const [chapterTest, setChapterTest] = useState(props.data.purchased_no_test != '' ? props.data.purchased_no_test : [1])
  // const [chapterTest, setChapterTest] = useState(props.data.purchased_no_test != '' ? [3, 1] : [1])
  const [chapterTest, setChapterTest] = useState(
    props.data.purchased_no_test != "" ? props.data.remaning_set_no : [1]
  );
  // const [chapterTest, setChapterTest] = useState([])

  const [selectedChapterTest, setSelectedChapterTest] = useState([]);

  const [checkBoxDisable, setCheckBoxDisable] = useState(
    props.data.purchased_no_test != "" ? props.data.purchased_no_test : []
  );

  const [testCartSelect, setTestCartSelect] = useState([]);

  const [selectSubscriptionCard, setSelectSubscriptionCard] = useState();
  const [selectElibraryFromCard, setSelectElibraryFromCard] = useState([]);
  const [isProfileUpdated, setIsProfileUpdated] = useState('');

  const history = useHistory();

  const scholasticCombinePrice = useSelector(
    (state) => state.subscribe.scholasticCombinePrice
  );

  useEffect(() => {
    // console.log("chapterTest--11--", props.data.subject_name, chapterTest, "module==", module, "mock--", mock, "library--", library)

    setTestCartSelect(
      props.data.purchased_no_test == "" ? chapterTest : selectedChapterTest
    );

    if (callOnUpdationRef.current) {
      dispatch(subscribeLoading(true));
      callCompbinePrice();
      dispatch(scholasticCombinationPriceIdAction(null));
      // setOptionChange(false)
    }
    const interval = setInterval(() => {
      showChangeValue();
    }, 1200);

    callOnUpdationRef.current = false;

    return () => {
      clearInterval(interval);
    };
  }, [
    sets,
    module,
    mock,
    library,
    case_studies,
    bundle,
    callOnUpdationRef,
    optionChange,
    chapterTest,
  ]);

  const showChangeValue = () => {
    setOptionChange(false);
  };

  /* useEffect(() => {
    // if (isProfileUpdated != 0) {
      setIsProfileUpdated(props?.checkProfile?.isComplete)
    // }
  }, [props?.checkProfile?.isComplete]); */

  useEffect(() => {
    getSubscriptionCardStatus();

    /* for after remove cart update e-library value */
    let isSelected = getCheckedStatus();
    setLibrary(isSelected ? 1 : 0);
    callOnUpdationRef.current = true;
    /* for after remove cart update e-library value */
  }, [props.cartList]);

  /* useEffect(() => {
    if (props.data.purchased_no_test.length == 1) {
      setTestCartSelect([2])
      setSelectedChapterTest([2])
    } 
  }, [props.data]); */

  useEffect(() => {
    if (props.data.purchased_no_test != "") {
      let OnlySelectedArray = props.data.remaning_set_no.filter(
        (el) => !props.data.purchased_no_test.includes(el)
      );
      setTestCartSelect(OnlySelectedArray);
      setSelectedChapterTest(OnlySelectedArray);
    }
  }, [props.data]);

  const getSubscriptionCardStatus = () => {
    let selectedCard = props.cartList.find((element) => {
      return element.subscription_id == props.id;
    });

    if (!selectedCard) {
      //setSelectSubscriptionCard(selectedCard.subscription_id);
      setIsLibrary(0);
    }
  };

  const onModulesHandler = (event) => {
    setIsModules(!isModules);
    setModule(isModules ? 0 : 1);
    setSelectMock(!isModules ? false : true);
    if (isModules == true) {
      setIsMock(false);
      setMock(0);
    }
    callOnUpdationRef.current = true;
  };

  const onMockHandler = (event) => {
    setIsMock(!isMock);
    setMock(isMock ? 0 : 1);
    callOnUpdationRef.current = true;
  };

  const onBundleHandler = () => {
    setIsBundle(!isBundle);
    setBundle(isBundle ? 0 : 1);
    setCase_studies(isBundle ? 0 : 1);
    callOnUpdationRef.current = true;
  };

  const onChangeRadio = (event) => {
    setSets(event.target.value);
    callOnUpdationRef.current = true;
  };

  const onProgress = () => {
    dispatch(subscribeLoading(true));

    let temp_data = props.subject_cards.filter(
      (obj) => obj.subject_id != props.id
    );

    let isSelected = getCheckedStatus();

    dispatch(scholasticSubscriptionSourceAction(temp_data));

    // dispatch(addToCartData(props.category_id, props.id, props.data.purchased_no_test == '' ? chapterTest : selectedChapterTest, isModules ? module : 0, isMock ? mock : 0, props.board, props.class_no ? props.class_no : 0, finalTotal, isBundle ? 1 : 0, 0, isSelected && props.data.disabled_library == 0 ? 1 : 0, history));
    dispatch(
      addToCartData(
        props.category_id,
        props.id,
        props.data.purchased_no_test == "" ? chapterTest : selectedChapterTest,
        isModules ? module : 0,
        isMock ? mock : 0,
        props.board,
        props.class_no ? props.class_no : 0,
        finalTotal,
        case_studies ? 1 : 0,
        0,
        isSelected && props.data.disabled_library == 0 ? 1 : 0,
        history
      )
    );
    dispatch(showRightNavAction(true));
    setModal(false);
  };

  const onArray = (num) => {
    let array = [];
    for (let x = 1; num >= x; x++) {
      array.push(
        <div className="form-check" key={x}>
          <input
            onChange={onChangeRadio}
            className="form-check-input"
            type="radio"
            name={"set" + props.id + x}
            value={x}
            // checked={sets == x}
            checked={
              props.data.purchased_no_set != 0
                ? props.data.purchased_no_set == x
                : sets == x
            }
            id={"set" + props.id + x}
            disabled={x > 1 ? true : false}
          />
          <label className="form-check-label" htmlFor={"set" + props.id + x}>
            {x}
          </label>
        </div>
      );
    }
    return array;
  };

  const onChapterTests = (num) => {
    let array = [];
    for (let x = 1; num >= x; x++) {
      array.push(
        <div key={x} className="form-check subject-group set_box_width">
          <input
            type="checkbox"
            className="form-check-input"
            id={"test" + x}
            // value={chapterTest}
            // checked={chapterTest.includes(x)}
            checked={chapterTest.includes(x)}
            onChange={(e) => onSubjectHandler(e, x)}
            // disabled={props.data.purchased_no_test.includes(chapterTest)}
            // disabled={chapterTest.find(element => element == props.data.purchased_no_test)}
            // disabled={x == props.data.purchased_no_test[0]}
            disabled={checkBoxDisable.includes(x)}
          />
          <label className="form-check-label" htmlFor={"test" + x}>
            {"Test " + x}
          </label>
        </div>
      );
    }
    return array;
  };

  const onSubjectHandler = (e, item) => {
    // console.log("e, item", item)
    let array = [...chapterTest];
    if (e.target.checked) {
      array.push(item);
    } else {
      const index = array.findIndex((val) => val === item);
      array.splice(index, 1);
    }

    setChapterTest(array);

    if (props.data.purchased_no_test != "") {
      let OnlySelectedArray = array.filter(
        (el) => !props.data.purchased_no_test.includes(el)
      );

      setSelectedChapterTest(OnlySelectedArray);
    }

    callOnUpdationRef.current = true;
  };

  const finalSubscriptionSubmit = () => {
    setModal(true);
    let totalValue = props.combinePrice;
    setFinalTotal(totalValue);
  };

  const updatedProfileInfo = () => {
    setIsDetails(true);
    return;
  }


  const onLibraryHandler = (e, id) => {
    setIsLibrary(!isLibrary);
    setLibrary(isLibrary ? 0 : 1);
    callOnUpdationRef.current = true;

    setSelectSubscriptionCard(!isLibrary ? id : "");

    if (!isLibrary) {
      props.e_library_select_handaler(e, id); //, !isLibrary
    } else {
      props.e_library_unselect_handaler(e, id); //, !isLibrary
    }
  };

  const callCompbinePrice = () => {
    setOptionChange(true);

    dispatch(
      getSCholasticCombinePriceData(
        props.id,
        props.data.purchased_no_test == "" ? chapterTest : selectedChapterTest,
        module,
        mock,
        library,
        case_studies,
        props.history
      )
    );
  };

  const handleClick = (param1) => {
    props.testCheck(param1);
  };

  const getCheckedStatus = () => {
    let status = false;
    //check from redux value
    if (props.subject_cards.length > 0) {
      let combo_subject_ids;
      for (let i = 0; i < props.subject_cards.length; i++) {
        if (props.subject_cards[i].subject_id == props.id) {
          status = true;
          break;
        }
      }
    }

    //check from cart value
    //console.log("props.cartList", props.cartList);
    if (props.cartList.length > 0) {
      for (let i = 0; i < props.cartList.length; i++) {
        //console.log(props.subjectName, "::", props.cartList[i].subscription_id," == ",props.id, props.cartList[i]);
        if (
          props.cartList[i].exam_category_id == props.category_id &&
          props.cartList[i].subscription_id == props.id
        ) {
          if (props.cartList[i].only_elibrary == 1) {
            //do nothing
          } else {
            status = props.cartList[i].has_library == 1 ? true : false;
          }
          break;
        }
      }
    }
    return status;
  };

  const getDisabledStatus = () => {
    let status = false;

    //check from redux value
    if (props.subject_cards.length > 0) {
      let combo_subject_ids;

      for (let i = 0; i < props.subject_cards.length; i++) {
        if (props.subject_cards[i].subject_id != props.id) {
          combo_subject_ids = props.subject_cards[i].combo_subject_ids;
          if (combo_subject_ids) {
            for (let j = 0; j < combo_subject_ids.length; j++) {
              for (let k = 0; k < props.combo_subject_ids.length; k++) {
                //console.log(combo_subject_ids[j]," == ",props.combo_subject_ids[k]);
                if (combo_subject_ids[j] == props.combo_subject_ids[k]) {
                  status = true;
                  break;
                }
              }
            }
          }
        }
      }
    }

    //check from cart value
    if (props.cartList.length > 0) {
      let combo_subject_ids;
      for (let i = 0; i < props.cartList.length; i++) {
        combo_subject_ids = props.cartList[i].combo_subject_ids;
        if (combo_subject_ids) {
          for (let j = 0; j < combo_subject_ids.length; j++) {
            for (let k = 0; k < props.combo_subject_ids.length; k++) {
              if (
                props.cartList[i].exam_category_id == props.category_id &&
                props.cartList[i].has_library == 1 &&
                combo_subject_ids[j] == props.combo_subject_ids[k]
              ) {
                if (
                  props.cartList[i].subscription_id != props.id ||
                  props.cartList[i].only_elibrary == 1
                ) {
                  status = true;
                }
                //}
                break;
              }
            }
          }
        }
      }
    }
    return status;
  };

  const onProfileHandler = (data) => {

    dispatch(
      updateStudentProfileOfSubscription(
        data.pincode,
        data.schoolname,
        data.schooladd,
        updataedStudentProfileDetails,
        props.history
      ))

  }

  const updataedStudentProfileDetails = (data) => {
    if (data != '') {
      setIsDetails(false);
      setIsProfileUpdated(0)
      props.isCompleteStatusUpdate()
      finalSubscriptionSubmit();
    }

  }

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <div
            className={`subcribe-scholastic-card ${props.isPurchased == 1
              ? "subscription-disable"
              : props.cartList.find(
                (element) =>
                  element.subscription_id == props.id &&
                  element.exam_category_id == 1 &&
                  element.only_elibrary == 0
              )
                ? "subscription-disable-at-cart"
                : null
              }`}
          >
            <div className="question-mark-circle">
              <i className="fa-sharp fa-solid fa-question"></i>
            </div>

            <div className="subcribe-scholastic-details">
              {props.sticker_text != "" ? (
                <div className="sticker_base">
                  <div className="sticker_text">{props.sticker_text}</div>
                </div>
              ) : null}
              {props.subject_image != "" &&
                props.subject_image != undefined &&
                props.subject_image != "undefined" ? (
                <img
                  className="sub_img_container"
                  src={props.subject_image}
                  alt=""
                />
              ) : (
                <div className="sub_img_container">No Image</div>
              )}

              <h4>{props.subjectName}</h4>
              {props.combo_subject_names != "" &&
                props.combo_subject_names != null ? (
                <>
                  <h6 className="text-center px-1">
                    ({props.combo_subject_names})
                  </h6>
                </>
              ) : null}
            </div>

            <div className="subcribe-scholastic-configuration">
              <div className="set-scholastic-item">
                <div className="set-scholastic-title">
                  <h4>Chapter test</h4>
                </div>
                <div className="test_container">
                  <div className="set-scholastic-content-subscription">
                    <div className="set-scholastic-content-lft flex-wrap">
                      {onChapterTests(props.data.test_count)}
                    </div>
                  </div>
                </div>
                {props.data.case_studies_exist != 0 ? (
                  <div className="set-scholastic-content-lft">
                    <div className="form-check custom-checkbox checkbox-success">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"bundle" + props.id}
                        name={"bundle" + props.id}
                        value="2"
                        // checked={isBundle}
                        checked={
                          props.cartList.find(
                            (element) =>
                              element.subscription_id == props.id &&
                              element.no_casestudy == 1
                          )
                            ? 1
                            : props.data.no_casestudy == 1
                              ? true
                              : isBundle
                        }
                        onChange={onBundleHandler}
                        disabled={
                          props.data.purchased_case_study == 1 ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"bundle" + props.id}
                      >
                        Case studies
                      </label>
                    </div>
                  </div>
                ) : null}
                {/* </div> */}
              </div>

              <div className="set-scholastic-item">
                <div className="set-scholastic-title">
                  <h4>Module tests</h4>
                </div>
                <div className="set-scholastic-content">
                  <div className="set-scholastic-content-lft">
                    <div className="form-check custom-checkbox checkbox-success">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"modules" + props.id}
                        name={"modules" + props.id}
                        checked={
                          props.cartList.find(
                            (element) =>
                              element.subscription_id == props.id &&
                              element.no_module == 1
                          )
                            ? 1
                            : props.data.purchased_no_module == 1
                              ? true
                              : isModules
                        }
                        onChange={onModulesHandler}
                        disabled={
                          props.data.purchased_no_module == 1 ? true : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"modules" + props.id}
                      >
                        3 Module tests
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="set-scholastic-item">
                <div className="set-scholastic-title">
                  <h4>Mock tests</h4>
                </div>
                <div className="set-scholastic-content">
                  <div className="set-scholastic-content-lft">
                    <div className="form-check custom-checkbox checkbox-success">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"mocks" + props.id}
                        name={"mocks" + props.id}
                        checked={
                          props.cartList.find(
                            (element) =>
                              element.subscription_id == props.id &&
                              element.no_mock == 1
                          )
                            ? 1
                            : isMock
                        }
                        onChange={onMockHandler}
                        disabled={
                          props.cartList.find(
                            (element) =>
                              element.subscription_id == props.id &&
                              element.no_mock == 1
                          )
                            ? false
                            : props.data.purchased_no_mock == 1
                              ? true
                              : props.data.purchased_no_module == 1
                                ? false
                                : selectMock
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"mocks" + props.id}
                      >
                        2 Mock tests
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`set-scholastic-item`}>
                <div className="set-scholastic-title">
                  <h4>e-Library</h4>
                </div>
                <div
                  className={`set-scholastic-content ${props.data.disabled_library == 1
                    ? "subscription-disable"
                    : null
                    }`}
                >
                  <div className="set-scholastic-content-lft">
                    <div className="form-check custom-checkbox checkbox-success">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={"eLibrary" + props.id}
                        name={"eLibrary" + props.id}
                        checked={getCheckedStatus()}
                        onChange={() =>
                          onLibraryHandler(
                            props.data.combo_subject_ids,
                            props.id
                          )
                        }
                        disabled={getDisabledStatus()}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"eLibrary" + props.id}
                      >
                        e-Library{" "}
                        {props.data.disabled_library == 1 ||
                          props.data.has_library == 1 ? (
                          <span className="small_text_left">
                            (Already purchased)
                          </span>
                        ) : null}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="subcribe-scholastic-price-details">
              {!optionChange ? (
                props.cartList.length > 0 ? (
                  <h4>
                    Rs.
                    {props.cartList.find(
                      (element) =>
                        element.subscription_id == props.id &&
                        element.only_elibrary == 0
                    )
                      ? props.cartList[
                        props.cartList.indexOf(
                          props.cartList.find(
                            (element) => element.subscription_id == props.id
                          )
                        )
                      ].cart_amount
                      : props.combinePrice}
                    /-
                  </h4> //cart_amount
                ) : props.isPurchased == 1 ? (
                  <h4>{`Purchased`}</h4>
                ) : (
                  <h4>Rs.{props.combinePrice}/-</h4>
                )
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                  variant="primary"
                />
              )}
              {props.courseAvailable == 1 ? (
                <>
                  {props.isPurchased == 1 ? null : (
                    <>
                      {!props.cartList.has_library == 0 ? (
                        !props.cartList.find(
                          (element) =>
                            element.subscription_id == props.id &&
                            element.exam_category_id == 1 &&
                            element.combo_subject_ids.every((group) =>
                              props.subject_id.find(
                                (o) => o.subject_id == group
                              )
                            )
                        )
                      ) : !props.cartList.find(
                        (element) =>
                          element.subscription_id == props.id &&
                          element.exam_category_id == 1 &&
                          element.only_elibrary == 0
                      ) ? (
                        props.combinePrice != 0 && chapterTest != "" ? (

                          // <Button onClick={() => props.checkProfile.isComplete == 0 && isProfileUpdated == 0 ? finalSubscriptionSubmit() : updatedProfileInfo()}>
                          <Button onClick={() => props.isComplete == 0 ? finalSubscriptionSubmit() : updatedProfileInfo()}>
                            Add to cart
                          </Button>


                        ) : props.combinePrice == 0 && chapterTest != "" ? (
                          <div>
                            <h6 className="text-white">
                              Select atleast 1 option
                            </h6>
                          </div>
                        ) : (
                          <div className="messageContainer">
                            <h6 className="text-white">
                              Select the Chapter You would like to test
                            </h6>
                          </div>
                        )
                      ) : (
                        <Button disabled>Add to cart</Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      You cannot add any items because the course validity is
                      expiring soon.
                    </Tooltip>
                  )}
                  placement="top"
                >
                  <Button className="disable_cart_btn">Add to cart</Button>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Modal className="fade bd-example-modal-sm subscribe-modal" show={modal}>
        <Modal.Header>
          <Modal.Title>Cart Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!testCartSelect && testCartSelect != undefined ? (
            <>
              <p>
                Details : Subject : {props.data.subject_name}{" "}
                {!!testCartSelect.length ? (
                  <>Test : [{testCartSelect.join(",")}] </>
                ) : null}{" "}
                {!!testCartSelect.length && case_studies == 1 ? "+" : null}{" "}
                {case_studies == 1 ? `Case studies` : null}
                {!!module && !!testCartSelect.length ? " + " : ""}
                {!!module ? `Module : 3` : null}
                {(!!mock && !!module) || (!!mock && !!testCartSelect.length)
                  ? " + "
                  : ""}
                {!!mock ? `Mock : 2` : null}
                {(!!mock && !!library) ||
                  (!!module && !!library) ||
                  (!!testCartSelect.length && !!library)
                  ? " + "
                  : ""}
                {!!library ? `e-Library` : null}
              </p>{" "}
              {/* {!!library ? `e-Library : ${library}` : null} */}
            </>
          ) : null}
          {/* <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="subscribe-modal-footer">
            <Button className="cancelBtn" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button className="proceedBtn" onClick={onProgress}>
              Proceed
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AddProfileDetailsCard
        show={isDetails}
        onProfileSubmit={(data) => onProfileHandler(data)}
        profileData={props.checkProfile}
        closeHandler={() => setIsDetails(false)}
      />
      <div>{props.showLoading ? <Loader /> : null}</div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {

  return {
    showLoading: state.subscribe.showLoading,
    isNav: state.subscribe.isNav,
    cartList: state.subscribe.cartList,
  };
};
export default connect(mapStateToProps)(SubcribeScholasticCard);
