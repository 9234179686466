import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import CategorySetCard from '../components/exam/components/CategorySetCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getNtseExamTypeData } from '../../store/actions/ExamTypeAction';
import { getOnlineCompetitiveSubscriptionDetailsData, getOnlineCompetitiveSubscriptionDetailsMatData } from '../../store/actions/OnlineExamAction';

const CompetitiveNtseTypeLibrary = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getNtseExamTypeData(2, 1, props.history));
		dispatch(getOnlineCompetitiveSubscriptionDetailsData(1, 'NTSE', props.history)) // SAT
		dispatch(getOnlineCompetitiveSubscriptionDetailsMatData(2, 'NTSE', props.history)) // MAT

	}, []);

	const selectType = (item) => {
		// history.push({ pathname: '/competitive-exam-Select', state: { id: item.id, exam_type: previousValue.exam_type } })
		history.push({ pathname: '/competitive-exam-Select', state: { id: item.id, exam_type: location.state.exam_type } })
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Competitive Exams <i className="bi bi-chevron-right"></i> Competitive Types {location.state != undefined && location.state.id == 1 ? "(NTSE)" : null} </div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={() => history.goBack()}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
			</div>
			{/* <div className="row"> */}
			<div className="card_row card_wrap " > {/* flex-wrap */}
				
				{props.competitiveSubscriptionDetails != '' && props.competitiveSubscriptionDetails != undefined && props.competitiveSubscriptionDetailsMat != '' && props.competitiveSubscriptionDetailsMat != undefined && props.ntseType != '' && props.ntseType != undefined ?

					props.ntseType.map((item, index) => (
						<React.Fragment key={index}>

							<CategorySetCard
								// icon={item.id == 1 ? ntse : ntse}
								icon={location.state.image}
								title={item.subtype_name}
								description={item.sub_heading}
								bgColor={item.id == 1 ? '#02879B' : '#02879B'}
								leftColor={'#02879B'}
								iconClass={'new_icon_left_image'}
								iconClassRgt={'new_icon_right_image'}
								satCount={props.competitiveSubscriptionDetails[0]}
								matCount={props.competitiveSubscriptionDetailsMat[0]}
								id={index}
							/>
						</React.Fragment>
					))
					:
					<div className="exam_list_container" >
						<div className="spinner-container">
							<div className="spinner-view">
								<Spinner
									as="span"
									animation="border"
									//size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
				}
			</div>
			{/* </div> */}

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		competitiveSubscriptionDetails: state.onlineexam.competitiveSubscriptionDetails,
		competitiveSubscriptionDetailsMat: state.onlineexam.competitiveSubscriptionDetailsMat,
		ntseType: state.examtype.ntseType
	};
};

export default connect(mapStateToProps)(CompetitiveNtseTypeLibrary);