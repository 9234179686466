import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"

import {
	Row,
	Col,
} from "react-bootstrap";
import PerformanceLoader from '../../../jsx/components/PerformanceLoader'

//Import Components
import ArchiveChartCardC from '../../pages/PerformanceScoreNewUI/performanceComponent/ArchiveChartCardC';
import ChartCard18 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard18';
import ChartCard2 from '../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard2';
import ArchiveChartCard19 from '../../pages/PerformanceScoreNewUI/performanceComponent/ArchiveChartCard19';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_2background.png'

import {performanceRequestAction, getCompareScholasticCompetitiveDataDataDetails, getCompetitiveSetwiseScoreDataDetails, getcompetitiveSubjectAvgscoreDataDetails, getCompareScholasticCompetitiveDataAction } from '../../../store/actions/ArchivePerformanceScoreAction';

const ArchiveCompetitiveScore = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [previousValue, setPrevousValue] = useState(location.state)
	const [typeName, setTypeName] = useState('')
	const [pageName, setpageName] = useState('')


	useEffect(() => {
		// console.log('location...',location.state.class_no)
		const pathName = window.location.pathname.split('/');
		const id_path = pathName[2];
		const com_type = id_path.split('-');
		const name_com_type = com_type[1];

		setTypeName(name_com_type);

		dispatch(performanceRequestAction(true))

		dispatch(getCompareScholasticCompetitiveDataAction([]));
		dispatch(getCompareScholasticCompetitiveDataDataDetails(location.state.exam_type, 0,location.state.class_no, props.history))

		dispatch(getCompetitiveSetwiseScoreDataDetails(location.state.exam_type,location.state.class_no, props.history))

		dispatch(getcompetitiveSubjectAvgscoreDataDetails(location.state.exam_type, 0,location.state.class_no, props.history))

		if (location.state.exam_type == 'undefined' || location.state.exam_type == undefined) {
			history.push({ pathname: "/PerformanceCategoryPage" })
		}

		setpageName(com_type.join(" "))

		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
		};
	}, []);

	const onShowDetailsHandler =(data)=>{
		const typeName = previousValue.exam_type.toUpperCase()
        history.push({ pathname: `/archive-subjectwise-competitive-score/archive-${typeName}-subjectwise-competitive-score`, state: { set_no: data.slice(3), exam_type: previousValue.exam_type,class_no:location.state.class_no,page_name:pageName } })
	}


	const goPrevious = () => {
		history.goBack()
	}


	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Archive Performance Score <i className="bi bi-chevron-right"></i> Archive Competitive Performance Score <i className="bi bi-chevron-right"></i> {pageName} </div>
				</div>
			</div>


			<div className="d-flex flex-row justify-content-between mb-auto p-2 align-items-center">
				<div className="col-md-1">
					<div className="top_back_container_overall" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>
				<div className='d-flex flex-row align-items-center'>
					<div className="overall_right_heading mr-auto p-3">
						{location.state && location.state.exam_type}
					</div>
					{location?.state?.image != null ?
						<img
							// src={location.state.subject_image}
							src={location?.state?.image}
							alt="creastest"
							// color={props.leftColor}
							className="overall_sub_image_competitive"
						/>
						:
						<div className="no_image_container text-center">No Image
						</div>
					}
				</div>
			</div>
			<Row>
				<Col xl={6}>
					<ArchiveChartCardC
						pageHeading="Score Spectrum"
						exam_type={previousValue && previousValue.exam_type}
						typeName={typeName}
						classNo={location.state.class_no}
					/>
				</Col>

				<Col xl={6}>
					{props.getcompetitiveSubjectAvgscore != '' && props.getcompetitiveSubjectAvgscore != undefined ?
						<>
							<ChartCard18
								pageHeading="Performance Trend"
								dataSet={props.getcompetitiveSubjectAvgscore}
								typeName={typeName}
							/>
						</>
						:
						<PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.compareScholasticCompetitiveLabels != '' && props.compareScholasticCompetitiveDatasets != '' && !!props.compareScholasticCompetitiveDatasets ?
						<ChartCard2
							pageHeading="Scholastic/Competitive Index (%) "
							label={props.compareScholasticCompetitiveLabels}
							dataSets={props.compareScholasticCompetitiveDatasets}
						/>
						:
						<PerformanceLoader />
					}
				</Col>

				<Col xl={6}>
					{props.compititiveSetwiseScore != '' && props.compititiveSetwiseScore != undefined ?
						<ArchiveChartCard19
							pageHeading="Performance Analysis"
							dataSet={props.compititiveSetwiseScore}
							exam_type={previousValue && previousValue.exam_type}
							typeName={typeName}
							classNo={location.state.class_no}
							showDetailsHandler={(data)=>onShowDetailsHandler(data)}
						/>
						: <PerformanceLoader />
					}
				</Col>

				{/* <Link to="/subjectwise-competitive-score">Next Page</Link> */}
			</Row>

		</>
	)
}

const mapStateToProps = (state) => {
	return {
		compareScholasticCompetitiveDatasets: state.archivePerformance.compareScholasticCompetitiveDatasets,
		compareScholasticCompetitiveLabels: state.archivePerformance.compareScholasticCompetitiveLabels,
		compititiveSetwiseScore: state.archivePerformance.compititiveSetwiseScore,
		loading: state.archivePerformance.loading,
		getcompetitiveSubjectAvgscore: state.archivePerformance.getcompetitiveSubjectAvgscore,
	};
};
export default connect(mapStateToProps)(ArchiveCompetitiveScore);