import React, { Fragment, useState, useEffect } from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch, useSelector } from 'react-redux';

import AddProfileDetailsCard from "../subcriptionCard/AddProfileDetailsCard";

import {
	Row,
	Col,
	Modal,
	Button,
	Tooltip
} from "react-bootstrap";

import { addToCartData, subscribeLoading, showRightNavAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';

import {
	updateStudentProfileOfSubscription,
} from "../../../store/actions/ProfileAction";

const LibraryCompetetiveCard = (props) => {

	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [isDetails, setIsDetails] = useState(false);
	const [isProfileUpdated, setIsProfileUpdated] = useState('');

	const competitiveSubscriptionSourceDataColl = useSelector(state => state.subscribe.competitiveSubscriptionSourceDataColl);

	/* useEffect(() => {
		setIsProfileUpdated(props?.checkProfile?.isComplete)
	  }, [props?.checkProfile?.isComplete]); */

	const onProgress = () => {
		dispatch(compititiveSubscriptionSourceAction([]))
		dispatch(subscribeLoading(true));
		dispatch(addToCartData(props.category_id, props.id, 0, 0, 0, props.type, props.class_no ? props.class_no : 0, props.library, 0, 1, 1, props.history));
		dispatch(showRightNavAction(true))

		setModal(false);
	}

	const onProfileHandler = (data) => {
		dispatch(
			updateStudentProfileOfSubscription(
				data.pincode,
				data.schoolname,
				data.schooladd,
				updataedStudentProfileDetails,
				props.history
			))
	}

	const updataedStudentProfileDetails = (data) => {
		if (data != '') {
			setIsDetails(false);
			setIsProfileUpdated(0)
			props.isCompleteStatusUpdate()
			finalSubscriptionSubmit();
		}
	}
	const finalSubscriptionSubmit = () => {
		setModal(true);
		// let totalValue = props.combinePrice;
		// setFinalTotal(totalValue);
	};

	const updatedProfileInfo = () => {
		setIsDetails(true);
		return;
	}

	return (
		<Fragment>
			<Row>

				<Col xl={12}>
					<div className={`subcribe-competitive-card ${props.isPurchased == 1 ? "subscription-disable" : null}`}>
						{/* <div className="question-mark-circle">
							<i className="fa-sharp fa-solid fa-question"></i>
						</div> */}
						{/* sticker_text: "2%" */}
						{props.sticker_text != '' ?
							<div className='sticker_base'>
								<div className='sticker_text'>{props.sticker_text}</div>
							</div>
							: null}
						<div className="subcribe-competitive-details">
							<h4>e-Library</h4>
							<h4>{props.typeName}</h4>
							{/* <h6>(p.id:-{props.id})(eti:-{props.exam_type_id}={competitiveSubscriptionSourceDataColl.exam_type})</h6> */}
						</div>

						<div className="subcribe-competitive-price-details">
							<h4>Rs.{props.library}/-</h4>
							<div className="displayNone">{props.exam_type_id}</div>
							{props.courseAvailable == 1 ?
								<>
									{props.isPurchased == 1 ?
										null :
										<>
											{!props.cartList.find(element => element.subscription_id === props.id && element.exam_category_id == 2 && element.exam_type_id == props.exam_type_id && element.has_library == 1 && element.exam_type_id == props.type) && !props.cartList.find(element => element.subscription_id != props.id && element.exam_category_id == 2 && element.exam_type_id == props.exam_type_id && element.has_library == 1 && element.exam_type_id == props.type) && competitiveSubscriptionSourceDataColl.exam_type != props.type   /* && !props.cartList == '' && !props.is_select_elibrary_from_subscription */ ?
												<Button
													onClick={() => props.isComplete == 0 ? finalSubscriptionSubmit() : updatedProfileInfo()} /* setModal(true) */
												>Add to Cart</Button>
												: <Button disabled>Add to Cart</Button>
											}
										</>
									}
								</>
								: <OverlayTrigger
									delay={{ hide: 450, show: 300 }}
									overlay={(props) => (
										<Tooltip {...props}>You cannot add any items because the course validity is expiring soon.</Tooltip>
									)}
									placement="top"
								>
									<Button className='disable_cart_btn'>Add to cart</Button>
								</OverlayTrigger>}
						</div>

					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm subscribe-modal"
				//size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Cart Items</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!!props.typeName && props.typeName != undefined ?
						<p>Details : {props.typeName} (e-Library)</p>
						: null}
					{/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. */}
				</Modal.Body>
				<Modal.Footer>
					<div className="subscribe-modal-footer">
						<Button
							className="cancelBtn"
							onClick={() => setModal(false)}
						>
							Cancel
						</Button>
						<Button
							className="proceedBtn"
							onClick={onProgress}
						>Proceed</Button>
					</div>

				</Modal.Footer>
			</Modal>

			<AddProfileDetailsCard
				show={isDetails}
				onProfileSubmit={(data) => onProfileHandler(data)}
				profileData={props.checkProfile}
				closeHandler={() => setIsDetails(false)}
			/>

			
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.subscribe.showLoading,
		cartList: state.subscribe.cartList
	};
};
export default connect(mapStateToProps)(LibraryCompetetiveCard);