/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
// import { changeTab } from '../../store/actions/AuthActions';
import {
  elibraryGetLastsubjectListAction,
  elibraryListActivePageAction,
} from "../../../store/actions/LibraryAction";
import {
  assessmentActivePageAction,
  storeAssessmentFilterDataStoreAction,
} from "../../../store/actions/SubscribeAction";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const dispatch = useDispatch();

  const demoExamSubmit = useSelector(
    (state) => state.questionNo.demoExamDoneOrNot
  );
  const demoUserOrNot = useSelector((state) => state.auth.user_id);
  const regUserSubOrNot = useSelector((state) => state.auth.is_subscribe);
  const newStudentid = useSelector((state) => state.student.newStudentid);
  const is_subscribe_e_library = useSelector(
    (state) => state.auth.is_subscribe_e_library
  );
  const [pageName, setpageName] = useState("");

  let token;
  let getData = localStorage.getItem("userDetails");

  if (getData != null && getData != undefined && getData != "") {
    token = JSON.parse(getData).token;
  }

  const tabName = useSelector((state) => state.auth.tabName);
  const examid = useSelector((state) => state.onlineexam.examId);
  const timeUpWarning = useSelector((state) => state.questionNo.timeUpWarning);

  var d = new Date();
  // console.log("@@@11----demoExamSubmit:-", demoExamSubmit, "demoUserOrNot:-", demoUserOrNot, "regUserSubOrNot:-", regUserSubOrNot, "newStudentid:-", newStudentid, "is_subscribe_e_library:-", is_subscribe_e_library, "Sidebar--token--", token)
  /* console.log("Sidebar--@@@----demoExamSubmit:-", demoExamSubmit)
	console.log("Sidebar--@@@----demoUserOrNot:-", demoUserOrNot, )
	console.log("Sidebar--@@@----regUserSubOrNot:-", regUserSubOrNot)
	console.log("Sidebar--@@@----newStudentid:-", newStudentid)
	console.log("Sidebar--@@@----is_subscribe_e_library:-", is_subscribe_e_library, )
	console.log("Sidebar--@@@----Sidebar--token--", token) */

  // console.log("@@@@----examid", examid )
  // console.log("@@@@----timeUpWarning", timeUpWarning )
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    /* if (tabName === 'login') {
			dispatch(changeTab('login123'));
		} */
  }, []);

  useEffect(() => {
    const pathName = window.location.pathname.split("/");

    if (pathName.length > 2 && pathName != undefined) {
      const id_path = pathName[2];
      const com_type = id_path.split("-");
      if (com_type[0] !== "archive") {
        setpageName(com_type.join("-"));
      }
    }

    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    // console.log('pathName ',path);
    let currentElement = document.getElementById("performance_score");
    if (currentElement) {
      let anchorArrow = currentElement.querySelector("a");
      anchorArrow.setAttribute("aria-expanded", "false");
      let subMenu = currentElement.querySelector("ul");
      currentElement.classList.remove("mm-active");
      if (subMenu) {
        subMenu.classList.remove("mm-show");
      }
      // console.log("path....", path);
      //console.log('performancescore....',performancescore)
      if (performancescore.includes(path)) {
        currentElement.classList.add("mm-active");
        if (subMenu) {
          subMenu.classList.add("mm-show");
        }
        // console.log('page under performance')
      } else {
        // console.log('else page')
      }
    }
  });

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const listBlack = () => {
    dispatch(elibraryGetLastsubjectListAction(0));
    dispatch(elibraryListActivePageAction(1));
  };

  const assessmentPageNumberResent = () => {
    dispatch(assessmentActivePageAction(1));
    dispatch(storeAssessmentFilterDataStoreAction([]));
  };

  /// Active menu //demo-e-library
  let dashBoard = ["dashboard"],
    onlineexams = [
      "demo-exams",
      "scholastic-exams",
      "competitive-exams",
      "competitive-types",
      "assessment-list",
      "online-assessment-details",
    ],
    elibrary = [
      "demo-e-library-pdf",
      "e-library-competitive",
      "e-library-scholastic",
      "e-library-category",
      "e-library-category-type",
      "e-library-subject",
    ],
    onlineclasses = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    subscribe = ["subscription"],
    // performancescore = [
    performancescore = [
      "performance-category-select",
      "performance-score",
      "online-demo-exams",
      "online-scholastic-exams",
      "online-competitive-exams",
      "overall-scholastic-performance-score",
      "subjectwise-scholastic-score",
      "e-library-performance-score",
      // "subjectwise-analysis-on-module",
      "subjectwise-analysis-mol",
      // "subjectwise-analysis-on-chapter-test",
      "subjectwise-analysis-ctl",
      // "chapterwise-analysis-on-chapter-test",
      "chapterwise-analysis-ctl",
      // "chapterwise-analysis-on-module",
      "chapterwise-analysis-mol",
      // "subjectwise-analysis-on-mock",
      "subjectwise-analysis-mcl",
      // "chapterwise-analysis-on-mock",
      "chapterwise-analysis-mcl",
      "e-library-scholastic-performance-score",
      "e-library-competitive-performance-score",
      "online-exam-performance-score",
      "scholastic-performance-score",
      "competitive-performance-score",
      "e-library-competitive-type-list",
      `${pageName}`,
    ];
  // Online Class = [
  let classonline = ["online-class"];
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/dashboard" className="ai-icon">
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          {/* ------------------------------Online Exams---------------------------- */}
          <li className={`${onlineexams.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="bi bi-book"></i>
              <span className="nav-text">Online Exams</span>
            </Link>
            <ul>
              <li
                className={`${onlineclasses.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow" to="#">
                  <span className="nav-text-new">Demo Exams</span>
                </Link>
                <ul className="insideMenu">
                  {newStudentid == null && demoUserOrNot == 0 ? (
                    <>
                      <li>
                        <Link
                          className={`${
                            path === "demo-exams" ? "mm-active" : ""
                          }`}
                          to="/demo-exams"
                        >
                          Guest User
                        </Link>
                      </li>
                      <li className="disable-link unselect_text">
                        Registered User
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="disable-link unselect_text">Guest User</li>
                      <li>
                        <Link
                          className={`${
                            path === "demo-exams" ? "mm-active" : ""
                          }`}
                          to="/demo-exams"
                        >
                          Registered User
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              {demoUserOrNot == 0 ? (
                <>
                  <li className="disable-link unselect_text">
                    Scholastic Exams
                  </li>
                </>
              ) : (demoUserOrNot != 0 && regUserSubOrNot == 1) ||
                regUserSubOrNot == 3 ? (
                <>
                  <li>
                    <Link
                      className={`${
                        path === "scholastic-exams" ? "mm-active" : ""
                      }`}
                      to="/scholastic-exams"
                    >
                      Scholastic Exams
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="disable-link unselect_text">
                    Scholastic Exams
                  </li>
                </>
              )}

              {demoUserOrNot == 0 ? (
                <>
                  <li className="disable-link unselect_text">
                    Competitive Exams
                  </li>
                </>
              ) : (demoUserOrNot != 0 && regUserSubOrNot == 2) ||
                regUserSubOrNot == 3 ? (
                <>
                  <li>
                    <Link
                      className={`${
                        path === "competitive-exams" ? "mm-active" : ""
                      }`}
                      to="/competitive-exams"
                    >
                      Competitive Exams
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="disable-link unselect_text">
                    Competitive Exams
                  </li>
                </>
              )}
              {demoExamSubmit == 1 ? (
                <li>
                  <Link
                    className={`${path === "demo-exams" ? "mm-active" : ""}`}
                    to="/demo-assessment-list"
                  >
                    Demo Assessment
                  </Link>
                </li>
              ) : (
                <li className="disable-link unselect_text">Demo Assessment</li>
              )}
              {(demoUserOrNot != 0 && regUserSubOrNot == 1) ||
              regUserSubOrNot == 2 ||
              regUserSubOrNot == 3 ? (
                <>
                  {/* <li><Link className={`${path === "demo-exams" ? "mm-active" : ""}`} to="/online-assessment-category">Online Assessment</Link></li> */}
                  <li>
                    <Link
                      className={`${path === "demo-exams" ? "mm-active" : ""}`}
                      to="/assessment-list"
                      onClick={assessmentPageNumberResent}
                    >
                      Assessment List
                    </Link>
                  </li>
                </>
              ) : (
                <li className="disable-link unselect_text">Assessment List</li>
              )}
            </ul>
          </li>

          {/* ------------------------------e-Library---------------------------- */}

          <li className={`${elibrary.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              {" "}
              <i className="bi bi-people"></i>{" "}
              <span className="nav-text">e-Library</span>
            </Link>
            <ul>
              <li
                className={`${onlineclasses.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="has-arrow" to="#">
                  <span className="nav-text-new">Demo</span>
                </Link>
                <ul className="insideMenu">
                  {newStudentid == null && demoUserOrNot == 0 ? (
                    <>
                      <li>
                        <Link
                          className={`${
                            path === "e-library" ? "mm-active" : ""
                          }`}
                          to="/e-library"
                        >
                          Guest User
                        </Link>
                      </li>
                      <li className="disable-link unselect_text">
                        Registered User
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="disable-link unselect_text">Guest User</li>
                      <li>
                        <Link
                          className={`${
                            path === "e-library" ? "mm-active" : ""
                          }`}
                          to="/e-library"
                        >
                          Registered User
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              {/* {is_subscribe_e_library == 0 ?
								<>
									<li className="disable-link unselect_text">Scholastic</li>
								</>
								: is_subscribe_e_library == 1 || is_subscribe_e_library == 3 ?
									<>
										<li><Link className={`${path === "e-library-competitive" ? "mm-active" : ""}`} to={{ pathname: "/e-library-category", state: { categoryId: 1 } }}>Scholastic</Link></li>
									</>
									: <>
										<li className="disable-link unselect_text">Scholastic</li>
									</>
							} */}
              {is_subscribe_e_library == 0 ? (
                <>
                  <li className="disable-link unselect_text">
                    Scholastic List
                  </li>
                </>
              ) : is_subscribe_e_library == 1 || is_subscribe_e_library == 3 ? (
                <>
                  <li>
                    <Link
                      className={`${
                        path === "e-library-competitive" ? "mm-active" : ""
                      }`}
                      to={{
                        pathname: "/e-library-scholastic-list",
                        state: { categoryId: 1 },
                      }}
                      onClick={listBlack}
                    >
                      Scholastic List
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="disable-link unselect_text">
                    Scholastic List
                  </li>
                </>
              )}
              {/* <li><Link className={`${path === "e-library-scholastic" ? "mm-active" : ""}`} to={{ pathname: "/e-library-competitive-list", state: {} }} >Competitive</Link></li> */}
              {is_subscribe_e_library == 0 ? (
                <>
                  <li className="disable-link unselect_text">
                    Competitive List
                  </li>
                </>
              ) : is_subscribe_e_library == 2 || is_subscribe_e_library == 3 ? (
                <>
                  {/* <li><Link className={`${path === "e-library-scholastic" ? "mm-active" : ""}`} to={{ pathname: "/e-library-category-type", state: {} }} >Competitive List</Link></li> */}
                  {/* <li><Link className={`${path === "e-library-scholastic" ? "mm-active" : ""}`} to={{ pathname: "/e-library-competitive-list", state: {} }} >Competitive List</Link></li> */}
                  <li>
                    <Link
                      className={`${
                        path === "e-library-scholastic" ? "mm-active" : ""
                      }`}
                      to={{
                        pathname: "/e-library-competitive-segments",
                        state: {},
                      }}
                      onClick={listBlack}
                    >
                      Competitive List
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="disable-link unselect_text">
                    Competitive List
                  </li>
                </>
              )}
              {/* <li><Link className={`${path === "e-library-scholastic" ? "mm-active" : ""}`} to="#" >Integrated List</Link></li> */}
            </ul>
          </li>

          {demoUserOrNot == 0 ? (
            <li className="disable-link-header unselect_text">
              <div>
                <i className="bi bi-info-circle"></i>
              </div>
              <div className="nav-text">Online Classes</div>
              {/* </Link> */}
            </li>
          ) : (demoUserOrNot != 0 && regUserSubOrNot == 1) || 2 || 3 ? (
            <li className={`${classonline.includes(path) ? "mm-active" : ""}`}>
              {/* <Link className="has-arrow ai-icon" to="#" > */}
              <Link className="ai-icon" to="/online-class">
                <i className="bi bi-info-circle"></i>
                <span className="nav-text">Online Classes</span>
              </Link>
              {/* <ul>
									<li><Link className={`${path === "app-profile" ? "mm-active" : ""}`} to="/app-profile">Profile</Link></li>
					<li><Link className={`${path === "post-details" ? "mm-active" : ""}`} to="/post-details">Post Details</Link></li>
					<li><Link className={`${path === "app-calender" ? "mm-active" : ""}`}to="/app-calender">Calendar</Link></li> 
								</ul>*/}
            </li>
          ) : (
            <li className="disable-link-header unselect_text">
              <div>
                <i className="bi bi-info-circle"></i>
              </div>
              <div className="nav-text">Online Classes</div>
              {/* </Link> */}
            </li>
          )}

          {(demoUserOrNot == 0 && newStudentid == null) || newStudentid == 0 ? (
            <li className="disable-link-header unselect_text">
              <div>
                <i className="bi bi-bell"></i>
              </div>
              <div className="nav-text">Subscription</div>
            </li>
          ) : (
            <li className={`${subscribe.includes(path) ? "mm-active" : ""}`}>
              <Link to="/subscription" className="ai-icon">
                <i className="bi bi-bell"></i>
                <span className="nav-text">Subscription</span>
              </Link>
              {/* <ul>
								<>
									<li><Link className={`${path === "subscribe" ? "mm-active" : ""}`} to="/subscribe">Subscribe</Link></li> */}
              {/* <li><Link className={`${path === "subscribe" ? "mm-active" : ""}`} to="/subscribe-list">Subscribe List</Link></li> */}

              {/* </>
							</ul> */}
            </li>
          )}

          {/* <li className={`${subscribe.includes(path) ? "mm-active" : ""}`}>
							<Link to="/subscribe" className="ai-icon" >
								<i className="bi bi-bell"></i>
								<span className="nav-text">Subscribe</span>
							</Link>
						</li> */}

          {/* {demoUserOrNot == 0 ?
						<li className="disable-link-header unselect_text">
							<div>
								<i className="bi bi-pie-chart"></i>
							</div>
							<div className="nav-text">
								Performance Score
							</div>
						</li>
						: demoUserOrNot != 0 && regUserSubOrNot == 1 || 2 || 3 ?
							<li className={`${performancescore.includes(path) ? "mm-active" : ""}`}>
								<Link to="online-performance-category" className="ai-icon" >
									<i className="bi bi-pie-chart"></i>
									<span className="nav-text">Performance Score</span>
								</Link>
							</li>
							:
							<li className="disable-link-header unselect_text">
								<div>
									<i className="bi bi-pie-chart"></i>
								</div>
								<div className="nav-text">
									Performance Score
								</div>
							</li>
					} */}
          {/* <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
						<Link to="/dashboard" className="ai-icon" >
							<i className="bi bi-grid"></i>
							<span className="nav-text">Dashboard</span>
						</Link>
					</li> */}

          {demoUserOrNot == 0 ? (
            <li className="disable-link-header unselect_text">
              <div>
                <i className="bi bi-pie-chart"></i>
              </div>
              <div className="nav-text">Performance Score</div>
            </li>
          ) : (demoUserOrNot != 0 && regUserSubOrNot == 1) || 2 || 3 ? (
            <li
              id="performance_score"
              className={`${
                performancescore.includes(path) ? "mm-active" : ""
              }`}
            >
              {/* <Link to="/performance-score" className="ai-icon" > */}
              <Link to="#" className="has-arrow">
                <i className="bi bi-pie-chart"></i>
                <span className="nav-text">Performance Score</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "online-exam-performance-score"
                        ? "mm-active"
                        : ""
                    }`}
                    to="/online-exam-performance-score"
                  >
                    Online Exam
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "e-library-performance-score" ? "mm-active" : ""
                    }`}
                    to="/e-library-performance-score"
                  >
                    e-Library
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className="disable-link-header unselect_text">
              <div>
                <i className="bi bi-pie-chart"></i>
              </div>
              <div className="nav-text">Performance Score</div>
            </li>
          )}
        </MM>

        <div className="copyright mt-5">
          <p className="fs-12">
            Crestest learning Ventures LLP ©{" "}
            {
              <Moment format="YYYY" withTitle>
                {d}
              </Moment>
            }{" "}
            All rights reserved
          </p>
          {/* <p className="fs-12">Crestest learning Ventures LLP © 2022 All Rights Reserved</p> */}
          {/* <p className="fs-10">Developed by Schemaphic Systems</p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
