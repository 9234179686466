import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as utility from "../../../utility/Utility";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CreatableSelect from "react-select/creatable";
import {
  getBoardData,
  getSchoolListData,
  loadingBoardAction,
} from "../../../store/actions/BoardActions";
import {
  updateProfileDetails,
  loadingProfile,
  profileDataUpdatedAction,
  getProfileDetailsById,
  getOTPByClassUpdateData,
  updateStudentClassData,
} from "../../../store/actions/ProfileAction";
import {
  getClassStandardData,
  getClassStandardByTokenList,
  loadingClassStandardAction,
} from "../../../store/actions/ClassStandardAction";
import {
  showingLoaderStudentAction,
  demoUserRecordExistsOrNot,
  sendVerificationOtp,
  verificationCodeAction,
  recordExistsSuccess,
} from "../../../store/actions/StudentAction";

import profile from "../../../images/profile/profile.png";
import ProfileImgCrop from "./ProfileImgCrop";

const Profile = (props) => {
  const calendarRef = useRef();
  const mobileOtpRef = useRef();
  const mobileClassOtpRef = useRef();
  const emailOtpRef = useRef();

  const status = useSelector((state) => state.student.status);
  const profileImg = useSelector((state) => state.profile.profileImg);
  const profileImageFile = useSelector(
    (state) => state.profile.profileImageFile
  );
  const profile_image = useSelector((state) => state.profile.profilePic);

  let errorsObj = {
    fname: "",
    lname: "",
    dob: "",
    email: "",
    gender: "",
    address: "",
    pincode: "",
    mobile: "",
    standard: "",
    board: "",
    schoolName: "",
    schoolAddress: "",
    pws: "",
    cpws: "",
    clsOTP: "",
    emlOTP: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [proImg, setProImg] = useState("");
  const [localImg, setLocalImg] = useState("");
  const [proImgFile, setProImgFile] = useState(null);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [mobile, setMobile] = useState("");
  const [standard, setStandard] = useState("");
  const [preStandard, setPreStandard] = useState("");
  const [boardName, setBoardName] = useState("");
  const [board, setBoard] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [defaultOption, setDefaultOption] = useState(null);
  const [schoolAddress, setSchoolAddress] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [pws, setPws] = useState("");
  const [cpws, setCpws] = useState("");
  const [date, setDate] = useState(null);
  const [previousMobile, setPreviousMobile] = useState("");
  const [otpVerificationModal, setOtpVerificationModal] = useState(false);
  const [otpClassModal, setOtpClassModal] = useState(false);
  const [clsOTP, setClsOTP] = useState("");
  const [emlOTP, setEmlOTP] = useState("");
  const [clsOTPdetails, setClsOTPdetails] = useState(null);
  const [clsVerifyButtonShow, setClsVerifyButtonShow] = useState(true);
  const [showClsResendOtp, setShowClsResendOtp] = useState(false);
  const [verifyButtonShow, setVerifyButtonShow] = useState(true);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [profileUpdatedWithMobile, setProfileUpdatedWithMobile] = useState("");

  const [userDetails, setUserDetails] = useState({
    motp: "",
  });
  const [cropImageModal, setCropImageModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [schoolNameList, setSchoolNameList] = useState([]);

  const [viewPassword, setViewPassword] = useState("password");
  const [infoBox, setInfoBox] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [manualSchoolName, setManualSchoolName] = useState("");
  const [ismanualSchoolName, setIsManualSchoolName] = useState(0); /* 0= name collect from list, 1= manual type */


  const dispatch = useDispatch();

  useEffect(() => {
    setProfileUpdatedWithMobile("");
    dispatch(getProfileDetailsById(props.history));
    dispatch(loadingBoardAction(true));
    dispatch(getBoardData(props.history));
    //dispatch(getClassStandardData(props.history));
    dispatch(getClassStandardByTokenList(props.history));
    dispatch(getSchoolListData(1, props.history));
  }, []);

  useEffect(() => {
    if (!props.studentDetails) return;
    const {
      profile_pic = "",
      fname = "",
      lname = "",
      dob = null,
      email = "",
      gender = "",
      address = "",
      pincode = "",
      mobile = "",
      standard = "",
      board = "",
      board_name = "",
      school_name = "",
      school_address = "",
      academic_year = "",
    } = props.studentDetails;

    if (Object.keys(props.studentDetails).length !== 0) {
      // let defaultSchoolName = { value: school_name, label: school_name };
      let defaultSchoolName = { value: "orange", label: "Orange" };
      
      setProImg(profile_pic);
      setFname(fname);
      setLname(lname);
      setDate(dob ? new Date(dob) : null);
      setEmail(email);
      setGender(gender?.toLowerCase());
      setAddress((address == null || address == 'null') ? '' : address );
      setPincode(pincode == 0 ? '' : pincode);
      setMobile(mobile);
      setStandard(standard);
      setPreStandard(standard);
      setBoard(board);
      setBoardName(board_name);
      setSchoolName((school_name == null || school_name == 'null') ? '' : school_name );
      // setDefaultOption(defaultSchoolName);
      setSchoolAddress((school_address == null || school_address == 'null') ? '' : school_address);
      setPreviousMobile(mobile);
      setAcademicYear(academic_year);
      setDefaultOption({ label: (school_name == null || school_name == 'null') ? '' : school_name, value: (school_name == null || school_name == 'null') ? '' : school_name })
      // setDefaultOption(props?.studentDetails?.is_manual_school_name == 0 ? { label: school_name, value: school_name } : {label : "Create new", value : "Create new"})
      // setIsManualSchoolName(props?.studentDetails?.is_manual_school_name)
      setManualSchoolName((school_name == null || school_name == 'null') ? '' : school_name)

    }
  }, [props.studentDetails]);

  useEffect(() => {
    if (schoolName != "Create new") {
      let defaultSchoolName = { value: schoolName, label: schoolName };
      setDefaultOption(defaultSchoolName);
    } else {
      setIsManualSchoolName(1)
      /* if(props.studentDetails.school_name != '' && props.studentDetails.school_name != undefined && props.studentDetails.school_name != "Create new")
        {
          setManualSchoolName(schoolName)
        }  */
      // let CreateNewSchoolName = { value: "Create new", label: "Create new" };
      // setDefaultOption(CreateNewSchoolName);

    }

  }, [schoolName]);

  useEffect(() => {
    if (status == 0 && status != null) {
      setOtpVerificationModal(true);
      dispatch(showingLoaderStudentAction(true));
      dispatch(sendVerificationOtp(mobile, "", props.history));
    }
  }, [status]);

  useEffect(() => {
    if (props.schoolList.length > 0) {
      let selectSchoolNameBoardWise = [];
      let selectSchoolName = {};
      // let creact_new = "Create new";
      let creact_new = {}
      creact_new["value"] = "Create new";
      creact_new["label"] = "Create new";
      creact_new["id"] = 0;
      props.schoolList.forEach((elm) => {
        selectSchoolName = {};
        selectSchoolName["value"] = elm.school_name;
        selectSchoolName["label"] = elm.school_name;
        selectSchoolName["id"] = elm.id;
        selectSchoolNameBoardWise.push(selectSchoolName);
      });
      selectSchoolNameBoardWise.unshift(creact_new)
      setSchoolNameList(props.schoolList);
      setOptions(selectSchoolNameBoardWise);
    }
  }, [props.schoolList]);

  useEffect(() => {
    if (profileUpdatedWithMobile == 1) {
      profileUpdate();
      setProfileUpdatedWithMobile(0);
    }
  }, [profileUpdatedWithMobile]);

  //   useEffect(() => {
  //     if (profileDataUpdated == 1) {
  //       //onGetDetails();
  //       //dispatch(profileDataUpdatedAction(0));
  //     }
  //     //
  //   }, [profileDataUpdated]);

  useEffect(() => {
    setLocalImg(profileImg);
    setProImgFile(profileImageFile[0]);
    setCropImageModal(profileImageFile[1]);
  }, [profileImg, profileImageFile, profile_image]);

  function onSubmit(e) {
    e.preventDefault();

    let enteredSchoolName = schoolName && schoolName.trim();
    setSchoolName(enteredSchoolName);

    let enteredManualSchoolName = manualSchoolName && manualSchoolName.trim();
    setManualSchoolName(enteredManualSchoolName)

    let error = false;
    const errorObj = { ...errorsObj };
    const onlyNumber = /^[0-9]+$/;
    const regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    if (fname === "") {
      errorObj.fname = "First name is required";
      error = true;
    } else if (fname && fname.length < 3) {
      errorObj.fname = "Minimum 3 Character is required";
      error = true;
    }

    if (lname === "") {
      errorObj.lname = "Last name is required";
      error = true;
    } else if (lname && lname.length < 3) {
      errorObj.lname = "Minimum 3 Character is required";
      error = true;
    }

    if (date === "" || date === undefined) {
      errorObj.dob = "DOB is required";
      error = true;
    }
    
    if (gender == undefined || gender == 'undefined' || gender == null || gender == "null" || gender === "") {
      errorObj.gender = "Gender is required";
      error = true;
    }

    if (mobile === "") {
      errorObj.mobile = "Mobile is required";
      error = true;
    } else if (mobile && mobile.length < 10) {
      errorObj.mobile = "Check Mobile No.";
      error = true;
    } else if (mobile == "0000000000") {
      errorObj.mobile = "Not a valid number";
      error = true;
    } /* else if (previousMobile != mobile) {
			setOtpVerificationModal(true)
		} */

    if (address === "") {
      errorObj.address = "Address is required";
      error = true;
    } else if (address && address.length < 3) {
      errorObj.address = "Minimum 3 Character is required";
      error = true;
    }
    
    if (pincode == null || pincode == "null" || pincode == undefined || pincode == 'undefined' || pincode === "") {
      errorObj.pincode = "Postal code is required";
      error = true;
    } else if (pincode && pincode.length < 6) {
      errorObj.pincode = "Minimum 6 Character is required";
      error = true;
    } else if (pincode == "000000") {
      errorObj.pincode = "Not a valid Postal code";
      error = true;
    } else if (!onlyNumber.test(pincode)) {
      errorObj.pincode = "Not a valid Postal code";
      error = true;
    }

    if (standard === "") {
      errorObj.standard = "Standard is required";
      error = true;
    }
    if (board === "") {
      errorObj.board = "Board is required";
      error = true;
    }
    /* if (pws != '' && !regularExpression.test(pws)) {
      errorObj.pws = 'Password not in pattern';
      error = true;
    } */

    /* ismanualSchoolName == 0 ? schoolName : manualSchoolName, */

    if (schoolName == '' && ismanualSchoolName == 0) {
      errorObj.schoolName = "School name is required"
      error = true;
    }
    if (manualSchoolName == '' && ismanualSchoolName == 1) {
      errorObj.schoolName = "School name is required"
      error = true;
    }

    if (schoolAddress == undefined || schoolAddress == 'undefined' || schoolAddress == null || schoolAddress == '') {
      errorObj.schoolAddress = "School address is required"
      error = true;
    }

    if (pws !== cpws) {
      errorObj.cpws = "Password and confirm password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    if (previousMobile != mobile && preStandard !== standard) {
      dispatch(
        getOTPByClassUpdateData(getClassVerificationDetail, props.history)
      );
    } else if (previousMobile != mobile) {
      dispatch(showingLoaderStudentAction(true));
      dispatch(demoUserRecordExistsOrNot(mobile, "", checkUserRecordCallBack, props.history));
    } else if (preStandard !== standard) {
      dispatch(
        getOTPByClassUpdateData(getClassVerificationDetail, props.history)
      );
    } else {
      dispatch(loadingProfile(true));
      dispatch(
        updateProfileDetails(
          proImgFile == null ? "" : proImgFile,
          fname,
          lname,
          date ? moment(date).format("YYYY-MM-DD") : "",
          email,
          gender,
          address,
          pincode,
          previousMobile,
          preStandard == standard ? "" : standard,
          board,
          ismanualSchoolName == 0 ? schoolName : manualSchoolName,
          schoolAddress,
          cpws,
          props.history
        )
      );
    }
  }

  const checkUserRecordCallBack = (data) => {
    if (data == 0) {
      setOtpVerificationModal(true);
      dispatch(showingLoaderStudentAction(true));
      dispatch(sendVerificationOtp(mobile, "", props.history));
    }
  };

  const profileUpdate = () => {
    dispatch(loadingProfile(true));
    dispatch(
      updateProfileDetails(
        proImgFile == null ? "" : proImgFile,
        fname,
        lname,
        date ? moment(date).format("YYYY-MM-DD") : "",
        email,
        gender,
        address,
        pincode,
        profileUpdatedWithMobile == 1 ? mobile : previousMobile,
        preStandard == standard ? "" : standard,
        board,
        ismanualSchoolName == 0 ? schoolName : manualSchoolName,
        schoolAddress,
        cpws,
        props.history
      )
    );
  };

  const resendClassChangeOtp = () => {
    dispatch(showingLoaderStudentAction(true));
    dispatch(
      getOTPByClassUpdateData(getClassVerificationDetail, props.history)
    );
    setShowClsResendOtp(false);
    setClsVerifyButtonShow(true);
  };

  const getClassVerificationDetail = (data) => {
    // let mobile_otp = utility.decryptAES(data.mobile_otp);
    // let email_otp = utility.decryptAES(data.email_otp);
    //console.log(mobile_otp, "...OTP....", email_otp);
    setClsOTPdetails(data);
    setOtpClassModal(true);
  };

  const setdata = (e) => {
    const { name, value } = e.target;
    setUserDetails((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const onImageHandle = (event) => {
    setCropImageModal(true);
  };

  const closeOtpVerificationModal = () => {
    dispatch(recordExistsSuccess(null));
    setOtpVerificationModal(false);
    setUserDetails((userDetails) => ({
      ...userDetails,
      motp: "",
      eotp: "",
    }));

    setShowResendOtp(false);
    setVerifyButtonShow(true);
    setProfileUpdatedWithMobile(0);
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    const onlyNumber = /^[0-9]+$/;

    const { motp } = userDetails;
    let error = false;
    const errorObj = { ...errorsObj };

    if (motp === "") {
      mobileOtpRef.current.focus();
      errorObj.motp = "Mobile OTP is required";
      error = true;
    } else if (!onlyNumber.test(motp)) {
      mobileOtpRef.current.focus();
      errorObj.motp = "Allow only numeric";
      error = true;
    } else if (motp.length < 6) {
      mobileOtpRef.current.focus();
      errorObj.motp = "Verify your OTP";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      if (userDetails.motp == utility.decryptAES(props.mOtp)) {
        setOtpVerificationModal(false);
        setProfileUpdatedWithMobile(1);
        dispatch(recordExistsSuccess(null));
      } else {
        alert("Verify your OTP");
      }
    }
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      const removeOtp = {
        email_otp: "",
        mobile_otp: "",
      };
      setUserDetails((userDetails) => ({
        ...userDetails,
        motp: "",
        eotp: "",
      }));
      setShowResendOtp(true);
      setVerifyButtonShow(false);
      dispatch(verificationCodeAction(removeOtp));
    }

    return (
      <div className="timer">
        <div className="remainingTime">{remainingTime}</div>
      </div>
    );
  };

  const resendOtp = () => {
    dispatch(showingLoaderStudentAction(true));
    dispatch(sendVerificationOtp(mobile, "", props.history));
    setShowResendOtp(false);
    setVerifyButtonShow(true);
  };

  const showPassword = () => {
    if (viewPassword == "password") {
      setViewPassword("text");
    } else {
      setViewPassword("password");
    }
  };

  const handleMouseOver = () => {
    setInfoBox(true);
  };

  const handleMouseOut = () => {
    setInfoBox(false);
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    e.preventDefault();
  };

  const verifyClassChangeOtp = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    const onlyNumber = /^[0-9]+$/;

    if (clsOTP === "") {
      mobileClassOtpRef.current.focus();
      errorObj.clsOTP = "Mobile OTP is required";
      error = true;
    } else if (!onlyNumber.test(clsOTP)) {
      mobileClassOtpRef.current.focus();
      errorObj.clsOTP = "Allow only numeric";
      error = true;
    } else if (clsOTP.length < 6) {
      mobileClassOtpRef.current.focus();
      errorObj.clsOTP = "Verify your OTP";
      error = true;
    }

    if (emlOTP === "") {
      emailOtpRef.current.focus();
      errorObj.emlOTP = "Email OTP is required";
      error = true;
    } else if (!onlyNumber.test(emlOTP)) {
      emailOtpRef.current.focus();
      errorObj.emlOTP = "Allow only numeric";
      error = true;
    } else if (emlOTP.length < 6) {
      emailOtpRef.current.focus();
      errorObj.emlOTP = "Verify your OTP";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    if (
      clsOTP == utility.decryptAES(clsOTPdetails.mobile_otp) &&
      emlOTP == utility.decryptAES(clsOTPdetails.email_otp)
    ) {
      dispatch(
        updateStudentClassData(standard, updateStudentCallback, props.history)
      );
    } else {
      alert("Verify your OTP");
    }
  };

  const updateStudentCallback = () => {
    dispatch(getProfileDetailsById(props.history));
    setOtpClassModal(false);
    setClsOTP("");
    setClsOTP("");
    setClsOTPdetails(null);
    setClsVerifyButtonShow(true);
    setShowClsResendOtp(false);
    dispatch(
      updateProfileDetails(
        proImgFile == null ? "" : proImgFile,
        fname,
        lname,
        date ? moment(date).format("YYYY-MM-DD") : "",
        email,
        gender,
        address,
        pincode,
        previousMobile,
        preStandard == standard ? "" : standard,
        board,
        ismanualSchoolName == 0 ? schoolName : manualSchoolName,
        schoolAddress,
        cpws,
        props.history
      )
    );
  };

  const renderChangeClassTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setClsOTP("");
      setEmlOTP("");
      setClsOTPdetails(null);
      setShowClsResendOtp(true);
      setClsVerifyButtonShow(false);
    }

    return (
      <div className="timer">
        <div className="remainingTime">{remainingTime}</div>
      </div>
    );
  };

  const closeClassOtpVerificationModal = () => {
    setOtpClassModal(false);
    setClsOTP("");
    setEmlOTP("");
    setClsOTPdetails(null);
    setShowClsResendOtp(false);
    setClsVerifyButtonShow(true);
  };

  const getSelectedValue = (item) => {
    // console.log("item.........", item, );
    if (ismanualSchoolName === 0 && item.value != 'Create new') {

      if (item) {
        setSelectedOption(item.value)
        // const selectedValue = item.value;
        const selectedValue = item.value;
        setSchoolName(selectedValue);
        const selectSchoolAddress = schoolNameList.find(
          (i) => i.school_name === selectedValue && i.id === item.id
        );

        setSchoolAddress(
          selectSchoolAddress ? selectSchoolAddress.school_address : ""
        );
      } else {
        setSchoolName("");
        setSchoolAddress("");
        // setSelectedOption("")
      }
    } else {

      // setSchoolAddress("");
      setIsManualSchoolName(1)
    }
  };

  const getManualSchoolNameChange = (value) => {
    setManualSchoolName(value)

  };

  const disableClass = (item) => {
    let status = false;
    if (item.class_no < props.studentDetails?.standard) {
      status = true;
    }
    return status;
  };

  const showSchoolList = () => {
    setIsManualSchoolName(0)
  }

  return (
    <Fragment>
      {/* {console.log("=======@@111=====", `M: ${utility.decryptAES(props.mOtp)}`) } */}

      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="card card-body">
            <div className="profile-container">
              {/* <img src={proImg ? proImg : profile} alt="" className="rounded" /> */}
              <div className="profile-header-image">
                {localImg != "" && localImg != null ? (
                  <img src={localImg} alt="" className="rounded" />
                ) : (
                  <img
                    src={
                      profile_image != null && profile_image != ""
                        ? profile_image
                        : proImg != null && proImg != ""
                          ? proImg
                          : profile
                    }
                    alt=""
                    className="rounded"
                  />
                )}
                <div className="custom-file-input" onClick={onImageHandle}>
                  {/* <div className="custom-file-input"> */}
                  <label htmlFor="imgForm">
                    <i className="fa fa-camera m-0" />
                  </label>
                  {/* <input
										type="file"
										id="imgForm"
										multiple
										accept="image/*"
										onChange={onImageHandle}
										hidden
									/> */}
                </div>
              </div>

              <div className="profile-form-content">
                <form onSubmit={onSubmit}>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>First name</strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="First name"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                      // onKeyDown={utility.inputAlphabetOnly}
                      onKeyDown={utility.inputAlphabetOnlyWithSpace}
                    />
                    {errors.fname && (
                      <div className="text-danger fs-12">{errors.fname}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Last name</strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Last name"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                      onKeyDown={utility.inputAlphabetOnly}
                    />
                    {errors.lname && (
                      <div className="text-danger fs-12">{errors.lname}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Date of birth</strong>
                    </label>
                    <div className="date-control-view profile-view">
                      <DatePicker
                        ref={calendarRef}
                        className="form-control input_value_date disable_input"
                        name="dob"
                        selected={date}
                        // onChange={(date) => setDobFormat(date)}
                        // onChange={(date) => setUserdob(Date.parse(date))}
                        onChange={(date) => setDate(date)}
                        // minDate={new Date()}
                        maxDate={new Date()}
                        placeholderText="DOB"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        //readOnly
                        onChangeRaw={handleDateChangeRaw}
                        preventOpenOnFocus={true}
                      />
                      {/* <DatePicker
												ref={calendarRef}
												selected={date}
												onChange={(date) => setDate(date)}
											/> */}
                      {/* <button type='button' className="picker-btn"
												onClick={() => calendarRef.current.setOpen(true)}
											>
												<i className="fa-solid fa-calendar-days"></i>
											</button> */}
                    </div>
                    {errors.dob && (
                      <div className="text-danger fs-12">{errors.dob}</div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Email</strong>
                    </label>
                    {/* email-disable */}
                    <input
                      className="form-control disable_input"
                      placeholder="Email"
                      value={email}
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-danger fs-12">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Gender</strong>
                    </label>
                    <div className="profile-basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    {errors.gender && (
                      <div className="text-danger fs-12">{errors.gender}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Mobile</strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Mobile"
                      value={mobile}
                      // onChange={(e) => setMobile(e.target.value)}
                      onChange={(e) => setMobile(e.target.value)}
                      maxLength={10}
                      onKeyPress={utility.inputNumberOnly}
                    />
                    {errors.mobile && (
                      <div className="text-danger fs-12">{errors.mobile}</div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Address</strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    {errors.address && (
                      <div className="text-danger fs-12">{errors.address}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Postal code</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Postal code"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      maxLength={6}
                      onKeyPress={utility.inputNumberOnly}
                    />
                    {errors.pincode && (
                      <div className="text-danger fs-12">{errors.pincode}</div>
                    )}
                  </div>

                  <div className="form-group mb-3 subscription-disable">
                    <label className="mb-2">
                      <strong>Academic year</strong>
                    </label>
                    <input
                      type="text"
                      className="readable-academic form-control"
                      placeholder=""
                      value={academicYear}
                      onChange={(e) => setAcademicYear(e.target.value)}
                    // readOnly
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Standard</strong>
                    </label>
                    <div className="profile-basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      {/* <input
												className="form-control disable_input"
												placeholder="Select Standard"
												value={standard}
												onChange={(e) => setStandard(e.target.value)}
												disabled
											/> */}
                      <select
                        defaultValue={"option"}
                        className="form-control profile-form-control"
                        value={standard}
                        disabled={
                          props.studentDetails?.expired == 1 ? false : true
                        }
                        onChange={(e) => setStandard(e.target.value)}
                      >
                        <option value="">Select Standard</option>
                        {
                          props.classList.map((item, index) => {
                            return (
                              <option
                                //disabled={disableClass(item)}
                                key={index}
                                value={item.class_no}
                              >
                                {item.short_code}
                              </option>
                            );
                          })
                          // <option value={props.standard}>Class {props.standard}</option>
                        }
                      </select>
                    </div>
                    {errors.standard && (
                      <div className="text-danger fs-12">{errors.standard}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>Board</strong>
                    </label>
                    <div className="profile-basic-dropdown">
                      {/* <span className="drop_down_arrow"><i className="bi bi-caret-down-fill"></i></span> */}
                      <input
                        className="form-control disable_input"
                        placeholder="Select Board"
                        value={boardName}
                        onChange={(e) => setBoardName(e.target.value)}
                        disabled
                      />
                      {/* <select
												defaultValue={"option"}
												className="form-control disable_input"
												value={board}
												onChange={(e) => setBoard(e.target.value)}
												
											>
												<option value="">Select Board</option>
												{
													/* props.boardList.map((item, index) => {
														return <option key={index} value={item.id}>{item.name}</option>
													}) 
													<option value={props.board}>{props.board_name}</option>
												}
											</select> */}
                    </div>
                    {errors.board && (
                      <div className="text-danger fs-12">{errors.board}</div>
                    )}
                  </div>

                  <div className="form-group mb-3 eye_parent_container profile_page">
                    <label className="mb-2">
                      <strong>School name</strong>
                    </label>


                    
                    {/* {defaultOption?.label !== "Create new" ? */}
                    {ismanualSchoolName === 0 ?
                      <>
                        <CreatableSelect
                          value={defaultOption?.value == "" ? '' : defaultOption}
                          isClearable
                          onChange={getSelectedValue}
                          options={options}
                          defaultValue={defaultOption}
                          placeholder='Select School'
                          isValidNewOption={() => false}
                        />
                        {errors.schoolName && (
                          <div className="text-danger fs-12">
                            {errors.schoolName}
                          </div>
                        )}
                      </>
                      :
                      <>
                        <div className="d-flex align-items-center">
                          <div
                            className="view_password_reset "
                            onClick={() => showSchoolList()}
                          >
                            <i
                              className={"bi bi-x-circle-fill"}
                            ></i>
                          </div>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="School name"
                            value={manualSchoolName}
                            onChange={(e) => getManualSchoolNameChange(e.target.value)}
                          />
                        </div>
                        {errors.schoolName && (
                          <div className="text-danger fs-12">{errors.schoolName}</div>
                        )}
                      </>
                    }
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>School address</strong>
                    </label>
                    <input
                      className="form-control disable_input"
                      placeholder="School Address"
                      value={schoolAddress}
                      onChange={(e) => setSchoolAddress(e.target.value)}
                      // disabled={defaultOption?.label === "Create new" ? false : true}
                      disabled={ismanualSchoolName === 1 ? false : true}
                    />
                    {errors.schoolAddress && (
                      <div className="text-danger fs-12">
                        {errors.schoolAddress}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-3 eye_parent_container">
                    <div className="d-flex justify-content-between align-items-center flex-row">
                      {infoBox ? (
                        <div className="info_box">
                          Must contain at least one number and one uppercase and
                          one special character, and min 8 and max 16 characters
                          require
                        </div>
                      ) : null}
                      <div>
                        <label className="mb-2">
                          <strong>
                            New password{" "}
                            <span className="label_note">
                              (If you wish not to change your password, keep
                              this blank)
                            </span>
                          </strong>
                        </label>
                      </div>
                      {/* <div
												onMouseOver={handleMouseOver}
												onMouseOut={handleMouseOut}
											>
												<i className="bi bi-info-circle-fill blue_color"></i>
											</div> */}
                    </div>
                    <div className="d-flex align-items-center">
                      <div
                        className="view_password_reset"
                        onClick={() => showPassword()}
                      >
                        <i
                          className={
                            viewPassword == "password"
                              ? "bi bi-eye-fill"
                              : "bi bi-eye-slash-fill"
                          }
                        ></i>
                      </div>
                      <input
                        // type="password"
                        type={viewPassword}
                        className="form-control"
                        placeholder="Password"
                        value={pws}
                        onChange={(e) => setPws(e.target.value)}
                        minLength={6}
                        maxLength={15}
                        autoComplete="new-password"
                        onKeyDown={utility.whiteSpacenotAllow}
                      />
                    </div>
                    {errors.pws && (
                      <div className="text-danger fs-12">{errors.pws}</div>
                    )}
                  </div>

                  <div className="form-group mb-3">
                    <label className="mb-2">
                      <strong>New confirm password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="confirm password"
                      value={cpws}
                      onChange={(e) => setCpws(e.target.value)}
                      onCut={handleChange}
                      onCopy={handleChange}
                      onPaste={handleChange}
                      onKeyDown={utility.whiteSpacenotAllow}
                    />
                    {errors.cpws && (
                      <div className="text-danger fs-12">{errors.cpws}</div>
                    )}
                  </div>

                  {/* btn-primary */}
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-block profile-update-btn"
                    >
                      Update
                      {props.loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3"></div>

        <Modal className="fade" show={otpVerificationModal}>
          <Modal.Header>
            {/* <Modal.Title className="modal_title">OTP Verify</Modal.Title> (M:{props.mOtp}, E: {props.eOtp}) */}
            <Modal.Title className="modal_title">
              Please Verify your Mobile Number{" "}
            </Modal.Title>{" "}
            {/* (M:{utility.decryptAES(props.mOtp)}, E:{" "}
                {utility.decryptAES(props.eOtp)}) */}
            <div className="optLoader">
              {props.mOtp != "" ? (
                <>
                  <div className="timer-wrapper">
                    <CountdownCircleTimer
                      isPlaying
                      duration={props.otpValidTime * 60}
                      // duration={1 * 60}
                      colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
                      colorsTime={[props.otpValidTime * 60, 51, 50, 0]}
                      onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                      size={30}
                      strokeWidth={3}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                  <div className="small_text">Seconds Remaining </div>
                </>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 mb-2">
                      <label className="col-sm-6 col-form-label col-form-label-md">
                        Mobile OTP
                      </label>
                      <input
                        ref={mobileOtpRef}
                        type="text"
                        className="form-control input_value"
                        placeholder="Mobile OTP"
                        onChange={setdata}
                        value={userDetails.motp}
                        name="motp"
                        autocomplete="off"
                        maxLength={6}
                      />
                      {errors.motp && (
                        <div className="text-danger fs-12">{errors.motp}</div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal_footer_container_info">
              <div className="modal_footer_left_container">
                <p className="footer_text">Good Luck</p>
                <p className="footer_text">Team Crestest</p>
              </div>
              <div className="modal_footer_right_container">
                <Button
                  variant="danger text-center modal_footer_button"
                  onClick={() => closeOtpVerificationModal()}
                >
                  Close
                </Button>
                {verifyButtonShow ? (
                  <Button
                    variant="warning text-center modal_footer_button"
                    onClick={verifyOtp}
                  >
                    Verify
                  </Button>
                ) : null}
                {showResendOtp ? (
                  <Button
                    variant="warning text-center modal_footer_button"
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </Button>
                ) : null}
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal className="fade" show={cropImageModal}>
          <Modal.Header>
            <Modal.Title className="modal_title">
              Resize your image{" "}
            </Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setCropImageModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <div className="image-change_container">
              <div className="d-flex mt-3">
                <ProfileImgCrop />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal_footer_container_info"></div>
          </Modal.Footer>
        </Modal>

        <Modal className="fade" show={otpClassModal}>
          <Modal.Header>
            <Modal.Title className="modal_title">
              Verify Your Contact Information{" "}
            </Modal.Title>{" "}
            {/* {clsOTPdetails?.mobile_otp && clsOTPdetails?.email_otp ? (
              <>
                (M:{utility.decryptAES(clsOTPdetails?.mobile_otp)}, E:{" "}
                {utility.decryptAES(clsOTPdetails?.email_otp)})
              </>
            ) : null} */}
            <div className="optLoader">
              {clsOTPdetails?.mobile_otp && clsOTPdetails?.email_otp ? (
                <>
                  <div className="timer-wrapper">
                    <CountdownCircleTimer
                      isPlaying
                      duration={clsOTPdetails?.otp_valid_time * 60}
                      colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
                      colorsTime={[50, 50, 50, 0]}
                      onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                      size={30}
                      strokeWidth={3}
                    >
                      {renderChangeClassTime}
                    </CountdownCircleTimer>
                  </div>
                  <div className="small_text">Seconds Remaining </div>
                </>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="basic-form">
                <div className="row">
                  <div className="col-sm-6 mb-2">
                    <label className="col-sm-6 col-form-label col-form-label-md">
                      Mobile OTP
                    </label>
                    <input
                      ref={mobileClassOtpRef}
                      type="text"
                      className="form-control input_value"
                      placeholder="Mobile OTP"
                      onChange={(e) => setClsOTP(e.target.value)}
                      value={clsOTP}
                      name="clsOTP"
                      autocomplete="off"
                      maxLength={6}
                    />
                    {errors.clsOTP && (
                      <div className="text-danger fs-12">{errors.clsOTP}</div>
                    )}
                  </div>
                  <div className="col-sm-6 mb-2">
                    <label className="col-sm-6 col-form-label col-form-label-md">
                      Email OTP
                    </label>
                    <input
                      ref={emailOtpRef}
                      type="text"
                      className="form-control input_value"
                      placeholder="Email OTP"
                      onChange={(e) => setEmlOTP(e.target.value)}
                      value={emlOTP}
                      name="emlOTP"
                      autocomplete="off"
                      maxLength={6}
                    />
                    {errors.emlOTP && (
                      <div className="text-danger fs-12">{errors.emlOTP}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal_footer_container_info">
              <div className="modal_footer_left_container">
                <p className="footer_text">Good Luck</p>
                <p className="footer_text">Team Crestest</p>
              </div>
              <div className="modal_footer_right_container">
                <Button
                  variant="danger text-center modal_footer_button"
                  onClick={() => closeClassOtpVerificationModal()}
                >
                  Close
                </Button>
                {clsVerifyButtonShow ? (
                  <Button
                    type="button"
                    variant="warning text-center modal_footer_button"
                    onClick={verifyClassChangeOtp}
                  >
                    Verify
                  </Button>
                ) : null}
                {showClsResendOtp ? (
                  <Button
                    type="button"
                    variant="warning text-center modal_footer_button"
                    onClick={resendClassChangeOtp}
                  >
                    Resend OTP
                  </Button>
                ) : null}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    boardList: state.board.boardList,
    classList: state.standard.profileStandardList,
    loading: state.profile.showLoading,
    standard: state.auth.standard,
    board: state.auth.board,
    board_name: state.auth.board_name,
    mOtp: state.student.mobile_otp,
    otpValidTime: state.student.otpValidTime,
    studentDetails: state.profile.details,
    schoolList: state.board.schoolList,
    schoolNameShowingLoader: state.board.schoolNameShowingLoader,
  };
};

export default connect(mapStateToProps)(Profile);
