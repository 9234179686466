import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import {useHistory } from "react-router-dom"
import { Doughnut, getElementAtEvent} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
	Row,
	Col,
	Modal,
} from "react-bootstrap";



const ChartCard8 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	// const [labels, setLabels] = useState(['Ch1', 'Ch2', 'Ch3', 'Ch4', 'Ch5', 'Ch6', 'Ch7', 'Ch8', 'Ch9', 'Ch10', 'Ch11', 'Ch12', 'Ch13', 'Ch14', 'Ch15', 'Ch16', 'Ch17'])
	const [chapters, setChapters] = useState(props.chapters)
	const [subject, setSubject] = useState(props.subject)
	const [labels, setLabels] = useState(props.labelName)
	// const [chartData, setChartData] = useState([60, 60, 60, 60, 60, 60, 70, 70, 70, 80, 80, 80, 80, 90, 90, 90, 90])
	const [chartData, setChartData] = useState(props.value)
	const [tableData, setTableData] = useState(props.tableData)
	const [backgroundColor, setBackgroundColor] = useState(['#FBAFB1',])
	const [totalMarks, setTotalMarks] = useState(props.totalMarks)
	const [showTableModal, setShowTableModal] = useState(false)
	const [filterTableData, setFilterTableData] = useState([])


	const [overallAvg, setOverallAvg] = useState(0)
	const chartRef = useRef();
	const [board_name, setBoard_name] = useState('')
	const [standard, setStandard] = useState('')

	ChartJS.register(ArcElement, Tooltip, Legend);


	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);
	}, []);


	const data = {
		// labels: ["Below 70%", "70% to 79%", "80% to 89%", "90% and above"],
		labels: labels,
		datasets: [{
			label: 'My First Dataset',
			// data: [60,60, 70, 80, 90],
			// data: [60, 60, 60, 60, 60, 60, 70, 70, 70, 80, 80, 80, 80, 90, 90, 90, 90],
			data: chartData,
			// data: ['ch3', 'ch1', 'ch5', 'ch12', 'ch13', 'ch17', 'ch6', 'ch10', 'ch11', 'ch7', 'ch14', 'ch16', 'ch1', 'ch8', 'ch9','ch2', 'ch4'],
			// backgroundColor: backgroundColor,
			backgroundColor: props.chartColor,
			hoverBackgroundColor: props.hoverBackground,
			/* hoverBackgroundColor: [
				'#A12721',
				'#ff9933'
			], */
			borderWidth: 2,
			hoverOffset: 2,
			borderJoinStyle: 'round',

		}],
	};
	const options = {
		maintainAspectRatio: false,
		responsive: true,
		width: "100",
		height: "100",
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				text: "Math PCS set",
				padding: {
					bottom: 10
				},
				weight: "bold",
				color: "#00325c",
				font: {
					size: 13
				},
				align: "center"
			},
			datalabels: {
				labels: {
					name: {
						align: 'center',
						font: { size: 10 },
						color: "#000",
						formatter: function (value, ctx) {
							return ctx.chart.data.labels[ctx.dataIndex];
						},
						anchor: "center", //start, center, end
						rotation: function (ctx) {
							const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
							const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
							const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
							return rotation < 180 ? rotation - 90 : rotation + 90;
						},
					},
				}
			},
		}
	};
	const plugins = [ChartDataLabels, {
		beforeDraw: function (chart) {
			var width = chart.width,
				height = chart.height,
				ctx = chart.ctx;
			ctx.restore();
			var fontSize = (height / 160).toFixed(2);
			ctx.font = fontSize + "em sans-serif";
			ctx.textBaseline = "top";
			var text = totalMarks + "%",
				textX = Math.round((width - ctx.measureText(text).width) / 2),
				textY = (height / 2) - 5;
			ctx.fillText(text, textX, textY);
			ctx.save();
		},

	}];


	const showChartData = (event, item) => {
		let chartIndex = getElementAtEvent(chartRef.current, event);
		let _moudleIndex =  chartIndex[0].index;
		chartIndex = chartIndex[0].index;
		history.push({
			pathname: "/chapterwise-analysis-mol",
			state: { subject_id: subject[chartIndex], chapter_id: chapters[chartIndex], subject:props.pageHeading, moudleIndex: _moudleIndex, group_subject_id: props.group_subject_id, group_name: props.group_name  }
		})
	}

	const showTableData = () => {
		const keyValue = [];
		let valueData = [];
		// console.log("Show Table Data", props.pageHeading); //setTableData //tableData
		const names = Object.keys(tableData)
			.filter((key) => key.includes(props.pageHeading))
			.reduce((obj, key) => {
				return Object.assign(obj, {
					[key]: tableData[key]
				});
			}, [{}]);

		setShowTableModal(true)
		setFilterTableData([names[props.pageHeading]])
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className={`chart_card_parent ${props.highlight == props.pageHeading ? `hightlight_box` : null}`}>
						{/* <div className="chart_card_parent"> */}
						<div className="chart_card_heading">MOL Analysis - {props.pageHeading}</div>
						{props.value && props.tableData ?
							<>
								<div className="chart_body" /* style={{width: '60%', height: '60%'}} */>
									<Doughnut
										ref={chartRef}
										data={data}
										options={options}
										plugins={plugins}
										onClick={showChartData}
									// onClick={props.showChartData}
									/>
									{/* //onClick={() => CheckData()} //redraw={true} */}
								</div>
							</>
							:
							<div>
								No Data Available
							</div>
						}
						<div className="bottom_details">
							{/* <div className="btn_details back_btn_color text_black">Back</div> */}
							{/* <div className="btn_details details_btn_color text_white" onClick={props.showTableData}>Details</div> */}
							<div className="btn_details details_btn_color text_white cursorPointer" onClick={() => showTableData()}>Details</div>
						</div>



					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								<div className='performer_table_heading'>MOL Activity - {props.pageHeading}</div>
								<p>Scholastic <i className="fa fa-angle-right assessment_icon_color"></i>  {board_name}: {standard} <i className="fa fa-angle-right assessment_icon_color"></i> Moudule <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading} </p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="5px" className="performance_analysis_table_data">
								<thead>
									<tr>
										<th className="text-center"></th>
										<th className="text-center"></th>
										<th className="text-center light_sky1">Total Qs</th>
										<th className="text-center light_yellow">Attempted</th>
										<th className="text-center light_gray_3">Not-attempted</th>
										<th className="text-center light_green1">Correct</th>
										<th className="text-center light_red1">Incorrect</th>
										<th className="text-center light_red2">Marks</th>
									</tr>

								</thead>

								<tbody>
									{filterTableData != "" ?

										Object.keys(filterTableData[0]).map((keyName, i) => (

											(i == 0) ?
												<tr key={i} >
													<td className="text-center light_red2" rowSpan={Object.keys(filterTableData[0]).length}>{props.pageHeading}</td>
													<td className="text-center light_gray_1">{keyName}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_question']}</td>
													<td className="text-center light_gray_1">{filterTableData[0][keyName]['total_attended']}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_not_attempted']}</td>
													<td className="text-center light_gray_1">{filterTableData[0][keyName]['total_correct_ans']}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_incorrect_ans']}</td>
													<td className="text-center light_red2">{filterTableData[0][keyName]['total_marks']}</td>
												</tr>
												:
												<tr key={i} >
													<td className="text-center light_gray_1">{keyName}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_question']}</td>
													<td className="text-center light_gray_1">{filterTableData[0][keyName]['total_attended']}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_not_attempted']}</td>
													<td className="text-center light_gray_1">{filterTableData[0][keyName]['total_correct_ans']}</td>
													<td className="text-center light_gray_2">{filterTableData[0][keyName]['total_incorrect_ans']}</td>
													<td className="text-center light_red2">{filterTableData[0][keyName]['total_marks']}</td>
												</tr>

										))
										: null}
								</tbody>
							</table>
						</div>
					</div>
				</>
			</Modal>
		</>
	)
}

export default ChartCard8;