import React, { Fragment, useState, useEffect } from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch } from 'react-redux';
import {
	Row,
	Col,
	Card,
	Tab,
	Badge,
	Dropdown,
	Tooltip,
	Modal,
	Nav,
	Button
} from "react-bootstrap";

import { addToCartData, subscribeLoading, showRightNavAction } from '../../../store/actions/SubscribeAction';

const SubcribeIntegratedCard = (props) => {

	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [isLibrary, setIsLibrary] = useState(props.data.has_library == 0 ? false : true);
	const [totalAmount, setTotalAmount] = useState(props.price);

	useEffect(() => {


	}, []);

	const onProgress = () => {
		dispatch(subscribeLoading(true));
		// dispatch(addToCartData(props.category_id, props.id, props.setCount, 0, 0, props.type, props.class_no ? props.class_no : 0, totalAmount, 0, 0, isLibrary ? 1 : 0, props.history));
		dispatch(addToCartData(props.category_id, props.id, props.setCount, 1, 1, 0, props.class_no ? props.class_no : 0, totalAmount, 0, 0, isLibrary ? 1 : 0, props.history));
		dispatch(showRightNavAction(true))
		setModal(false);
	}

	const onLibraryHandler = (e) => {
		setIsLibrary(e.target.checked);
		if (e.target.checked == true) {
			setTotalAmount(totalAmount + props.library);
		} else {
			setTotalAmount(totalAmount - props.library);
		}
	}

	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					{/* exam_category_id, exam_type_id */}
					{/* <div className={`subcribe-competitive-card  ${props.cartList.length == 0 ? (props.isPurchased == 1 ? "subscription-disable" : null) : (props.isPurchased == 1 || props.cartList[0].subscription_id != props.id && props.cartList[0].exam_type_id == props.data.exam_type_id ? "subscription-disable" : null) }`}>  */}
					<div className={`subcribe-integrated-card  ${props.cartList.length == 0 ? (props.isPurchased == 1 ? "subscription-disable" : null) : (props.isPurchased == 1 || props.cartList.find(x => x.exam_type_id === props.data.exam_type_id && x.exam_category_id === props.data.exam_category_id && x.subscription_id != props.id) ? "subscription-disable" : null)}`}>
					

						<div className="question-mark-circle">
							<i className="fa-sharp fa-solid fa-question"></i>
						</div>
						<div className="subcribe-competitive-details">
							{props.sticker_text != '' ?
								<div className='sticker_base'>
									<div className='sticker_text'>{props.sticker_text}</div>
								</div>
								: null}
							{/* <h4>{props.setCount} Sets</h4> */}
							<h4>{props.integrated_name}</h4>
							{/* <h4>{props.questionPerset * props.setCount} Questions</h4> */}
							<h4>{props.subheading}</h4>
							{/* <p>( {props.questionPerset} Questions per set )</p> */}
							<p>( {props.details} )</p>

							{/* <div className={`set-elibrary-content ${props.data.disabled_library == 1 ? "subscription-disable" : null}`} >

								<div className="set-scholastic-content-lft">

									<div className="form-check custom-checkbox checkbox-success">
										<input
											type="checkbox"
											className="form-check-input"
											id={"e-library" + props.id}
											name={"e-library" + props.id}
											//value={props.library}
											checked={isLibrary}
											onChange={onLibraryHandler}
											disabled={props.cartList.find(e => e.subscription_id == props.id && e.exam_type_id == props.data.exam_type_id ? true : false)}
										/>
										<label
											className="form-check-label"
											htmlFor={"e-library" + props.id}
										>
											E-Library {props.data.disabled_library == 1 ? <span className='small_text_left' >()</span> : null}
										</label>
									</div>

								</div>

								<div className="set-elibrary-content-rght">
									{isLibrary ?
										<p>Rs.{props.library}/-</p>
										: null}
								</div>

							</div> */}

						</div>



						<div className="subcribe-competitive-price-details">
							<div>
								<div>
									{/* {props.data.cart_amount == 0 ? */}
									<h4>Rs.{totalAmount}/- </h4>
									{/* :
								<h4>Rs.{props.data.cart_amount}/-</h4>
							} */}
								</div>
								<div className='small_text_left '>(Including GST)</div>
							</div>

							<div className="displayNone">{props.exam_type_id}</div>
							{props.courseAvailable == 1 ?
							<>
							{props.isPurchased == 1 ?
								null :
								<>
									{!props.cartList.find(element => element.subscription_id === props.id && element.exam_category_id == 3 ) ?
										<Button
											onClick={() => setModal(true)}
										>Add to Cart</Button>
										: <Button disabled>Add to Cart</Button>
									}
								</>
							}
							</>
								:
								<OverlayTrigger
								delay={{ hide: 450, show: 300 }}
								overlay={(props) => (
								<Tooltip {...props}>You cannot add any items because the course validity is expiring soon.</Tooltip>
								)}
								placement="top"
								>
								<Button className='disable_cart_btn'>Add to cart</Button>
								</OverlayTrigger>}
						</div>

					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm subscribe-modal"
				//size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Cart Items</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</Modal.Body>
				<Modal.Footer>
					<div className="subscribe-modal-footer">
						<Button
							className="cancelBtn"
							onClick={() => setModal(false)}
						>
							Cancel
						</Button>
						<Button
							className="proceedBtn"
							onClick={onProgress}
						>Proceed</Button>
					</div>

				</Modal.Footer>
			</Modal>
			{/* {console.log('cartList...',props.cartList)} */}
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.subscribe.showLoading,
		cartList: state.subscribe.cartList
	};
};
export default connect(mapStateToProps)(SubcribeIntegratedCard);