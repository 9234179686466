import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "react-bootstrap";
import IconTint from "react-icon-tint";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getArchiveCategoryData,
  getArchiveLibraryCategoryData,
} from "../../../store/actions/ExamCategoryAction";
import {
  getClassStandardData,
  getArchiveStandardListDetails,
} from "../../../store/actions/ClassStandardAction";

const ArchivePerformanceScore = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const [archiveType, setArchiveType] = useState(state?.type ? state.type : 0);
  const [standard, setStandard] = useState(
    state?.standard ? state.standard : ""
  );
  const [examCategory, setExamCategory] = useState([]);
  const [elibraryCategory, setElibraryCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getArchiveStandardListDetails(props.history));
    //dispatch(getClassStandardData(props.history));
  }, []);

  useEffect(() => {
    if (archiveType !== 0) {
      setLoading(true);
      if (archiveType == 1) {
        dispatch(
          getArchiveCategoryData(
            standard,
            archiveCategoryCallBack,
            props.history
          )
        );
      } else if (archiveType == 2) {
        dispatch(
          getArchiveLibraryCategoryData(
            standard,
            archiveLibraryCategoryCallBack,
            props.history
          )
        );
      }
    }
  }, [archiveType]);

  const archiveCategoryCallBack = (data) => {
    setExamCategory(data);
    setLoading(false);
  };

  const archiveLibraryCategoryCallBack = (data) => {
    setElibraryCategory(data);
    setLoading(false);
  };

  const goPerformance = (id) => {
    if (id == 1) {
      history.push({
        pathname: "/archive-scholastic-performance-score",
        state: { type: archiveType, standard: standard },
      });
    } else if (id == 2) {
      history.push({
        pathname: "/archive-competitive-performance-score",
        state: { type: archiveType, standard: standard },
      });
    } else if (id == 3) {
      history.push({
        pathname: "/e-library-archive-scholastic-performance-score",
        state: { id: 1, type: archiveType, standard: standard },
      });
    } else if (id == 4) {
      history.push({
        pathname: "/e-library-archive-competitive-type-list",
        state: { id: 2, type: archiveType, standard: standard },
      });
    }
  };

  const onStandardHandler = (value) => {
    setStandard(value);
    setArchiveType(0);
  };

  const onChangeArchive = (type) => {
    setArchiveType(type);
  };

  return (
    <div className="row">
      <div className="card card-body">
        <div className="archive-score-container pb-5">
          <div className="archive-score-standard mb-3">
            <div className="form-group col-3 mb-3">
              <div className="profile-basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>
                <select
                  defaultValue={"option"}
                  className="form-control profile-form-control"
                  value={standard}
                  onChange={(e) => onStandardHandler(e.target.value)}
                >
                  <option value="">Select Standard</option>
                  {props.classList.map((item, index) => {
                    return (
                      <option key={index} value={item.class_no}>
                        {item.short_code}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="archive-score-btn-view">
            <button
              onClick={() => onChangeArchive(1)}
              className={`${archiveType === 1 ? "active-archive" : ""}`}
              type="button"
              disabled={!standard}
            >
              Online Exam
            </button>
            <button
              onClick={() => onChangeArchive(2)}
              className={`${archiveType === 2 ? "active-archive" : ""}`}
              type="button"
              disabled={!standard}
            >
              e-Library
            </button>
          </div>

          <div className="row mt-5">
            {archiveType === 1 && !loading ? (
              <div className="exam_inside_container_performance">
                <div
                  className={`col-md-6 contain_container mb-40 cursorPointer ${
                    examCategory[0]?.is_exam == 0
                      ? "subscription-disable"
                      : null
                  }`}
                  style={{ backgroundColor: "#94AC4B" }}
                  onClick={() =>
                    examCategory[0]?.is_exam != 0 ? goPerformance(1) : null
                  }
                >
                  <div className="left_container_performance">
                    <div className="graph_img_container_performance">
                      <img
                        src={examCategory[0]?.performance}
                        alt=""
                        className="graph_img"
                      />
                    </div>
                  </div>
                  <div className="middle_container">
                    <div className="middle_inside_container">
                      <h3 className="font_white">
                        {examCategory[0]?.category}
                      </h3>
                      <p className="font_white">
                        {examCategory[0]?.performance_subheading}
                      </p>
                    </div>
                  </div>
                  <div className="right_container_performance">
                    <IconTint
                      src={examCategory[0]?.performance}
                      alt=""
                      color="#fff"
                      className={"right_image_performance"}
                    />
                  </div>
                </div>

                <div
                  className={`col-md-6 contain_container mb-40 cursorPointer ${
                    examCategory[1]?.is_exam == 0
                      ? "subscription-disable"
                      : null
                  }`}
                  style={{ backgroundColor: "#71BFD8" }}
                  onClick={() =>
                    examCategory[1]?.is_exam != 0 ? goPerformance(2) : null
                  }
                >
                  <div className="left_container_performance">
                    <div className="graph_img_container_performance">
                      <img
                        src={examCategory[1]?.performance}
                        alt=""
                        className="graph_img"
                      />
                    </div>
                  </div>
                  <div className="middle_container">
                    <div className="middle_inside_container">
                      <h3 className="font_white">
                        {examCategory[1]?.category}
                      </h3>
                      <p className="font_white">
                        {examCategory[1]?.performance_subheading}
                      </p>
                    </div>
                  </div>
                  <div className="right_container_performance">
                    <IconTint
                      src={examCategory[1]?.performance}
                      alt=""
                      color="#fff"
                      className={"right_image_performance"}
                    />
                  </div>
                </div>
              </div>
            ) : archiveType === 2 && !loading ? (
              <div className="exam_inside_container_performance">
                <div
                  className={`col-md-6 contain_container mb-40 cursorPointer ${
                    elibraryCategory[0]?.is_access_library == 0
                      ? "subscription-disable"
                      : null
                  }`}
                  style={{ backgroundColor: "#94AC4B" }}
                  onClick={() =>
                    elibraryCategory[0]?.is_exam != 0 ? goPerformance(3) : null
                  }
                >
                  <div className="left_container_performance">
                    <div className="graph_img_container_performance">
                      <img
                        src={elibraryCategory[0]?.performance_e_library}
                        alt=""
                        className="graph_img"
                      />
                    </div>
                  </div>
                  <div className="middle_container">
                    <div className="middle_inside_container">
                      <h3 className="font_white">
                        {elibraryCategory[0]?.category}
                      </h3>
                      <p className="font_white">
                        {elibraryCategory[0]?.performance_e_library_sub_heading}
                      </p>
                    </div>
                  </div>
                  <div className="right_container_performance">
                    <IconTint
                      src={elibraryCategory[0]?.performance_e_library}
                      alt=""
                      color="#fff"
                      className={"right_image_performance"}
                    />
                  </div>
                </div>

                <div
                  className={`col-md-6 contain_container mb-40 cursorPointer ${
                    elibraryCategory[1]?.is_access_library == 0
                      ? "subscription-disable"
                      : null
                  }`}
                  style={{ backgroundColor: "#71BFD8" }}
                  onClick={() =>
                    elibraryCategory[1]?.is_exam != 0 ? goPerformance(4) : null
                  }
                >
                  <div className="left_container_performance">
                    <div className="graph_img_container_performance">
                      <img
                        src={elibraryCategory[0]?.performance_e_library}
                        alt=""
                        className="graph_img"
                      />
                    </div>
                  </div>
                  <div className="middle_container">
                    <div className="middle_inside_container">
                      <h3 className="font_white">
                        {elibraryCategory[1]?.category}
                      </h3>
                      <p className="font_white">
                        {elibraryCategory[1]?.performance_e_library_sub_heading}
                      </p>
                    </div>
                  </div>
                  <div className="right_container_performance">
                    <IconTint
                      src={elibraryCategory[1]?.performance_e_library}
                      alt=""
                      color="#fff"
                      className={"right_image_performance"}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {loading ? (
            <div className="mt-5 mb-5">
              <div className="exam_list_container">
                <div className="spinner-view">
                  <Spinner
                    as="span"
                    animation="border"
                    //size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categorylist: state.category.examcategoryList,
    librarycategoryList: state.category.librarycategoryList,
    classList: state.standard.profileArchiveList,
    //classList: state.standard.classStandardList,
  };
};
export default connect(mapStateToProps)(ArchivePerformanceScore);
