import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";

export function storelocalstorage(api_name) {

    let getData = localStorage.getItem('userDetails');

    const postData = {
        api_name,
        local_storage_data:getData,
    };

    return axios({
        url: GlobalConfigs.API_URL + "lms/storelocalstorage",
        method: "POST",
        headers: {
            ContentType: "application/json",
            //Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}