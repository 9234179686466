import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  loadingToggleAction,
  loginAction,
  demoLogin,
} from "../../store/actions/AuthActions";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import * as utility from "../../utility/Utility";
import { Button, Modal } from "react-bootstrap";
import Loader from "../components/Loader";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getBoardData,
  loadingBoardAction,
  getSchoolListData,
  getSchoolListFailureAction,
  selectSchoolNameListAction,
  schoolNameShowingLoaderAction,
} from "../../store/actions/BoardActions";
import {
  getClassStandardData,
  loadingClassStandardAction,
} from "../../store/actions/ClassStandardAction";
import {
  newStudentRegistrationWithoutDemo,
  showingLoaderStudentAction,
  demoUserRecordExistsOrNot,
  sendVerificationOtp,
  verificationCodeAction,
  recordExistsSuccess,
  registrationStatusDetails,
} from "../../store/actions/StudentAction";
import {
  getAcademicYearByBoardList,
  academicYearLoading,
  getAcademicYearByBoardIdAction,
} from "../../store/actions/AcademicActions";

//defalutValue
import footerIcon from "../../images/policy-icon.png";
// import loginbg from "../../images/background-login.png";
import loginbg from "../../images/landing-bg.jpg";

// import loginLeftImage from "../../images/registration_left_image.png";
import loginLeftImage from "../../images/infographic.jpg";
import Moment from "react-moment";

import TermsCondition from "./TermsCondition";
import PrivacyPolicy from "./PrivacyPolicy";

import ReactGA from "react-ga"; // Add Google Analytics

function RegistrationNew(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const fnameRef = useRef();
  const lnameRef = useRef();
  const mobileRef = useRef();
  const emailRef = useRef();
  const stdRef = useRef();
  const academicRef = useRef();
  const boardRef = useRef();
  const mobileOtpRef = useRef();
  const emailOtpRef = useRef();

  let errorsObj = {
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    std: "",
    academic: "",
    board: "",
    motp: "",
    eotp: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [userDetails, setUserDetails] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    academic: "",
    std: "",
    board: "",
    motp: "",
    eotp: "",
  });

  const [otpVerificationModal, setOtpVerificationModal] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [verifyButtonShow, setVerifyButtonShow] = useState(true);

  const [termsAndConditionModal, SetTermsAndConditionModal] = useState(false);
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  var d = new Date();

  useEffect(() => {
    console.log("history-----", history)
    if (fnameRef.current) {
      fnameRef.current.focus();
    }
    dispatch(getClassStandardData(props.history));
    dispatch(getBoardData(props.history));
  }, []);

  useEffect(() => {
    // Push a new state to the history stack to block back/forward navigation
    window.history.pushState(null, null, window.location.href);

    // Prevent navigation by listening for popstate event
    const handlePopState = () => {
      // When back or forward is pressed, stay on the same page
      window.history.pushState(null, null, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [history]);
  
 


  // Add Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const onFnameHandler = (val) => {
    setUserDetails({ ...userDetails, fname: val });
  };

  const onLnameHandler = (val) => {
    setUserDetails({ ...userDetails, lname: val });
  };

  const onClassHandler = (val) => {
    setUserDetails({ ...userDetails, std: val });
  };

  const onBoardHandler = (val) => {
    if (val) {
      setUserDetails({ ...userDetails, board: val, academic: '' });
      dispatch(getAcademicYearByBoardList(parseInt(val), history));
    } else {
      setUserDetails({ ...userDetails, board: '', academic: '' });
      dispatch(getAcademicYearByBoardIdAction([]));
    }
  };

  const onMobileHandler = (val) => {
    setUserDetails({ ...userDetails, mobile: val });
  };

  const onEmailHandler = (val) => {
    setUserDetails({ ...userDetails, email: val });
  };

  const onAcademicHandler = (val) => {
    setUserDetails({ ...userDetails, academic: val });
  };

  const onMotpHandler = (val) => {
    setUserDetails({ ...userDetails, motp: val });
  };

  const onEotpHandler = (val) => {
    setUserDetails({ ...userDetails, eotp: val });
  };

  /* form validation  */
  const UserRegistration = (e) => {
    e.preventDefault();
    const re = /^[A-Za-z\s]+$/;
    const onlyNumber = /^[0-9]+$/;
    const { fname, lname, mobile, email, std, academic, board } = userDetails;

    let error = false;
    const errorObj = { ...errorsObj };
    if (fname.trim() === "") {
      fnameRef.current.focus();
      errorObj.fname = "First Name is Required";
      error = true;
    } else if (fname.length < 3) {
      fnameRef.current.focus();
      errorObj.fname = "Minimum 3 Character is Required";
      error = true;
    } else if (!re.test(fname)) {
      fnameRef.current.focus();
      errorObj.fname = "Allow only alphabets";
      error = true;
    } else {
      lnameRef.current.focus();
    }
    if (lname.trim() === "") {
      errorObj.lname = "Last Name is Required";
      lnameRef.current.focus();
      error = true;
    } else if (lname.length < 3) {
      errorObj.lname = "Minimum 3 Character is Required";
      lnameRef.current.focus();
      error = true;
    } else {
      if (!re.test(lname)) {
        errorObj.lname = "Allow only alphabets";
        lnameRef.current.focus();
        error = true;
      }
    }

    if (mobile === "") {
      errorObj.mobile = "Mobile is Required";
      mobileRef.current.focus();
      error = true;
    } else if (mobile.length < 10) {
      errorObj.mobile = "Check Mobile No.";
      mobileRef.current.focus();
      error = true;
    } else if (!onlyNumber.test(mobile)) {
      errorObj.mobile = "Allow only numeric";
      mobileRef.current.focus();
      error = true;
    } else if (mobile == "0000000000") {
      errorObj.mobile = "Not a valid number";
      mobileRef.current.focus();
      error = true;
    }

    if (email === "") {
      errorObj.email = "Email is Required";
      emailRef.current.focus();
      error = true;
    } else if (utility.validateEmail(email)) {
      errorObj.email = "Not a Valid Email";
      emailRef.current.focus();
      error = true;
    }

    if (academic === "" || academic === "Select academic year") {
      errorObj.academic = "Academic Year is Required";
      academicRef.current.focus();
      error = true;
    }
    if (std === "") {
      errorObj.std = "Standard is Required";
      stdRef.current.focus();
      error = true;
    }
    if (board === "") {
      errorObj.board = "Board is Required";
      boardRef.current.focus();
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      SetTermsAndConditionModal(true);
      studentDetailsBeforeOtpVerify();
    }
  };

  /* check user details exits or not */
  const checkUserRecord = () => {
    SetTermsAndConditionModal(false);
    dispatch(showingLoaderStudentAction(true));
    dispatch(
      demoUserRecordExistsOrNot(
        userDetails.mobile,
        userDetails.email,
        checkUserRecordCallBack,
        history
      )
    );
    //setOtpVerificationModal(true);
  };
  const checkUserRecordCallBack = (data) => {
    if (data == 0) {
      setOtpVerificationModal(true);
      dispatch(
        sendVerificationOtp(userDetails.mobile, userDetails.email, history)
      );
    }
  };

  /* OTP from validation */
  const verifyOtp = (e) => {
    // history.push("/page-register-success");
    // return;
    e.preventDefault();
    const { fname, lname, mobile, email, std, board, academic } = userDetails;
    const onlyNumber = /^[0-9]+$/;

    const { motp, eotp } = userDetails;
    let error = false;
    const errorObj = { ...errorsObj };

    if (motp === "") {
      mobileOtpRef.current.focus();
      errorObj.motp = "Mobile OTP is required";
      error = true;
    } else if (!onlyNumber.test(motp)) {
      mobileOtpRef.current.focus();
      errorObj.motp = "Allow only numeric";
      error = true;
    } else if (motp.length < 6) {
      mobileOtpRef.current.focus();
      errorObj.motp = "Verify your OTP";
      error = true;
    }

    if (eotp === "") {
      emailOtpRef.current.focus();
      errorObj.eotp = "Email OTP is required";
      error = true;
    } else if (!onlyNumber.test(eotp)) {
      emailOtpRef.current.focus();
      errorObj.eotp = "Allow only numeric";
      error = true;
    } else if (eotp.length < 6) {
      emailOtpRef.current.focus();
      errorObj.eotp = "Verify your OTP";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      if (
        userDetails.eotp == utility.decryptAES(props.eOtp) &&
        userDetails.motp == utility.decryptAES(props.mOtp)
      ) {
        dispatch(showingLoaderStudentAction(true));
        dispatch(
          newStudentRegistrationWithoutDemo(
            fname.trim(),
            lname.trim(),
            mobile.trim(),
            email,
            std,
            board,
            academic,
            registerUserCallback,
            history
          )
        );
      } else {
        /* if otp not match */
        dispatch(
          registrationStatusDetails(
            mobile.trim(),
            email,
            `${fname.trim()} ${lname.trim()}`,
            userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0,
            userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0,
            1,
            userDetails.std,
            userDetails.board,
            userDetails.academic
          )
        );

        alert("Verify your OTP");
      }
    }
  };

  const registerUserCallback = (data) => {
    setOtpVerificationModal(false);
    const removeOtp = {
      email_otp: "",
      mobile_otp: "",
    };
    dispatch(verificationCodeAction(removeOtp));
    console.log("@---removeOtp")
    history.push("/page-register-success");
    console.log("@-11--page-register-success")
  };

  const studentDetailsBeforeOtpVerify = () => {
    dispatch(
      registrationStatusDetails(
        userDetails.mobile.trim(),
        userDetails.email,
        `${userDetails.fname.trim()} ${userDetails.lname.trim()}`,
        userDetails.motp == utility.decryptAES(props.mOtp) ? 1 : 0,
        userDetails.eotp == utility.decryptAES(props.eOtp) ? 1 : 0,
        1,
        userDetails.std,
        userDetails.board,
        userDetails.academic
      )
    );
  };

  /* counting OTP timing */
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      const removeOtp = {
        email_otp: "",
        mobile_otp: "",
      };

      setUserDetails((userDetails) => ({
        ...userDetails,
        motp: "",
        eotp: "",
      }));

      dispatch(verificationCodeAction(removeOtp));
      setShowResendOtp(true);
      setVerifyButtonShow(false);
      dispatch(
        registrationStatusDetails(
          userDetails.mobile,
          userDetails.email,
          `${userDetails.fname} ${userDetails.lname}`,
          0,
          0,
          2,
          userDetails.std,
          userDetails.board,
          userDetails.academic
        )
      );
    }

    return (
      <div className="timer">
        {/* <div className="small_text">Remaining</div> */}
        <div className="remainingTime">{remainingTime}</div>
        {/* <div className="small_text">seconds</div> */}
      </div>
    );
  };

  /* resend otp  */
  const resendOtp = () => {
    dispatch(showingLoaderStudentAction(true));
    dispatch(
      sendVerificationOtp(userDetails.mobile, userDetails.email, history)
    );
    setShowResendOtp(false);
    setVerifyButtonShow(true);
  };

  /* after verification otp modal close */
  const closeOtpVerificationModal = () => {
    dispatch(recordExistsSuccess(null));
    setOtpVerificationModal(false);
    setUserDetails((userDetails) => ({
      ...userDetails,
      password: "",
      cpassword: "",
      motp: "",
      eotp: "",
    }));
    // dispatch(getSchoolListFailureAction([]));

    setShowResendOtp(false);
    setVerifyButtonShow(true);
  };

  return (
    <>
      <div
        className="login-main-page"
        style={{
          backgroundImage: "url(" + loginbg + ")",
          backgroundSize: "cover",
        }}
      >
        <div className="login-wrapper">
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-contain-center">
              <div className="col-xl-1 mt-3"></div>
              <div className="col-xl-10 mt-3">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row m-0">
                      <div className="col-xl-6 col-md-6 sign registration_left_side">
                        <img
                          src={loginLeftImage}
                          className="education-img"
                        ></img>
                      </div>
                      <div className="col-xl-6 col-md-6 p-0">
                        <div className="register-container">
                          <h4>Register</h4>
                          <div className="col-lg-12">
                            <div className="basic-form registration">
                              <form onSubmit={UserRegistration}>
                                <div className="row">
                                  <div className="col-sm-6 mb-3 pe-2">
                                    <input
                                      ref={fnameRef}
                                      selectTextOnFocus={true}
                                      type="text"
                                      className="form-control input_value"
                                      placeholder="First Name"
                                      onChange={(e) =>
                                        onFnameHandler(e.target.value)
                                      }
                                      value={userDetails.fname}
                                      autoComplete="off"
                                      onKeyDown={
                                        utility.inputAlphabetOnlyWithSpace
                                      }
                                    />
                                    {errors.fname && (
                                      <div className="text-danger fs-12">
                                        {errors.fname}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-sm-6 mb-3 ps-2">
                                    <input
                                      ref={lnameRef}
                                      selectTextOnFocus={true}
                                      type="text"
                                      className="form-control input_value"
                                      placeholder="Last Name"
                                      onChange={(e) =>
                                        onLnameHandler(e.target.value)
                                      }
                                      value={userDetails.lname}
                                      autoComplete="off"
                                      onKeyDown={utility.inputAlphabetOnly}
                                    // onKeyDown={utility.inputAlphabetOnlyWithSpace}
                                    />
                                    {errors.lname && (
                                      <div className="text-danger fs-12">
                                        {errors.lname}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-sm-6 mb-3 pe-2">
                                    <div className="form-control select_div_standard">
                                      <select
                                        ref={stdRef}
                                        className="select_option_gender input_value"
                                        value={userDetails.std}
                                        onChange={(e) =>
                                          onClassHandler(e.target.value)
                                        }
                                      >
                                        <option value="">Choose Class</option>
                                        {props.classStandardList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.class_no}
                                              >
                                                {item.short_code}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    {errors.std && (
                                      <div className="text-danger fs-12">
                                        {errors.std}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-sm-6 mb-3 ps-2">
                                    <div className="form-control select_div_board">
                                      <select
                                        ref={boardRef}
                                        className="select_option_gender input_value"
                                        value={userDetails.board}
                                        onChange={(e) =>
                                          onBoardHandler(e.target.value)
                                        }
                                      >
                                        <option value="">Choose Board</option>
                                        {props.boardList.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    {errors.board && (
                                      <div className="text-danger fs-12">
                                        {errors.board}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-sm-6 mb-3 pe-2">
                                    <input
                                      ref={mobileRef}
                                      type="text"
                                      className="form-control input_value"
                                      placeholder="Mobile Number"
                                      onChange={(e) =>
                                        onMobileHandler(e.target.value)
                                      }
                                      value={userDetails.mobile}
                                      autoComplete="off"
                                      maxLength={10}
                                      onKeyPress={utility.inputNumberOnly}
                                    />
                                    {errors.mobile && (
                                      <div className="text-danger fs-12">
                                        {errors.mobile}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-sm-6 mb-3 ps-2">
                                    <input
                                      ref={emailRef}
                                      type="text"
                                      className="form-control input_value"
                                      placeholder="Email"
                                      onChange={(e) =>
                                        onEmailHandler(e.target.value)
                                      }
                                      value={userDetails.email}
                                      autoComplete="off"
                                      onKeyDown={utility.whiteSpacenotAllow}
                                    />
                                    {errors.email && (
                                      <div className="text-danger fs-12">
                                        {errors.email}
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-sm-12 mb-3">
                                    <div className="form-control select_div_standard">
                                      <select
                                        ref={academicRef}
                                        className="select_option_gender input_value"
                                        value={userDetails.academic}
                                        onChange={(e) =>
                                          onAcademicHandler(e.target.value)
                                        }
                                      >
                                        <option value="">Academic Year</option>
                                        {props.academicList.map(
                                          (item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.academicyear}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                    {errors.academic && (
                                      <div className="text-danger fs-12">
                                        {errors.academic}
                                      </div>
                                    )}
                                  </div>

                                  <div className="register_btn_container">
                                    <button
                                      type="submit"
                                      autoFocus={true}
                                      className="btn btn-block register_btn"
                                    >
                                      Register{" "}
                                      <span className="ml-3">
                                        {props.showLoading ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : null}
                                      </span>
                                    </button>
                                  </div>

                                  <div className="mb-3 text-center">
                                    <span className="text-secondary-c font-w600 fs-15">
                                      Already have an account?{" "}
                                      <Link
                                        to="./page-login"
                                        className="text-secondary-c select_text"
                                      >
                                        Sign in
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <p className="text-white mb-0 fs-12">
                    {" "}
                    {
                      <Moment format="YYYY" withTitle>
                        {d}
                      </Moment>
                    }{" "}
                    Crestest Learning Ventures LLP. All Rights Reserved.
                  </p>
                  <p className="font-w500 mb-0 fs-13">
                    <Link
                      to="#"
                      className="privacy-text select_text"
                      onClick={() => setPrivacyPolicyModal(true)}
                    >
                      Privacy Policy
                    </Link>{" "}
                    <img src={footerIcon} />
                  </p>
                </div>
              </div>
              <div className="col-xl-1 mt-3"></div>
            </div>
          </div>
        </div>

        <Modal className="fade" show={otpVerificationModal}>
          <Modal.Header>
            <Modal.Title className="modal_title">
              Verify Your Contact Information{" "}
            </Modal.Title>{" "}
            {/* (M:{utility.decryptAES(props.mOtp)}, E:{" "}
            {utility.decryptAES(props.eOtp)}) */}
            <div className="optLoader">
              {props.mOtp != "" && props.eOtp != "" ? (
                <>
                  <div className="timer-wrapper">
                    <CountdownCircleTimer
                      isPlaying
                      duration={props.otpValidTime * 60}
                      colors={["#004777", "#F7B801", "#ff0000", "#ff0000"]}
                      colorsTime={[50, 50, 50, 0]}
                      onComplete={() => ({ shouldRepeat: false, delay: 1 })}
                      size={30}
                      strokeWidth={3}
                    >
                      {renderTime}
                    </CountdownCircleTimer>
                  </div>
                  <div className="small_text">Seconds Remaining </div>
                </>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="col-lg-12">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-sm-6 mb-2">
                      <label className="col-sm-6 col-form-label col-form-label-md">
                        Mobile OTP
                      </label>
                      <input
                        ref={mobileOtpRef}
                        type="text"
                        className="form-control input_value"
                        placeholder="Mobile OTP"
                        onChange={(e) => onMotpHandler(e.target.value)}
                        value={userDetails.motp}
                        name="motp"
                        autocomplete="off"
                        maxLength={6}
                      />
                      {errors.motp && (
                        <div className="text-danger fs-12">{errors.motp}</div>
                      )}
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label className="col-sm-6 col-form-label col-form-label-md">
                        Email OTP
                      </label>
                      <input
                        ref={emailOtpRef}
                        type="text"
                        className="form-control input_value"
                        placeholder="Email OTP"
                        onChange={(e) => onEotpHandler(e.target.value)}
                        value={userDetails.eotp}
                        name="eotp"
                        autocomplete="off"
                        maxLength={6}
                      />
                      {errors.eotp && (
                        <div className="text-danger fs-12">{errors.eotp}</div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal_footer_container_info">
              <div className="modal_footer_left_container">
                <p className="footer_text">Good Luck</p>
                <p className="footer_text">Team Crestest</p>
              </div>
              <div className="modal_footer_right_container">
                <Button
                  variant="danger text-center modal_footer_button"
                  onClick={() => closeOtpVerificationModal()}
                >
                  Close
                </Button>
                {verifyButtonShow ? (
                  <Button
                    variant="warning text-center modal_footer_button"
                    onClick={verifyOtp}
                  >
                    Verify
                  </Button>
                ) : null}
                {showResendOtp ? (
                  <Button
                    variant="warning text-center modal_footer_button"
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </Button>
                ) : null}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <TermsCondition
          termsAndConditionModal={termsAndConditionModal}
          termsConditionSubmit={() => checkUserRecord()}
          closeTermsConsition={() => SetTermsAndConditionModal(false)}
        />
      </div>
      <div>
        <PrivacyPolicy
          privacyPolicyModal={privacyPolicyModal}
          closePrivacyPolicyModal={() => setPrivacyPolicyModal(false)}
        />
      </div>

      <div>{props.studentShowLoading ? <Loader /> : null}</div>
      <div>
        <ToastContainer />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    newuserId: state.student.newStudentid,
    eOtp: state.student.email_otp,
    mOtp: state.student.mobile_otp,
    otpValidTime: state.student.otpValidTime,
    user_id: state.auth.user_id,
    user_name: state.auth.fname + " " + state.auth.lname,
    questionNo: state.questionNo.currentQestionNo,
    scholasticQuestionList: state.branch.scholasticQuestionList,
    showLoadingBranchScholastic: state.branch.showLoadingBranchScholastic,
    classStandardList: state.standard.classStandardList,
    showLoading: state.board.showLoading,
    boardList: state.board.boardList,
    studentShowLoading: state.student.showLoading,
    schoolList: state.board.schoolList,
    tabN: state.auth.tabName,
    academicList: state.academic.academicMasterList,
    academicLoading: state.academic.showLoading,
    schoolNameShowingLoader: state.board.schoolNameShowingLoader,
  };
};
export default connect(mapStateToProps)(RegistrationNew);
