import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getAcademicYearByBoardId(board_id) {
    const postData = {
        board_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_YEAR_BY_BOARD_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            // Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getAcademicSessionExistForExam(category,board_type) {
    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    const postData = {
        category,
        board_type
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_SESSION_EXIST_OR_EXPIRES_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}