import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
	Row,
	Col,
	Modal
} from "react-bootstrap";

const ArchiveChartCard16Mat = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const [subject, setSubject] = useState(props.subject)
	const [dataSets, setDataSets] = useState(props.dataValue)
	const [showTableModal, setShowTableModal] = useState(false)
	const [tableData, setTableData] = useState(props.tableData)
	const [filterTableData, setFilterTableData] = useState([])
	const [setNo, setSetNo] = useState(props.setNumber)
	const [standard, setStandard] = useState('')
	const [board_name, setBoard_name] = useState('')

	ChartJS.register(ArcElement, Tooltip, Legend);
	const [chatData, setChatData] = useState(props.dataSet)

	useEffect(() => {

	}, [dataSets, tableData, filterTableData]);

	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);

	}, []);

	const data = {
		labels: ["Correct", "Incorrect", "Not attempted"],
		// labels: [],
		datasets: [
			{
				// data: [10, 2, 2],
				data: [props.dataSet.correct_record, props.dataSet.incorrect_record, props.dataSet.not_attempted],
				// data: [dataSets[2], dataSets[1], dataSets[4],],
				// data: setData0,
				borderWidth: 0,
				backgroundColor: [
					"#00B050",
					"#C00000",
					"#A6A6A6",
				],
				/* hoverBackgroundColor: [
					"#000000",
					"#C00000",
					"#A6A6A6",
				], */
			},
		],
	};


	const options = {
		plugins: {
			legend: {
				legend: true,
				position: "left",
				labels: {
					boxWidth: 20,
					padding: 13,
					useSeriesColors: false,
					usePointStyle: true,
					// pointStyleWidth: 10,
				}
			},
			// legend: false,
			// responsive: true,
			datalabels: {
				color: '#000',
				display: true,
				align: 'center',
				backgroundColor: '#D9D9DB',
				borderRadius: 100,
				padding: 3,
				font: {
					size: 12,
				},
			},
		},
		maintainAspectRatio: false,
		onClick: function (evt, element) {
			const typeName = props.exam_type.toUpperCase()
			history.push({ pathname: `/archive-competitive-subjectwise-analysis-on-set/${typeName}-competitive-subjectwise-analysis-on-set`, state:{exam_type : props.exam_type, setNumber: props.setNumber, subType:props.subType,parentpreviousPageName:props.parentpreviousPageName, previousPageName:props.previousPageName,class_no:props.classNo} })
			if (element.length > 0) {
				// console.log(element, element[0]._datasetInde)
				// you can also get dataset of your selected element
				// console.log(data.datasets[element[0]._datasetIndex])
			}
		}
	};

	const showTableData = () => {
		// console.log("Show Table Data");

		setShowTableModal(true)
		setFilterTableData([tableData])
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	const showSetTableData = () => {
		setShowTableModal(true)
	}

	const MoreDetails = () => {
		const typeName = props.exam_type.toUpperCase()
		history.push({ pathname: `/archive-competitive-subjectwise-analysis-on-set/${typeName}-competitive-subjectwise-analysis-on-set`, state:{exam_type : props.exam_type, setNumber: props.setNumber, subType:props.subType,parentpreviousPageName:props.parentpreviousPageName, previousPageName:props.previousPageName,class_no:props.classNo} })
	}


	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>
						{Object.keys(chatData).length !== 0 ?
							<>
								<div className="chart_body" >
									<Pie data={data} width={100} height={50} options={options} plugins={[ChartDataLabels]} />
								</div>
								<div className="bottom_details">
									<div className="btn_details back_btn_color text_black cursorPointer" onClick={MoreDetails}>More Details</div>
									{/* <div className="btn_details details_btn_color text_white cursorPointer" onClick={props.showTableData}>Details</div> */}
									<div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
								</div>
							</>
							:
							<div className='dflex flex1 jcc aic'>
								No Data Available
							</div>
						}
					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								{/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
								<div className='performer_table_heading'>Score Grid - Set (MAT)</div>
								<p>Competitive <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="10px" className="performance_table_data">
								<thead>
									<tr>
										<th className="text-center light_sky">MAT</th>
										{data.labels.map((item, index) => {
											return (
												<th className="text-center light_sky" key={index}>{item}</th>
											)
										})
										}
									</tr>

								</thead>
								<tbody>
									<tr className='table-cell'>
										<td className="text-center light_gray_1">{setNo}</td>
										{data.datasets[0].data.map((item, index) => {
											return (
												<td className="text-center light_gray_1" key={index}>{item}</td>
											)
										})
										}
									</tr>
								</tbody>

							</table>

						</div>

					</div>
				</>
			</Modal>
		</>
	)
}

export default ArchiveChartCard16Mat;