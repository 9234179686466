import {
  getBranchScholasticList,
  demoScholasticExamQuestions,
  demoExamSubmit,
  demoAssessmentList,
  getBranchListAgainstSubjectid,
  scholasticExamQuestionsListForSubscriber,
  scholasticExamSubmitforSubscriber,
  demoCompetitiveExamQuestions,
  demoAssessmentCountList,
  onlineScholasticAssessmentDetails,
  onlineCompetitiveAssessmentDetails,
  scholasticIntermExamSubmitforSubscriber,
  getExamDetailsByExamNoData,
} from "../../services/ScholasticService";

import {
  GET_BRANCH_SCHOLASTIC_LIST,
  BRANCH_SCHOLASTIC_LOADING,
  GET_SCHOLASTIC_QUESTION_LIST,
  SUBMIT_ANSWER,
  DEMO_EXAM_SUBMIT,
  PDF_GENERATE_SUCCESS,
  GET_ASSESSMENT_DETAILS_LIST_REQUEST,
  GET_ASSESSMENT_DETAILS_LIST_SUCCESS,
  GET_ASSESSMENT_DETAILS_LIST_FAILURE,
  BRANCH_LIST_AGAINST_SUBJECT_ID_REQUEST,
  BRANCH_LIST_AGAINST_SUBJECT_ID_SUCCESS,
  BRANCH_LIST_AGAINST_SUBJECT_ID_FAILURE,
  SCHOLASTIC_QUESTIONS_LIST_REQUEST,
  SCHOLASTIC_QUESTIONS_LIST_SUCCESS,
  SCHOLASTIC_QUESTIONS_LIST_FAILURE,
  SCHOLOASTIC_EXAM_SUBMIT_SUCCESS,
  GET_COMPETITIVE_QUESTION_LIST,
  DEMO_ASSESSMENT_LIST_SUCCESS,
  DEMO_ASSESSMENT_LIST_FAILURE,
  ONLINE_SCHOLASTIC_ASSESSMENT_LIST_SUCCESS,
  ONLINE_SCHOLASTIC_ASSESSMENT_LIST_FAILURE,
  ONLINE_SCHOLASTIC_ASSESSMENT_LIST_REQUEST,
  ONLINE_EXAM_ID,
  REMOVE_ONLINE_PREVIOUS_EXAM_ID,
  USERID_DEMO_TO_REGISTER,
  TOTAL_ATTEMPTS,
  GET_EXAM_DETAILS_BY_EXAM_NO,
} from "../constants";

import { logout } from "../actions/AuthActions";

import * as utility from "../../utility/Utility";

export function getBranchScholasticData(id, history) {
  return (dispatch) => {
    getBranchScholasticList(id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getBranchScholasticListAction(response.data.data));
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}
/* get Scholastic Exam question details */
export function getScholasticExamQuestionsData(history) {
  return async (dispatch) => {
    demoScholasticExamQuestions(dispatch)
      .then((response) => {
        if (response.data.status == 200) {
          if (response.data.data != "") {
            dispatch(
              getScholasticQuestionListAction(response.data.data)
            ); /* get Scholastic Exam question details into action */
          } else {
            history.push("/dashboard");
            utility.showError("Sorry! No Question Details Available");
          }
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        } else {
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function submitScholasticExam(data, id, exam_category_id, type, history) {
  let getData = localStorage.getItem("userDetails");
  let registerUserId = JSON.parse(getData).id;

  return (dispatch) => {
    demoExamSubmit(data, id, exam_category_id, dispatch)
      .then((response) => {
        if (response.data.status == 200) {
            if(type==0){
                dispatch(logout(history));
                history.replace("/page-register-success");
            }else{
                dispatch(demoExamSubmitAction(1));
                history.push({ pathname: './demo-assessment-details', state: { exam_category_id, student_id: registerUserId == 0 ? id : registerUserId, student_status: registerUserId == 0 ? 0 : 1 } });
                let olduserDetailsData = JSON.parse(localStorage.userDetails);
                olduserDetailsData.id = id;
                localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));
                dispatch(userIdUpdatedFromDemoToReg(id))
            }
        } else if (response.data.status == 300) {
          utility.showError(response.data.msg);
        } else {
          utility.showError(response.data.msg);
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getDemoAssessmentList(
  exam_category_id,
  student_status,
  student_id,
  history
) {
  return (dispatch) => {
    demoAssessmentList(exam_category_id, student_status, student_id, history)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(demoAssessmentDetailsListSuccess(response.data.data));
          utility.showSuccess(response.data.msg);
        } else {
          dispatch(demoAssessmentDetailsListFaliure(response.data.data));
          history.push("/dashboard");
          utility.showError(response.data.msg);
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function branchListAgainstSubjectid(id, history) {
  return (dispatch) => {
    getBranchListAgainstSubjectid(id, history)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(branchListAgainstSubjectIdSuccessAction(response.data.data));
          // utility.showSuccess(response.data.msg);
        } else {
          dispatch(branchListAgainstSubjectIdFailureAction(response.data.data));
          utility.showError(response.data.msg);
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getScholasticExamQuestionsDataForSubscriber(
  branch,
  chapter,
  subject_id,
  set_no,
  chapter_no,
  group_subject_id,
  history
) {
  return async (dispatch) => {
    scholasticExamQuestionsListForSubscriber(
      branch,
      chapter,
      subject_id,
      set_no,
      chapter_no,
      group_subject_id,
      dispatch
    )
      .then((response) => {
        // console.log(">>>>>>>>>>---response---", response)
        if (response.data.status == 200) {
          if (response.data.data != "") {
            dispatch(
              scholoasticQuestionListForSubscriberSuccessAction(
                response.data.data
              )
            );
            dispatch(totalAttemptsAction(response.data.data[0].total_attempts));
          } else {
            dispatch(
              scholoasticQuestionListForSubscriberFailureAction(
                response.data.data
              )
            );
            history.push({ pathname: "/dashboard" });
            utility.showError("Sorry! No Question Details Available");
          }
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        } else {
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getScholasticExamAnswerSubmitForSubscriber(
  exam_type,
  branch,
  chapter,
  set_no,
  examdata,
  subject_id,
  chapter_no,
  group_subject_id,
  history
) {
  return async (dispatch) => {
    scholasticExamSubmitforSubscriber(
      exam_type,
      branch,
      chapter,
      set_no,
      examdata,
      subject_id,
      chapter_no,
      group_subject_id,
      dispatch
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(RemovePrevouseExamIdAction(0));
          dispatch(onlineExamIdAction(response.data.exam_id));
          const examDetails = {
            examId: response.data.exam_id,
            exam: 1,
            fromExam: 1,
          };
          // localStorage.setItem('refreshExamId', JSON.stringify(response.data.exam_id));
          localStorage.setItem("refreshExamId", JSON.stringify(examDetails));
          history.push({
            pathname: "/online-assessment-details",
            state: { exam: 1, fromExam: 1 },
          });
          // utility.showSuccess(response.data.msg);
          // dispatch(onlineExamIdAction(response.data.exam_id))
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        //console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getScholasticExamAnswerSubmitForSubscriberTimeupSubmit(
  exam_type,
  branch,
  chapter,
  set_no,
  examdata,
  subject_id,
  chapter_no,
  group_subject_id,
  history
) {
  return async (dispatch) => {
    scholasticExamSubmitforSubscriber(
      exam_type,
      branch,
      chapter,
      set_no,
      examdata,
      subject_id,
      chapter_no,
      group_subject_id,
      dispatch
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(RemovePrevouseExamIdAction(0));
          dispatch(onlineExamIdAction(response.data.exam_id));
          const examDetails = {
            examId: response.data.exam_id,
            exam: 1,
            fromExam: 1,
          };
          // localStorage.setItem('refreshExamId', JSON.stringify(response.data.exam_id));
          localStorage.setItem("refreshExamId", JSON.stringify(examDetails));

          // history.push({ pathname: '/online-assessment-details', state: { exam: 1, fromExam: 1 } });
          // utility.showSuccess(response.data.msg);
          // dispatch(onlineExamIdAction(response.data.exam_id))
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        //console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

/* Exam interm data Submit */
export function getscholasticIntermExamSubmitforSubscriber(
  exam_type,
  branch,
  chapter,
  set_no,
  examdata,
  subject_id,
  exam_category,
  time_used,
  questionNo,
  group_subject_id,
  history
) {
  return async (dispatch) => {
    scholasticIntermExamSubmitforSubscriber(
      exam_type,
      branch,
      chapter,
      set_no,
      examdata,
      subject_id,
      exam_category,
      time_used,
      questionNo,
      group_subject_id,
      dispatch
    )
      .then((response) => {
        if (response.data.status == 200) {
          // dispatch(RemovePrevouseExamIdAction(0))
          // dispatch(onlineExamIdAction(response.data.exam_id))
          // history.push({pathname:'/online-assessment-details', state:{exam:1, fromExam:1}});
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        // console.log("****", error);
        // utility.showError(error.response.data);
      });
  };
}

export function getCompetitiveExamQuestionsData(history) {
  return async (dispatch) => {
    demoCompetitiveExamQuestions(dispatch)
      .then((response) => {
        if (response.data.status == 200) {
          if (response.data.data != "") {
            dispatch(
              getScholasticQuestionListAction(response.data.data)
            ); /* get Scholastic Exam question details into action */
          } else {
            history.push("/dashboard");
            utility.showError("Sorry! No Question Details Available");
          }
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        } else {
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getDemoAssessmentListsData(newStudentid, history) {
  return async (dispatch) => {
    demoAssessmentCountList(newStudentid)
      .then((response) => {
        if (response.data.status == 200) {
          if (response.data.demoquestionscount != "") {
            dispatch(
              demoAssessmentListSuccessAction(response.data.demoquestionscount)
            );
          } else {
            dispatch(
              demoAssessmentListFailureAction(response.data.demoquestionscount)
            );
            // utility.showError(response.data.msg);
          }
        } else if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        } else {
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
      });
  };
}

export function getOnlineScholasticAssessmentDetailsExamIDWise(
  examid,
  history
) {
  return (dispatch) => {
    onlineScholasticAssessmentDetails(examid)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(
            onlineScholasticAssessmentListSuccessAction(response.data.data)
          );
          utility.showSuccess(response.data.msg);
        } else {
          dispatch(demoAssessmentDetailsListFaliure(response.data.data));
          history.push("/dashboard");
          utility.showError(response.data.msg);
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getOnlineCompetitiveAssessmentDetailsExamIDWise(
  examid,
  history
) {
  return (dispatch) => {
    onlineCompetitiveAssessmentDetails(examid)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(
            onlineScholasticAssessmentListSuccessAction(response.data.data)
          );
          utility.showSuccess(response.data.msg);
        } else {
          dispatch(demoAssessmentDetailsListFaliure(response.data.data));
          history.push("/dashboard");
          utility.showError(response.data.msg);
        }
        if (response.data.status == 400) {
          dispatch(logout(history));
          utility.showError(response.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getOnlineExamWrongWay(history) {
  return () => {
    utility.showError("Sorry! You have entered in worng way");
    history.push("/dashboard");
  };
}

export function getExamDetailsByExamNoDetails(examid, history) {
  return (dispatch) => {
    getExamDetailsByExamNoData(examid)
      .then((response) => {
        // console.log("****getExamDetailsByExamNoDetails*****----", response)
        if (response.data.status == 200) {
          dispatch(
            getExamDetailsByExamNoAction(response.data.total_attempts_count)
          );
          // utility.showSuccess(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        // utility.showError(error.response.data);
      });
  };
}

export function getBranchScholasticListAction(data) {
  return {
    type: GET_BRANCH_SCHOLASTIC_LIST,
    payload: data,
  };
}
/* get Scholastic Exam question details into action */
export function getScholasticQuestionListAction(data) {
  return {
    type: GET_SCHOLASTIC_QUESTION_LIST,
    payload: data,
  };
}

export function submitDemoExamAction(data) {
  return {
    type: SUBMIT_ANSWER,
    payload: data,
  };
}
/* question loader showing when qustion loading */
export function loadingBranchScholasticAction(status) {
  return {
    type: BRANCH_SCHOLASTIC_LOADING,
    payload: status,
  };
}

export function demoExamSubmitAction(status) {
  return {
    type: DEMO_EXAM_SUBMIT,
    payload: status,
  };
}

export function pdfGenerateSuccessAction(status) {
  return {
    type: PDF_GENERATE_SUCCESS,
    payload: status,
  };
}

export function demoAssessmentDetailsListRequest(status) {
  return {
    type: GET_ASSESSMENT_DETAILS_LIST_REQUEST,
    payload: status,
  };
}

export function demoAssessmentDetailsListSuccess(status) {
  return {
    type: GET_ASSESSMENT_DETAILS_LIST_SUCCESS,
    payload: status,
  };
}

export function demoAssessmentDetailsListFaliure(status) {
  return {
    type: GET_ASSESSMENT_DETAILS_LIST_FAILURE,
    payload: status,
  };
}

export function branchListAgainstSubjectIdRequestAction(status) {
  return {
    type: BRANCH_LIST_AGAINST_SUBJECT_ID_REQUEST,
    payload: status,
  };
}

export function branchListAgainstSubjectIdSuccessAction(status) {
  return {
    type: BRANCH_LIST_AGAINST_SUBJECT_ID_SUCCESS,
    payload: status,
  };
}

export function branchListAgainstSubjectIdFailureAction(status) {
  return {
    type: BRANCH_LIST_AGAINST_SUBJECT_ID_FAILURE,
    payload: status,
  };
}

export function scholoasticQuestionListForSubscriberRequestAction(status) {
  return {
    type: SCHOLASTIC_QUESTIONS_LIST_REQUEST,
    payload: status,
  };
}

export function scholoasticQuestionListForSubscriberSuccessAction(status) {
  return {
    type: SCHOLASTIC_QUESTIONS_LIST_SUCCESS,
    payload: status,
  };
}

export function scholoasticQuestionListForSubscriberFailureAction(status) {
  return {
    type: SCHOLASTIC_QUESTIONS_LIST_FAILURE,
    payload: status,
  };
}

export function scholoasticExamSubmitForSubscriberSuccessAction(status) {
  return {
    type: SCHOLOASTIC_EXAM_SUBMIT_SUCCESS,
    payload: status,
  };
}

export function getCompetitiveQuestionListAction(data) {
  return {
    type: GET_COMPETITIVE_QUESTION_LIST,
    payload: data,
  };
}

export function demoAssessmentListSuccessAction(data) {
  return {
    type: DEMO_ASSESSMENT_LIST_SUCCESS,
    payload: data,
  };
}

export function demoAssessmentListFailureAction(data) {
  return {
    type: DEMO_ASSESSMENT_LIST_FAILURE,
    payload: data,
  };
}

export function onlineScholasticAssessmentListSuccessAction(data) {
  return {
    type: ONLINE_SCHOLASTIC_ASSESSMENT_LIST_SUCCESS,
    payload: data,
  };
}

export function onlineScholasticAssessmentListFailureAction(data) {
  return {
    type: ONLINE_SCHOLASTIC_ASSESSMENT_LIST_FAILURE,
    payload: data,
  };
}

export function onlineAssessmentListRequestAction(data) {
  return {
    type: ONLINE_SCHOLASTIC_ASSESSMENT_LIST_REQUEST,
    payload: data,
  };
}

export function onlineExamIdAction(data) {
  return {
    type: ONLINE_EXAM_ID,
    payload: data,
  };
}
export function RemovePrevouseExamIdAction(data) {
  return {
    type: REMOVE_ONLINE_PREVIOUS_EXAM_ID,
    payload: data,
  };
}

export function userIdUpdatedFromDemoToReg(data) {
  return {
    type: USERID_DEMO_TO_REGISTER,
    payload: data,
  };
}
export function totalAttemptsAction(data) {
  return {
    type: TOTAL_ATTEMPTS,
    payload: data,
  };
}

export function getExamDetailsByExamNoAction(data) {
  return {
    type: GET_EXAM_DETAILS_BY_EXAM_NO,
    payload: data,
  };
}
