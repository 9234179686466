import {
    getScSubject,
    getCmSubject,
    getOnlyLibraryCm,
    getOnlyLibrarySc,
    addtocart,
    getcartlist,
    proceedbuy,
    removeAllSubscribe,
    removeSubscribe,
    getScrollingText,
    getScholasticPrice,
    afterPurchasedGetPurchased,
    getExamAssessmentList,
    getPurchased,
    getInvoicePDF,
    getsubscribedList,
    getLastPaymentDetailsData,
    getIntegratedSubject
} from '../../services/SubjectService';

import { checkAutoLogin } from '../../services/AuthService';

import {
    registerdUserIsSubscribe,
    registerdUserElibraryIsSubscribe
} from './AuthActions';

import {
    GET_SC_SUBJECT_LIST,
    GET_CM_SUBJECT_LIST,
    SIDE_NAV_BAR,
    ADD_TO_CART,
    GET_CART_LIST,
    POST_PROCEED_BUY,
    SUBSCRIBE_LOADING,
    GET_ONLY_LIBRARY_CM_LIST,
    GET_ONLY_LIBRARY_SC_LIST,
    REMOVE_ALL_SUBSCRIBE,
    REMOVE_SUBSCRIBE,
    REMOVE_SUBJECT_LIST,
    SHOW_RIGHT_SIDE_NAV,
    SCROLLING_TEXT_SUCCESS,
    SCROLLING_TEXT_FAILURE,
    SCHOLASTIC_COMBINE_PRICE,
    SCHOLASTIC_COMBINE_PRICE_ID,
    GET_EXAM_ASSESSMENT_LIST,
    GET_TRANSATION_DETAILS,
    SUBCRIPTION_COURSE_VALIDITY,
    GET_SUBSCRIBED_LIST,
    GET_LAST_PAYMENT_DETAILS,
    REMOVE_LIBRARY_LIST,
    PREVOUSE_VALUE_NTSE,
    PREVOUSE_VALUE_NSTSE,
    GETSUBSCRIBED_LIST,
    GET_INTEGRATED_SUBSCRIPTION,
    ASSESSMENT_ACTIVE_PAGE,
    E_LIBRARY_SELECT_SUBJECTS_LIST,
    CHECK_BOX_STATUS,
    SCHOLASTIC_SUBSCRIPTION_SOURCE,
    COMPETITVE_SUBSCRIPTION_SOURCE,
    STORE_ASSESSMENT_FILTER_DATA_STORE,
    BACK_FROM_ASSESSMENT_DETAILS,

} from '../constants';

import * as utility from '../../utility/Utility';

import {
    logout,
} from '../actions/AuthActions';

import jsPDF from 'jspdf';


export function getScSubjectData(board_id, cname, history) {
    return (dispatch) => {
        getScSubject(board_id, cname)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getScSubjectAction(response.data.data));
                    dispatch(subcriptionCourseValidiryAction({course_validity:response.data?.course_validity,course_available:response.data?.course_available, checkProfile: response.data?.checkProfile}));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function getCmSubjectData(type, cname, history) {
    return (dispatch) => {
        getCmSubject(type, cname)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getCmSubjectAction(response.data.data));
                    dispatch(subcriptionCourseValidiryAction({course_validity:response.data?.course_validity,course_available:response.data?.course_available, checkProfile: response.data?.checkProfile}));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                } else {
                    //
                }

            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}


export function getOnlyLibraryCmData(type, cname, history) {
    return (dispatch) => {
        getOnlyLibraryCm(type, cname)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getOnlyLibraryCmAction(response.data.data));
                    dispatch(subcriptionCourseValidiryAction({course_validity:response.data?.course_validity,course_available:response.data?.course_available, checkProfile: response.data?.checkProfile}));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function getOnlyLibraryScData(board, cname, history) {
    return (dispatch) => {
        getOnlyLibrarySc(board, cname)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getOnlyLibraryScAction(response.data.data));
                    dispatch(subcriptionCourseValidiryAction({course_validity:response.data?.course_validity,course_available:response.data?.course_available, checkProfile: response.data?.checkProfile}));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function addToCartData(c_id, sub_id, sets, module, mock, type_id, class_no, amount, case_study, only_elibrary, isLibrary, history) {
    return (dispatch) => {
        addtocart(c_id, sub_id, sets, module, mock, type_id, class_no, amount, case_study, only_elibrary, isLibrary)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getCartData(history));
                    //dispatch(addToCartAction(response.data.data));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                } else {
                    //
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function getCartData(history) {
    return (dispatch) => {
        getcartlist()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getCartAction(response.data));
                } else if (response.data.status == 400) {
                    utility.showError(response.data.msg);
                    dispatch(logout(history));
                    // return;
                } else {
                    //
                }

            })
            .catch((error) => {
                console.log("error")
                // utility.showError(error.response.data);
            });
    };
}

export function proceedbuyData(list, amount, trans_id, regUserSubOrNot, is_subscribe_e_library, history) {
    return (dispatch) => {
        proceedbuy(amount, trans_id)
            .then((response) => {
                if (response.data.status == 200) {
                    history.push({ pathname: '/online-PaymentGateWay', state: { access_code: response.data.data.access_code, encrypted_data: response.data.data.encrypted_data } });
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                //console.log("error-----", error)
                // utility.showError(error.response.data);
            });
        // 
    }

}
export function afterPurchasedGetPurchasedData() {

    let olduserDetailsData = JSON.parse(localStorage.userDetails);

    return (dispatch) => {
        afterPurchasedGetPurchased()
            .then((response) => {
                if (response.data.status == 200) {
                    olduserDetailsData.exam_unique_id = response.data.exam_unique_id;

                    dispatch(registerdUserIsSubscribe(response.data.is_subscribe));
                    dispatch(registerdUserElibraryIsSubscribe(response.data.is_subscribe_e_library))
                    dispatch(removeAllAction());
                    localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));

                    utility.showSuccess(response.data.msg);

                } else if (response.data.status == 400) {
                    dispatch(logout("./page-login"));
                    utility.showError(response.data.msg);
                    return;
                }

            })
            .catch((error) => {
                console.log("error-----", error)
                // utility.showError(error.response.data);
            });
        // 
    }
}

export function getPurchasedList(history) {
    return (dispatch) => {
        getPurchased()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTransationAction(response.data));
                }
                if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                console.log("error")
                // utility.showError(error.response.data);
            });
    };
}

export function getInvoicePDFData(transaction_id, history) {
    return (dispatch) => {
        getInvoicePDF(transaction_id)
            .then((response) => {
                const doc = new jsPDF({
                    format: 'a4',
                    unit: 'px',
                });

                doc.html(response.data.pdffile.content, {
                    callback(doc) {
                        doc.save('purchased_pdf');
                    },
                });

            })
            .catch((error) => {
                console.log("error")
                // utility.showError(error.response.data);
            });
    };
}

export function getIntegratedScSubjectData(board_id, class_id, history) {
    return (dispatch) => {
        getIntegratedSubject(board_id, class_id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getIntegratedScSubjectAction(response.data.data));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    utility.showError(response.data.msg);
                    return;
                } else {
                    //
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

/* 

{
    "status": 200,
    "msg": "Subscription purchased process successfully done",
    "email": "pp@g.com",
    "exam_unique_id": "1678972401442_439",
    "is_subscribe": 3,
    "is_subscribe_e_library": 1
}

*/

/* export function afterPurchasedGetPurchasedData(list, amount, trans_id, regUserSubOrNot, is_subscribe_e_library, history) {
    // let getData = localStorage.getItem('userDetails');
    
    let olduserDetailsData = JSON.parse(localStorage.userDetails);
    const findScholasticCategory = list.filter(element => element.exam_category_id == 1)
    const findCompetitiveCategory = list.filter(element => element.exam_category_id == 2)
    
    return (dispatch) => {
        proceedbuy(list, amount, trans_id)
            .then((response) => {
                console.log( "======response=========", response )
                // history.push({pathname: '/online-PaymentGateWay'})
                
                if (response.data.status == 200) {
                    olduserDetailsData.exam_unique_id = response.data.exam_unique_id;
                    olduserDetailsData.is_subscribe = response.data.is_subscribe;
                    olduserDetailsData.is_subscribe_e_library = response.data.is_subscribe_e_library;
                    localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));
                    dispatch(proceedbuyAction(response.data));
                    
                    if (list[0].only_elibrary == 0) {
                        // if (regUserSubOrNot == 1 || regUserSubOrNot == 2 || regUserSubOrNot == 3) {
                        if (regUserSubOrNot == 1 && list[0].exam_category_id == 1) {
                            dispatch(registerdUserIsSubscribe(1));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(1))
                            }
                        } else if (regUserSubOrNot == 2 && list[0].exam_category_id == 2) {
                            dispatch(registerdUserIsSubscribe(2));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(2))
                            }
                        } else if (regUserSubOrNot == 1 || regUserSubOrNot == 2 || regUserSubOrNot == 3) {
                            dispatch(registerdUserIsSubscribe(3));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(3))
                            }
                        } else {
                            if (list[0].exam_category_id == 1) {
                                dispatch(registerdUserIsSubscribe(1));
                                if (list[0].has_library == 1) {
                                    dispatch(registerdUserElibraryIsSubscribe(1))
                                }
                            } else if (list[0].exam_category_id == 2) {
                                dispatch(registerdUserIsSubscribe(2));
                                if (list[0].has_library == 1) {
                                    dispatch(registerdUserElibraryIsSubscribe(2))
                                }
                            }
                        }
                        if (findScholasticCategory.length > 0 && findCompetitiveCategory.length > 0) {
                            dispatch(registerdUserIsSubscribe(3));
                        }
                    }

                    // e-library----------- 
                    if (list[0].only_elibrary == 1) {
                        if (is_subscribe_e_library == 1 && list[0].exam_category_id == 1) {
                            dispatch(registerdUserElibraryIsSubscribe(1))
                        } else if (is_subscribe_e_library == 2 && list[0].exam_category_id == 2) {
                            dispatch(registerdUserElibraryIsSubscribe(2))
                        } else if (is_subscribe_e_library == 1 || is_subscribe_e_library == 2 || is_subscribe_e_library == 3) {
                            dispatch(registerdUserElibraryIsSubscribe(3))
                        } else {
                            if (response.data.is_subscribe_e_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(1))
                            } else if (response.data.is_subscribe_e_library == 2) {
                                dispatch(registerdUserElibraryIsSubscribe(2))
                            } else if (response.data.is_subscribe_e_library == 3) {
                                dispatch(registerdUserElibraryIsSubscribe(3))
                            }
                        }
                    }
                    utility.showSuccess(response.data.msg);
                } else if (response.data.status == 400) {
                    dispatch(logout("./page-login"));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
} */


/* export function proceedbuyData(list, amount, trans_id, regUserSubOrNot, is_subscribe_e_library, history) {
    // let getData = localStorage.getItem('userDetails');
    
    let olduserDetailsData = JSON.parse(localStorage.userDetails);
    const findScholasticCategory = list.filter(element => element.exam_category_id == 1)
    const findCompetitiveCategory = list.filter(element => element.exam_category_id == 2)
    
    return (dispatch) => {
        proceedbuy(list, amount, trans_id)
            .then((response) => {
                console.log( "======response=========", response )
                // history.push({pathname: '/online-PaymentGateWay'})
                
                if (response.data.status == 200) {
                    olduserDetailsData.exam_unique_id = response.data.exam_unique_id;
                    olduserDetailsData.is_subscribe = response.data.is_subscribe;
                    olduserDetailsData.is_subscribe_e_library = response.data.is_subscribe_e_library;
                    localStorage.setItem('userDetails', JSON.stringify(olduserDetailsData));
                    dispatch(proceedbuyAction(response.data));
                    
                    if (list[0].only_elibrary == 0) {
                        // if (regUserSubOrNot == 1 || regUserSubOrNot == 2 || regUserSubOrNot == 3) {
                        if (regUserSubOrNot == 1 && list[0].exam_category_id == 1) {
                            dispatch(registerdUserIsSubscribe(1));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(1))
                            }
                        } else if (regUserSubOrNot == 2 && list[0].exam_category_id == 2) {
                            dispatch(registerdUserIsSubscribe(2));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(2))
                            }
                        } else if (regUserSubOrNot == 1 || regUserSubOrNot == 2 || regUserSubOrNot == 3) {
                            dispatch(registerdUserIsSubscribe(3));
                            if (list[0].has_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(3))
                            }
                        } else {
                            if (list[0].exam_category_id == 1) {
                                dispatch(registerdUserIsSubscribe(1));
                                if (list[0].has_library == 1) {
                                    dispatch(registerdUserElibraryIsSubscribe(1))
                                }
                            } else if (list[0].exam_category_id == 2) {
                                dispatch(registerdUserIsSubscribe(2));
                                if (list[0].has_library == 1) {
                                    dispatch(registerdUserElibraryIsSubscribe(2))
                                }
                            }
                        }
                        if (findScholasticCategory.length > 0 && findCompetitiveCategory.length > 0) {
                            dispatch(registerdUserIsSubscribe(3));
                        }
                    }

                    // e-library----------- 
                    if (list[0].only_elibrary == 1) {
                        if (is_subscribe_e_library == 1 && list[0].exam_category_id == 1) {
                            dispatch(registerdUserElibraryIsSubscribe(1))
                        } else if (is_subscribe_e_library == 2 && list[0].exam_category_id == 2) {
                            dispatch(registerdUserElibraryIsSubscribe(2))
                        } else if (is_subscribe_e_library == 1 || is_subscribe_e_library == 2 || is_subscribe_e_library == 3) {
                            dispatch(registerdUserElibraryIsSubscribe(3))
                        } else {
                            if (response.data.is_subscribe_e_library == 1) {
                                dispatch(registerdUserElibraryIsSubscribe(1))
                            } else if (response.data.is_subscribe_e_library == 2) {
                                dispatch(registerdUserElibraryIsSubscribe(2))
                            } else if (response.data.is_subscribe_e_library == 3) {
                                dispatch(registerdUserElibraryIsSubscribe(3))
                            }
                        }
                    }
                    utility.showSuccess(response.data.msg);
                } else if (response.data.status == 400) {
                    dispatch(logout("./page-login"));
                    utility.showError(response.data.msg);
                    return;
                }
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
} */

export function removeAllData() {
    return (dispatch) => {
        removeAllSubscribe()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(removeAllAction());
                } else if (response.data.status == 400) {
                    dispatch(logout("./page-login"));
                    utility.showError(response.data.msg);
                    return;
                } else {
                    //
                }

            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function removeData(id, amount) {
    return (dispatch) => {
        removeSubscribe(id)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(removeAction({ 'id': id, 'amount': amount }));
                } else if (response.data.status == 400) {
                    dispatch(logout("./page-login"));
                    utility.showError(response.data.msg);
                    return;
                } else {
                    //
                }

            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function getSubscriptionTextData(history) {
    return async (dispatch) => {
        getScrollingText()
            .then((response) => {
                if (response.data.status == 200) {
                    if (response.data.data != "") {
                        dispatch(scrollingTextSuccessAction(response.data.data));
                    } else {
                        dispatch(scrollingTextFaillureAction(response.data.data));
                    }
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    return;
                } else {

                }

            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}

export function getSCholasticCombinePriceData(recid, set_no, module, mock, elibrary, case_studies, history) {
    return async (dispatch) => {
        getScholasticPrice(recid, set_no, module, mock, elibrary, case_studies)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(scholasticCombinationPriceSuccessAction(response.data.data));
                    dispatch(scholasticCombinationPriceIdAction(recid));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    return;
                }

            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getExamAssessmentListDetails(category, groupSubject, subject, history) {
    return async (dispatch) => {
        getExamAssessmentList(category, groupSubject, subject)
            .then((response) => {
                // console.log("%%%_---11", response)
                if (response.data.status == 200) {
                    dispatch(getExamAssessmentListAction(response.data.data));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getsubscribedListDetails(history) {
    return async (dispatch) => {
        getsubscribedList()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getsubscribedListAction(response.data.data));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                // utility.showError(error.response.data);
            });
    };
}

export function getLastPaymentDetailsDataDetails(history) {
    return async (dispatch) => {
        getLastPaymentDetailsData()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getLastPaymentDetailsDataAction(response.data.data));
                } else if (response.data.status == 400) {
                    dispatch(logout(history));
                    return;
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
            });
    };
}


export function getScSubjectAction(data) {
    return {
        type: GET_SC_SUBJECT_LIST,
        payload: data,
    };
}
export function getCmSubjectAction(data) {
    return {
        type: GET_CM_SUBJECT_LIST,
        payload: data,
    };
}
export function getOnlyLibraryCmAction(data) {
    return {
        type: GET_ONLY_LIBRARY_CM_LIST,
        payload: data,
    };
}
export function getOnlyLibraryScAction(data) {
    return {
        type: GET_ONLY_LIBRARY_SC_LIST,
        payload: data,
    };
}
export function addToCartAction(data) {
    return {
        type: ADD_TO_CART,
        payload: data,
    };
}
export function getCartAction(data) {
    return {
        type: GET_CART_LIST,
        payload: data,
    };
}
export function getTransationAction(data) {
    return {
        type: GET_TRANSATION_DETAILS,
        payload: data,
    };
}
export function proceedbuyAction(data) {
    return {
        type: POST_PROCEED_BUY,
        payload: data,
    };
}
export function removeAllAction(data) {
    return {
        type: REMOVE_ALL_SUBSCRIBE,
        payload: data,
    };
}
export function removeAction(id) {
    return {
        type: REMOVE_SUBSCRIBE,
        payload: id,
    };
}
export function removeSubjectListAction(status) {
    return {
        type: REMOVE_SUBJECT_LIST,
        payload: status,
    };
}

export function removeLibraryListAction(status) {
    return {
        type: REMOVE_LIBRARY_LIST,
        payload: status,
    };
}

export function sideNavAction(status) {
    return {
        type: SIDE_NAV_BAR,
        payload: status,
    };
}
export function subscribeLoading(status) {
    return {
        type: SUBSCRIBE_LOADING,
        payload: status,
    };
}

export function showRightNavAction(status) {
    return {
        type: SHOW_RIGHT_SIDE_NAV,
        payload: status,
    };
}

export function scrollingTextSuccessAction(status) {
    return {
        type: SCROLLING_TEXT_SUCCESS,
        payload: status,
    };
}
export function scrollingTextFaillureAction(status) {
    return {
        type: SCROLLING_TEXT_FAILURE,
        payload: status,
    };
}

export function scholasticCombinationPriceSuccessAction(status) {

    return {
        type: SCHOLASTIC_COMBINE_PRICE,
        payload: status,
    };
}

export function scholasticCombinationPriceIdAction(status) {
    return {
        type: SCHOLASTIC_COMBINE_PRICE_ID,
        payload: status,
    };
}

export function getExamAssessmentListAction(status) {

    return {
        type: GET_EXAM_ASSESSMENT_LIST,
        payload: status,
    };
}

export function getsubscribedListAction(status) {

    return {
        type: GET_SUBSCRIBED_LIST,
        payload: status,
    };
}

export function getLastPaymentDetailsDataAction(status) {
    return {
        type: GET_LAST_PAYMENT_DETAILS,
        payload: status,
    };
}

export function subscriptionPreviousValueNTSEAction(status) {
    return {
        type: PREVOUSE_VALUE_NTSE,
        payload: status,
    };
}

export function subscriptionPreviousValueNSTSEAction(status) {
    return {
        type: PREVOUSE_VALUE_NSTSE,
        payload: status,
    };
}


export function getIntegratedScSubjectAction(status) {
    return {
        type: GET_INTEGRATED_SUBSCRIPTION,
        payload: status,
    };
}

export function assessmentActivePageAction(status) {
    // console.log("assessmentActivePageAction---", status)
    return {
        type: ASSESSMENT_ACTIVE_PAGE,
        payload: status,
    };
}

export function eLibrarySelectSubjectsListAction(status) {
    // console.log("eLibrarySelectSubjectsListAction---", status)
    return {
        type: E_LIBRARY_SELECT_SUBJECTS_LIST,
        payload: status,
    };
}
export function checkBoxStatusAction(status) {
    return {
        type: CHECK_BOX_STATUS,
        payload: status,
    };
}

export function scholasticSubscriptionSourceAction(status) {
    return {
        type: SCHOLASTIC_SUBSCRIPTION_SOURCE,
        payload: status,
    };
}

export function compititiveSubscriptionSourceAction(status) {
    return {
        type: COMPETITVE_SUBSCRIPTION_SOURCE,
        payload: status,
    };
}
export function storeAssessmentFilterDataStoreAction(status) {
    return {
        type: STORE_ASSESSMENT_FILTER_DATA_STORE,
        payload: status,
    };
}

export function backFromAssessmentDetailsAction(status) {
    return {
        type: BACK_FROM_ASSESSMENT_DETAILS,
        payload: status,
    };
}

export function subcriptionCourseValidiryAction(data) {
    return {
        type: SUBCRIPTION_COURSE_VALIDITY,
        payload: data,
    };
}