import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function getExamCategories() {
  let token;
  let getData = localStorage.getItem("userDetails");
  if (getData != null && getData != undefined && getData != "") {
    token = JSON.parse(getData).token;
  }
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_GET_EXAM_CATEGORIES_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAllExamCategories() {
  let token;
  let getData = localStorage.getItem("userDetails");
  if (getData != null && getData != undefined && getData != "") {
    token = JSON.parse(getData).token;
  }
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_GET_ALLEXAM_CATEGORIES_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getLibraryCategories() {
  let token;
  let getData = localStorage.getItem("userDetails");
  if (getData != null && getData != undefined && getData != "") {
    token = JSON.parse(getData).token;
  }
  return axios({
    url: GlobalConfigs.API_URL + Apis.POST_GET_EXAM_CATEGORIES_LIBRARY_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getArchiveCategory(class_no) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  let postData = {
    class: class_no,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ARCHIVE_EXAM_CATEGORIES_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getArchiveLibraryCategory(class_no) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  let postData = {
    class: class_no,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ARCHIVE_EXAM_CATEGORIES_LIBRARY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
