import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Modal, Button, Spinner } from "react-bootstrap";

//Import Components
import ChartCard5 from "../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard5";
import ChartCard6 from "../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard6";
import ChartCard7 from "../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard7";
import ChartCard29 from "../../pages/PerformanceScoreNewUI/performanceComponent/ChartCard29";
import PerformanceLoader from "../../../jsx/components/PerformanceLoader";

import setBodyImage from "../../../setBodyImage";
import dashboardBackground from "../../../images/light_background_1.png";
import performerBackground from "../../../images/score_background.png";

import {
  getgetScholasticSetModuleMockSubjectWiseDataDetails,
  performanceRequestAction,
  subjectWiseScholasticScoreDataDetails,
} from "../../../store/actions/ArchivePerformanceScoreAction";

const ArchiveSubjectwiseScholasticScore = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [previousValue, setPrevousValue] = useState(location.state);
  const [showTableModal, setShowTableModal] = useState(false);

  useEffect(() => {
    dispatch(performanceRequestAction(true)); /* show loader */
    dispatch(
      subjectWiseScholasticScoreDataDetails(
        previousValue.group_subject_id,
        previousValue.class_no,
        props.history
      )
    ); /* get chapter/Module/Mock wise data */
    setBodyImage(performerBackground);
    return () => {
      setBodyImage(dashboardBackground);
    };
  }, []);

  const onShowDetailsHandler = (data) => {
    history.push({
      pathname: "/archive-subjectwise-analysis-ctl",
      state: {
        subName: data,
        exam_type: 1,
        group_subject_id: previousValue.group_subject_id,
        group_name: location.state.group_name,
		    class_no:previousValue.class_no,
      },
    });
  };

  const onShowDetailsSubjectwiseHandler = (data) => {
    history.push({
      pathname: "/archive-subjectwise-analysis-mol",
      state: {
        subName: data,
        exam_type: 2,
        group_subject_id: previousValue.group_subject_id,
        group_name: location.state.group_name,
        class_no:previousValue.class_no,
      },
    });
  };

  const onShowDetailsSubjectwiseMockHandler = (data) => {
    history.push({
      pathname: "/archive-subjectwise-analysis-mcl",
      state: {
        subName: data,
        exam_type: 3,
        group_subject_id: previousValue.group_subject_id,
        group_name: location.state.group_name,
        class_no:previousValue.class_no,
      },
    });
  };

  const goPrevious = () => {
    history.goBack();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 mb-3">
          <div className="text_blue">
          Archive Performance Score{" "}
            <i className="bi bi-chevron-right"></i> Archive Scholastic Performance
            Score({location.state && location.state.group_name}){" "}
            <i className="bi bi-chevron-right"></i> Archive Overall Scholastic
            Performance Score <i className="bi bi-chevron-right"></i>{" "}
            Archive Subjectwise Scholastic Score
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-1">
          <div className="top_back_container" onClick={goPrevious}>
            <span className="back_arrow">
              <i className="bi bi-chevron-left"></i>
            </span>
            Back
          </div>
        </div>
      </div>
      <Row>
        <Col xl={6}>
          {props.SetData != "" && props.scholasticLabel ? (
            <>
              <ChartCard5
                pageHeading="Grade Grid - CTL"
                // scholasticLabel={props.scholasticLabel}
                scholasticLabel={props.setLable}
                SetData={props.SetData}
                // highlight={previousValue.cardno}
                highlight={location.state.cardno}
                // showTableData={() => showSetTableData()}
                group_subject_id={previousValue.group_subject_id}
                group_name={location.state.group_name}
                showDetailsHandler={(data) => onShowDetailsHandler(data)}
              />
            </>
          ) : null}
        </Col>
        <Col xl={6}>
          {props.ModuleData != "" && props.scholasticLabel ? (
            <ChartCard6
              pageHeading="Grade Grid - MOL"
              // scholasticLabel={props.scholasticLabel}
              scholasticLabel={props.moduleLable}
              // ModuleData={props.ModuleData}
              ModuleData={props.ModuleData}
              highlight={location.state.cardno}
              // highlight={previousValue.cardno}
              group_subject_id={previousValue.group_subject_id}
              group_name={location.state.group_name}
              showDetailsHandler={(data) =>
                onShowDetailsSubjectwiseHandler(data)
              }
            />
          ) : null}
        </Col>
        <Col xl={6}>
          {props.MockData != "" && props.scholasticLabel ? (
            <ChartCard7
              pageHeading="Grade Grid - MCL"
              // scholasticLabel={props.scholasticLabel}
              scholasticLabel={props.mockLable}
              MockData={props.MockData}
              // highlight={previousValue.cardno}
              highlight={location.state.cardno}
              group_subject_id={previousValue.group_subject_id}
              group_name={location.state.group_name}
              showDetailsHandler={(data) =>
                onShowDetailsSubjectwiseMockHandler(data)
              }
            />
          ) : null}
        </Col>
        <Col xl={6}>
          {props.MockData != "" && props.scholasticLabel ? (
            <ChartCard29 pageHeading="Colour Guide" />
          ) : (
            <PerformanceLoader />
          )}
        </Col>
        {/* <Link to="/subjectwise-analysis-on-set">Next Page</Link> */}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    MockData: state.archivePerformance.MockData,
    ModuleData: state.archivePerformance.ModuleData,
    SetData: state.archivePerformance.SetData,
    scholasticLabel: state.archivePerformance.scholasticLabel,
    setLable: state.archivePerformance.setLable,
    moduleLable: state.archivePerformance.moduleLable,
    mockLable: state.archivePerformance.mockLable,
  };
};
export default connect(mapStateToProps)(ArchiveSubjectwiseScholasticScore);
