import React, { useEffect, useRef } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"
import { getCategoryData, getLibraryCategoryData } from '../../../store/actions/ExamCategoryAction';
import { onlineScholasticAssessmentListSuccessAction } from '../../../store/actions/ScholasticAction';

import {
	Spinner
} from "react-bootstrap";
import IconTint from 'react-icon-tint';


const PerformanceCategoryElibrary = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const callFirstTimeRef = useRef(true);

	useEffect(() => {
		if (callFirstTimeRef.current) {
			dispatch(getLibraryCategoryData(props.history)); /* call e-library performance data */
		}
		callFirstTimeRef.current = false

	}, [props.categorylist]);


	const removePreviousAssessmentListData = () => {
		dispatch(onlineScholasticAssessmentListSuccessAction(''))
	}

	/* go to particular page */
	const goPerformance = (id) => {
		if (id == 1) {
			history.push({ pathname: "/e-library-scholastic-performance-score", state: {id : id} })
		} else if (id == 2) {
			// history.push({ pathname: "/e-library-competitive-performance-score", state: {id : id} })
			history.push({ pathname: "/e-library-competitive-type-list", state: {id : id} })
		} 
	}

	return (
		<>

			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Performance Score <i className="bi bi-chevron-right"></i> <span className='text-lowercase'>e</span>-Library Performance Score </div>
				</div>
			</div>
			{
				props.librarycategoryList == "" || props.librarycategoryList == undefined ?
					<div className="exam_list_container" >
						<div className="spinner-container">
							<div className="spinner-view">
								<Spinner
									as="span"
									animation="border"
									//size="sm"
									role="status"
									aria-hidden="true"
								/>
							</div>
						</div>
					</div>
					:
					<>
						<div className="row">
							<div className="exam_inside_container_performance" >

								<div className={`col-md-6 contain_container mb-40 cursorPointer ${props.librarycategoryList[0].is_access_library == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: "#94AC4B" }} onClick={() => props.librarycategoryList[0].is_exam != 0 ? goPerformance(1) : null} >
									<div className="left_container_performance">
										<div className='graph_img_container_performance'>
											<img src={props.librarycategoryList[0].performance_e_library} alt="" className='graph_img' />
										</div>
										{/* <IconTint
												src={graph_score}
												alt=""
												color={"#94AC4B"}
												className={'left_image'}
											/> */}
									</div>
									<div className="middle_container">
										<div className="middle_inside_container">
											<h3 className="font_white">{props.librarycategoryList[0].category}</h3>
											<p className="font_white">{props.librarycategoryList[0].performance_e_library_sub_heading}</p>
										</div>
									</div>
									<div className="right_container_performance">
										<IconTint
											src={props.librarycategoryList[0].performance_e_library}
											alt=""
											color="#fff"
											className={'right_image_performance'} />
									</div>
								</div>

								<div className={`col-md-6 contain_container mb-40 cursorPointer ${props.librarycategoryList[1].is_access_library == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: "#71BFD8" }} onClick={() => props.librarycategoryList[1].is_exam != 0 ? goPerformance(2) : null}>
									<div className="left_container_performance">
										<div className='graph_img_container_performance'>
											<img src={props.librarycategoryList[0].performance_e_library} alt="" className='graph_img' />

										</div>
										{/* <IconTint
												src={graph_score}
												alt=""
												color={"#94AC4B"}
												className={'left_image'}
											/> */}
									</div>
									<div className="middle_container">
										<div className="middle_inside_container">
											<h3 className="font_white">{props.librarycategoryList[1].category}</h3>
											<p className="font_white">{props.librarycategoryList[1].performance_e_library_sub_heading}</p>
										</div>
									</div>
									<div className="right_container_performance">
										<IconTint
											src={props.librarycategoryList[1].performance_e_library}
											alt=""
											color="#fff"
											className={'right_image_performance'} />
									</div>
								</div>
							</div>
						</div>
					</>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		// categorylist: state.category.examcategoryList,
		librarycategoryList: state.category.librarycategoryList,
	};
};
export default connect(mapStateToProps)(PerformanceCategoryElibrary);