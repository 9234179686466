import React, { useEffect } from 'react';

import { connect, useDispatch } from 'react-redux';

import CompetitiveExamSubCard from '../components/eLibrary/CompetitiveExamSubCard';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation, useHistory } from "react-router-dom"

import { getExamTypeData } from '../../store/actions/ExamTypeAction';

const CompetitiveTypeLibrary = (props) => {

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		dispatch(getExamTypeData(2, props.history));
	}, []);

	const selectType = (item) => {
		if (item.id == 1) {
			history.push({ pathname: '/competitive-types', state: { id: item.id, exam_type: item.type_name, image: item.image_path } })
		} else {
			history.push({ pathname: '/competitive-exam-Select', state: { id: item.id, exam_type: item.type_name, image: item.image_path} })
		}
		// history.push({ pathname: '/competitive-exam-Select', state: { id: item.id, exam_type: item.type_name } })
	}

	return (
		<>
			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Online Exams <i className="bi bi-chevron-right"></i> Competitive Exams</div>
				</div>
			</div>
			<div className="row">
				<div className="demo-elibrary" >
					{props.typelist != '' ?
						props.typelist.map((item, index) => (
							<div className={`margin_buttom_40 ${item.subscribe == 1 ? "cursorPointer" : null}`} onClick={() => item.subscribe == 1 ? selectType(item) : null} key={index}>
								<CompetitiveExamSubCard
									cardData={item}
									key={index}
									icon={item.image_path} //nstse
									title={item.type_name}
									academicYear={item.academic_year}
									courseValidity={item.course_validity}
									description={item.sub_heading}
									bgColor={item.id == 1 ? '#02879B' : '#02879B'}
									leftColor={'#02879B'}
									iconClass={'new_icon_left_image'}
									iconClassRgt={'new_icon_right_image'}
									subscribe={item.subscribe}
								/>
							</div>
						))

						:
						<div className="exam_list_container" >
							<div className="spinner-container">
								<div className="spinner-view">
									<Spinner
										as="span"
										animation="border"
										//size="sm"
										role="status"
										aria-hidden="true"
									/>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
			{/* {console.log('typelist..',props.typelist)} */}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		typelist: state.examtype.examTypeList
	};
};

export default connect(mapStateToProps)(CompetitiveTypeLibrary);