import React from 'react';
import IconTint from 'react-icon-tint';
import { Link, useHistory } from "react-router-dom";
import * as utility from '../../../utility/Utility';
import moment from "moment";

export default function CompetitiveExamSubCard(props) {

	const history = useHistory();

	const getCourseValidityDate =(date) => {
        let output = "NA";
        if(date.length > 1) {
            let formattedStartDate = moment(date[0]).format("DD/MM/YYYY");
            let formattedEndDate = moment(date[1]).format("DD/MM/YYYY");
            output = `${formattedStartDate} - ${formattedEndDate}`;
        }
        return output;
    }

	return (
		<>
			<div className="row">
				<div className="exam_inside_container" >
					<div className={`col-md-6 sub_contain_container ${props.subscribe == 0 ? "subscription-disable" : null}`} style={{ backgroundColor: props.bgColor }}>
						<div className="left_container">
							{props.icon ?
								<img
									src={props.icon}
									alt=""
									// color={props.leftColor}
									className={props.iconClass}
								/>
								: <div className='no_image_text_container'>
									<h6 className='no_image_text'>No image</h6>
								</div>
							}
						</div>
						<div className="middle_container">
							<div className="middle_inside_container">
								<h3 className="font_white">{props.title}</h3>
								<p className="font_white">{props.description}</p>
							</div>
							<div className="bottom_inside_container">
							<span className="competitive_academic_course">Academic Year : {props.academicYear?props.academicYear:'NA'}</span>
							<span className="competitive_academic_course">Course Validity : {getCourseValidityDate(props.courseValidity)}</span>
							</div>
						</div>
						<div className="right_container">
							<IconTint
								src={props.icon}
								alt=""
								color="#fff"
								className={props.iconClassRgt} />
						</div>
					</div>
					{/* </div> */}
				</div>
			</div>

		</>
	)
}