import React, { useEffect } from "react";

import { connect, useDispatch } from "react-redux";

import CompetitiveElibrarySubCardPerformance from "../../components/eLibrary/CompetitiveElibrarySubCardPerformance";
import Spinner from "react-bootstrap/Spinner";
import { useLocation, useHistory } from "react-router-dom";

import { getArchiveExamTypeLibraryData } from "../../../store/actions/ExamTypeAction";

const ArchiveElibraryCompetitiveTypeList = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getArchiveExamTypeLibraryData(2, state.standard, props.history)
    ); /* call Exam type data */
  }, []);

  /* go to paticulart page as per exam type */
  const selectType = (item) => {
    history.push({
      pathname: "/e-library-archive-competitive-performance-score",
      state: { id: state.id, item: item, class_no: state.standard },
    });

    const competitiveListDataLibrary = {
      item: item,
    };
    localStorage.setItem(
      "localstrageCompetitiveLibraryListData",
      JSON.stringify(competitiveListDataLibrary)
    );
  };

  const goBackHandler = () => {
    history.push({
      pathname: "/archive-performance-score",
      state: state,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="text_blue">
            Archive Performance Score <i className="bi bi-chevron-right"></i>{" "}
            <span className="text-lowercase">e</span>-Library Archive
            Competitive Type List
          </div>
        </div>
        <div className="mt-3"></div>
      </div>

      <div className="row">
        <div className="col-md-1">
          <div className="top_back_container" onClick={() => goBackHandler()}>
            <span className="back_arrow">
              <i className="bi bi-chevron-left"></i>
            </span>
            Back
          </div>
        </div>
      </div>

      <div className="row">
        <div className="demo-elibrary">
          {props.getexamtypeLibrary != "" ? (
            props.getexamtypeLibrary.map((item, index) => (
              <div
                className={`margin_buttom_40 ${
                  item.is_view == 1 ? "cursorPointer" : null
                }`}
                onClick={() => (item.is_view == 1 ? selectType(item) : null)}
                key={index}
              >
                <CompetitiveElibrarySubCardPerformance
                  // key={index}
                  // path={item.id == 1 ? '' : ''}
                  // icon={item.id == 1 ? ntse : ntse} //nstse
                  // icon={item.id == 1 ? ntse : item.id == 2 ? nstse : item.id == 3 ? imo : item.id == 4 ? nso : item.id == 5 ? geo_genious :  null} //nstse
                  icon={item.image_path} //nstse
                  title={item.type_name}
                  description={item.sub_heading}
                  bgColor={item.id == 1 ? "#02879B" : "#02879B"}
                  leftColor={"#02879B"}
                  iconClass={"new_icon_left_image"}
                  iconClassRgt={"new_icon_right_image"}
                  e_subscribe={item.e_subscribe}
                  subject_image={item.subject_image}
                  is_view={item.is_view}
                />
              </div>
            ))
          ) : (
            <div className="exam_list_container">
              <div className="spinner-container">
                <div className="spinner-view">
                  <Spinner
                    as="span"
                    animation="border"
                    //size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    typelist: state.examtype.examTypeList,
    getexamtypeLibrary: state.examtype.getexamtypeLibrary,
  };
};

export default connect(mapStateToProps)(ArchiveElibraryCompetitiveTypeList);
