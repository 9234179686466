import React, { useEffect, useState, useRef } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import GaugeChart from 'react-gauge-chart'

import clv from "../../../../images/needle_clv.png"
import PerformanceLoader from '../../../../jsx/components/PerformanceLoader'

import {
	Row,
	Col,
	Modal
} from "react-bootstrap";

import { performanceRequestAction, getCompetitiveOverAllPerformanceData } from '../../../../store/actions/ArchivePerformanceScoreAction';

let interval;
let _idealTime = 0;

const ArchiveChartCardC = (props) => {

	const dispatch = useDispatch();

	const functionalCallFirstTimeRef = useRef(true);
	const [chartSeries, setChartSeries] = useState();

	const [showTableModal, setShowTableModal] = useState(false)
	const [standard, setStandard] = useState('')
	const [board_name, setBoard_name] = useState('')
	const [showNoData, setShowNoData] = useState(0)

	const totalRef = useRef(true);

	const competitiveOverAllPerformanceTotal = useSelector((state) => state.archivePerformance.competitiveOverAllPerformance);

	useEffect(() => {

		if (functionalCallFirstTimeRef.current) {
			dispatch(performanceRequestAction(true))
			dispatch(getCompetitiveOverAllPerformanceData(props.exam_type,props.classNo, props.history))
		}
		functionalCallFirstTimeRef.current = false;

		setChartSeries(props.competitiveOverAllPerformance.Competitive_avg)
	}, [competitiveOverAllPerformanceTotal]);

	useEffect(() => {
		let getData = localStorage.getItem('userDetails');
		let token = JSON.parse(getData).token;
		let board_name = JSON.parse(getData).board_name;
		let standard = JSON.parse(getData).standard;
		setBoard_name(board_name);
		setStandard(standard);

	}, []);

	useEffect(() => {
		interval = setInterval(() => {
			if (_idealTime >= 3) { // for 2 second
				setShowNoData(1)
				clearInterval(interval);
			}
			_idealTime++;
		}, 1000);

		return () => {
			clearInterval(interval);
			setShowNoData(0)
		}
	}, [_idealTime]);


	const chartStyle = {
		height: 157,
		width: 390,
	}

	const showSetTableData = () => {
		setShowTableModal(true)
	}

	const closeTableModal = () => {
		setShowTableModal(false)
	}

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>

						{props.competitiveOverAllPerformance != '' ?
							chartSeries !== '' && chartSeries !== undefined ?
								<>
									<div className='chart_container flex-1  jcc aic text-center flex_column relative_container justify_self_center performance_score'>
										<div id="chart speed_chart">
											<GaugeChart id="gauge-chart1"
												nrOfLevels={400}
												arcWidth={0.15}
												arcsLength={[0.9, 0.15, 0.15, 0.15]}
												colors={['#C00000', '#FFC000', '#00B050', '#00B0F0']}
												// percent={chartSeries / 100}
												percent={props.competitiveOverAllPerformance.Competitive_avg / 100}
												arcPadding={0.00}
												textColor="#000"
												needleColor="#275D79"
												needleBaseColor="#275D79"
												cornerRadius={0}
												style={chartStyle}
											/>
										</div>
										<div className='needle_base_image_container'>
											<img src={clv} alt="" className='needle_base_image' />
										</div>
									</div>
									<div className="text-center chart_details_text dflex jcc aic">
										{/* <p className="box_details">{chartSeries}%</p> */}
										<p className="box_details">{props.competitiveOverAllPerformance.Competitive_avg}%</p>
									</div>
									<div className="bottom_details">
										{/* <div className="btn_details back_btn_color text_black cursorPointer" onClick={MoreDetails}>More Details</div> */}
										{/* <div className="btn_details details_btn_color text_white cursorPointer" onClick={props.showTableData}>Details</div> */}
										<div className="btn_details details_btn_color text_white cursorPointer" onClick={showSetTableData}>Details</div>
									</div>
								</>
								: null
							: showNoData == 0 ? <PerformanceLoader /> : <div className='dflex flex-1 jcc aic'>
								No Data Available
							</div>}
					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-lg subscribe-modal"
				//size="sm"
				size="lg"
				show={showTableModal}
			>
				<>
					<div className='modal_container'>
						<div className='modal_Header_container'>
							<div>
								{/* <div className='performer_table_heading'>{props.pageHeading}</div> */}
								<div className='performer_table_heading'>Weighted Average Performance</div>
								{/* <p>Competitive <i className="fa fa-angle-right assessment_icon_color"></i> {board_name}: {standard}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p> */}
								<p>Competitive <i className="fa fa-angle-right assessment_icon_color"></i> {props.typeName}  <i className="fa fa-angle-right assessment_icon_color"></i> {props.pageHeading}</p>
							</div>
							<div className='close_btn cursorPointer' onClick={() => closeTableModal()}>
								<i className="fa fa-times" ></i>
							</div>
						</div>
						<div className='modal_body_container'>
							<table cellPadding="10px" className="performance_table_data">
								<thead>
									<tr>
										<th className="text-center light_sky">Label</th>

										{props.competitiveOverAllPerformance != '' && props.competitiveOverAllPerformance != undefined && props.competitiveOverAllPerformance != null ?
											props.competitiveOverAllPerformance.competitntseary.map((item, index) => {
												return (
													<th className="text-center light_sky" key={index}>Set {item.set_no}</th>
												)
											})
											: null}
										<th className="text-center light_sky">Weightage</th>
									</tr>

								</thead>
								<tbody>
									<tr className='table-cell'>
										<td className="text-center light_gray_1">Competitive</td>
										{props.competitiveOverAllPerformance != '' && props.competitiveOverAllPerformance != undefined && props.competitiveOverAllPerformance != null ?
											props.competitiveOverAllPerformance.competitntseary.map((item, index) => {
												return (
													<th className="text-center light_gray_1" key={index}>{item.total_correct_ans}</th>
												)
											})
											: null}
										<td className="text-center light_gray_1">{props.competitiveOverAllPerformance.Competitive_avg}</td>
									</tr>

								</tbody>

							</table>
						</div>
					</div>
				</>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		competitiveOverAllPerformance: state.archivePerformance.competitiveOverAllPerformance,
		loading: state.archivePerformance.loading,
	};
};
export default connect(mapStateToProps)(ArchiveChartCardC);