import React, { Fragment, useState, useEffect } from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom"

import AddProfileDetailsCard from "../subcriptionCard/AddProfileDetailsCard";

import {
	Row,
	Col,
	Modal,
	Button,
	Spinner,
	Tooltip
} from "react-bootstrap";

import { addToCartData, subscribeLoading, getScSubjectData, showRightNavAction, eLibrarySelectSubjectsListAction, scholasticSubscriptionSourceAction, compititiveSubscriptionSourceAction } from '../../../store/actions/SubscribeAction';

import {
	updateStudentProfileOfSubscription,
} from "../../../store/actions/ProfileAction";

// let a1 = [11, 13];
// let b1 = [7, 9, 11, 13]
const LibraryScholasticCard = (props) => {

	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const history = useHistory();
	const [isDetails, setIsDetails] = useState(false);
	const [isProfileUpdated, setIsProfileUpdated] = useState('');

	/* useEffect(() => {
		setIsProfileUpdated(props?.checkProfile?.isComplete)
	  }, [props?.checkProfile?.isComplete]); */

	const onProgress = () => {

		// dispatch(subscribeLoading(true));
		dispatch(addToCartData(props.category_id, props.id, [], 0, 0, props.board, props.class_no ? props.class_no : 0, props.library, 0, 1, 1, props.history));
		dispatch(showRightNavAction(true))
		setModal(false);
		// dispatch(eLibrarySelectSubjectsListAction(props.card_id))
	}

	const checkDisabledStatus = () => {
		let status = false;
		if (props.subject_cards.length > 0) {
			let combo_subject_ids;
			for (let i = 0; i < props.subject_cards.length; i++) {
				combo_subject_ids = props.subject_cards[i].combo_subject_ids;
				if (combo_subject_ids) {
					for (let j = 0; j < combo_subject_ids.length; j++) {
						for (let k = 0; k < props.combo_subject_ids.length; k++) {
							if (combo_subject_ids[j] == props.combo_subject_ids[k]) {
								status = true;
								break;
							}
						}
					}
				}
			}
		}

		//check from cart value
		if (props.cartList.length > 0) {
			let combo_subject_ids;
			for (let i = 0; i < props.cartList.length; i++) {
				combo_subject_ids = props.cartList[i].combo_subject_ids;
				if (combo_subject_ids) {
					for (let j = 0; j < combo_subject_ids.length; j++) {
						for (let k = 0; k < props.combo_subject_ids.length; k++) {
							if (props.cartList[i].exam_category_id == props.category_id &&
								props.cartList[i].has_library == 1 &&
								combo_subject_ids[j] == props.combo_subject_ids[k]) {
								// || props.only_elibrary == 1)
								status = true;
								break;
							}
						}
					}
				}
			}
		}
		return status;
	}

	const onProfileHandler = (data) => {
		dispatch(
			updateStudentProfileOfSubscription(
				data.pincode,
				data.schoolname,
				data.schooladd,
				updataedStudentProfileDetails,
				props.history
			))
	}

	const updataedStudentProfileDetails = (data) => {
		if (data != '') {
			setIsDetails(false);
			setIsProfileUpdated(0)
			props.isCompleteStatusUpdate()
			finalSubscriptionSubmit();
		}
	}
	const finalSubscriptionSubmit = () => {
		setModal(true);
		// let totalValue = props.combinePrice;
		// setFinalTotal(totalValue);
	};

	const updatedProfileInfo = () => {
		setIsDetails(true);
		return;
	}

	return (
		<Fragment>
			<Row>
				<Col xl={12}>
					<div className={`subcribe-scholastic-card ${props.isPurchased == 1 ? "subscription-disable" : null}`}>
						<div className="question-mark-circle">
							<i className="fa-sharp fa-solid fa-question"></i>
						</div>
						{props.sticker_text != '' ?
							<div className='sticker_base'>
								<div className='sticker_text'>{props.sticker_text}</div>
							</div>
							: null}

						<div className="subcribe-scholastic-details">
							{props.subject_image != '' && props.subject_image != undefined && props.subject_image != "undefined"
								?
								<img className='sub_img_container' src={props.subject_image} alt="" />
								:
								<div className='sub_img_container'>No Image</div>
							}
							<h4>{props.subjectName}</h4>
						</div>


						<div className="subcribe-scholastic-price-details">
							<h4>Rs.{props.library}/-</h4>
							{props.courseAvailable == 1 ?
								<>
									{props.isPurchased == 1 ?
										null :
										<>
											{checkDisabledStatus() ?
												<Button disabled>Add to Cart</Button>
												: <Button
													onClick={() => props.isComplete == 0 ? finalSubscriptionSubmit() : updatedProfileInfo()} /* setModal(true) */
												> Add to Cart</Button>}
										</>
									}
								</>
								: <OverlayTrigger
									delay={{ hide: 450, show: 300 }}
									overlay={(props) => (
										<Tooltip {...props}>You cannot add any items because the course validity is expiring soon.</Tooltip>
									)}
									placement="top"
								>
									<Button className='disable_cart_btn'>Add to cart</Button>
								</OverlayTrigger>}
						</div>

					</div>
				</Col>
			</Row>
			<Modal
				className="fade bd-example-modal-sm subscribe-modal"
				//size="sm"
				show={modal}
			>
				<Modal.Header>
					<Modal.Title>Cart Items</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!!props.subjectName && props.subjectName != undefined ?
						<p>Details : Subject : {props.subjectName} (e-Library)</p>
						: null}
					{/* In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used a s a placeholder before final copy is available. */}
				</Modal.Body>
				<Modal.Footer>
					<div className="subscribe-modal-footer">
						<Button
							className="cancelBtn"
							onClick={() => setModal(false)}
						>
							Cancel
						</Button>
						<Button
							className="proceedBtn"
							onClick={onProgress}
						>Proceed
						</Button>
					</div>

				</Modal.Footer>
			</Modal>

			<AddProfileDetailsCard
				show={isDetails}
				onProfileSubmit={(data) => onProfileHandler(data)}
				profileData={props.checkProfile}
				closeHandler={() => setIsDetails(false)}
			/>
		</Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.subscribe.showLoading,
		isNav: state.subscribe.isNav,
		cartList: state.subscribe.cartList
	};
};
export default connect(mapStateToProps)(LibraryScholasticCard);