import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Spinner } from "react-bootstrap";
import Loader from "../../components/Loader";
import moment from "moment";
import LibraryCompetetiveCard from "../../components/ElibraryCard/LibraryCompetetiveCard";
import LibraryScholasticCard from "../../components/ElibraryCard/LibraryScholasticCard";
import { getCategoryData } from "../../../store/actions/ExamCategoryAction";
import { getExamTypeData } from "../../../store/actions/ExamTypeAction";
import { getClassStandardData } from "../../../store/actions/ClassStandardAction";
import { getBoardData } from "../../../store/actions/BoardActions";
import {
  getOnlyLibraryCmData,
  getOnlyLibraryScData,
  getScSubjectData,
  getCmSubjectData,
  subscribeLoading,
  removeSubjectListAction,
  removeLibraryListAction,
  eLibrarySelectSubjectsListAction,
  scholasticSubscriptionSourceAction,
} from "../../../store/actions/SubscribeAction";

const TabLibrary = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoty, setCategory] = useState("");
  const [type, setType] = useState("");
  const [classname, setClassName] = useState("");
  const [board, setBoard] = useState("");
  const [isClass, setIsClass] = useState(true);
  const [defalutSelectValue, setDefalutSelectValue] = useState();
  const [isProfileUpdated, setIsProfileUpdated] = useState('')

  const SubStatus = useSelector(
    (state) => state.subscribe.scSubscriptionStatus
  );
  const eLibrarySelectSubjectsList = useSelector(
    (state) => state.subscribe.eLibrarySelectSubjectsList
  );
  const subscriptionCheckBoxStatus = useSelector(
    (state) => state.subscribe.subscriptionCheckBoxStatus
  );
  const scholasticSubscriptionSourceData = useSelector(
    (state) => state.subscribe.scholasticSubscriptionSourceData
  );
  const competitiveSubscriptionSourceData = useSelector(
    (state) => state.subscribe.competitiveSubscriptionSourceData
  );

  const [elibrarySelectFromSubscription, setElibrarySelectFromSubscription] =
    useState([]);

  useEffect(() => {
    dispatch(getCategoryData(history));
    dispatch(getExamTypeData(2, history));
    dispatch(getClassStandardData(history));
    dispatch(getBoardData(history));
  }, []); /* SubStatus */

  useEffect(() => {
    if (SubStatus === 1) {
      if (type != "") {
        dispatch(
          getOnlyLibraryCmData(type, type == "1" ? 0 : classname, history)
        );
      } else {
        dispatch(getOnlyLibraryScData(board, classname, history));
        setType("");
      }
    }
  }, [SubStatus]);

  useEffect(() => {
    if (props.checkProfile.isComplete == 1) {
      setIsProfileUpdated(1)
    } else {
      setIsProfileUpdated(0)
    }
  }, [props.checkProfile]);

  const onExamCategoryHandler = (event) => {
    setCategory(event.target.value);
    setBoard("");
    setType("");
    setClassName("");
    setIsClass(true);
    // dispatch(removeSubjectListAction());
    if (event.target.value == "Select Exam Category") {
      dispatch(removeLibraryListAction());
    } else if (event.target.value == 2 || event.target.value == 1) {
      dispatch(removeLibraryListAction());
    }
  };

  const onExamtypedHandler = (event) => {
    if (event.target.value != "Select Exam Type") {
      let is_class_value = props.typelist.filter(
        (i) => i.id == event.target.value
      );
      setIsClass(is_class_value[0].is_class == 0 ? true : false);
    }

    setType(event.target.value);
    setClassName("");

    if (
      event.target.value !== "2" &&
      event.target.value !== "5" &&
      event.target.value !== "Select Exam Type"
    ) {
      dispatch(subscribeLoading(true));
      dispatch(getOnlyLibraryCmData(event.target.value, 0, history));
    } else {
      dispatch(removeLibraryListAction());
    }
  };

  const onBoardHandler = (event) => {
    setBoard(event.target.value);
    setIsClass(
      event.target.value && event.target.value != "Select Board" ? false : true
    );
    setClassName("");
    // dispatch(removeSubjectListAction());
    if (event.target.value == "Select Board") {
      dispatch(removeLibraryListAction());
    }
  };

  const onClassHandler = (event) => {
    setClassName(event.target.value);
    if (
      categoty === "1" &&
      event.target.value &&
      event.target.value != "Select Class"
    ) {
      dispatch(subscribeLoading(true));
      dispatch(
        getOnlyLibraryScData(
          board,
          event.target.value != "Select Class" ? event.target.value : "",
          history
        )
      );
    } else if (
      categoty === "2" &&
      event.target.value &&
      event.target.value != "Select Class"
    ) {
      dispatch(subscribeLoading(true));
      dispatch(
        getOnlyLibraryCmData(
          type,
          event.target.value != "Select Class" ? event.target.value : "",
          history
        )
      );
    } else if (event.target.value == "Select Class") {
      dispatch(removeLibraryListAction());
    }
  };

  const getCourseValidityDateformat = (date) => {
    let otp = "NA";
    if (date) {
      let validityDate = date.split("-");
      let startDate = `${validityDate[0]}-${validityDate[1]}-${validityDate[2]}`;
      let endDate = `${validityDate[3]}-${validityDate[4]}-${validityDate[5]}`;
      let formattedStartDate = moment(startDate).format("DD/MM/YYYY");
      let formattedEndDate = moment(endDate).format("DD/MM/YYYY");
      otp = `${formattedStartDate} - ${formattedEndDate}`;
    }
    return otp;
  };

  const isCompleteStatusUpdate = () => {
    setIsProfileUpdated(0)
  }

  return (
    <Fragment>
      <Row>
        <Col xl={12}>
          <div className="subscribe-dropdown-section">
            <div className="subscribe-basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>
              {props.categorylist == "" || props.categorylist == undefined ? (
                <select className="form-control form-control">
                  <option value={defalutSelectValue}>
                    Select Exam Category
                  </option>
                </select>
              ) : (
                <select
                  // defaultValue={"option"}
                  className="form-control form-control"
                  value={categoty}
                  onChange={onExamCategoryHandler}
                >
                  <option value={defalutSelectValue}>
                    Select Exam Category
                  </option>
                  {props.categorylist.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.category}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            {categoty === "2" ? (
              <div className="subscribe-basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>
                <select
                  // defaultValue={"option"}
                  className="form-control form-control"
                  value={type}
                  disabled={categoty ? false : true}
                  onChange={onExamtypedHandler}
                >
                  <option value={defalutSelectValue}>Select Exam Type</option>
                  {props.typelist.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.type_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div className="subscribe-basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>
                <select
                  // defaultValue={"option"}
                  className="form-control form-control"
                  value={board}
                  disabled={
                    categoty && categoty != "Select Exam Category"
                      ? false
                      : true
                  }
                  onChange={onBoardHandler}
                >
                  <option value={defalutSelectValue}>Select Board</option>
                  {<option value={props.board}>{props.board_name}</option>}
                </select>
              </div>
            )}
            <div className="subscribe-basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>
              <select
                // defaultValue={"option"}
                className="form-control form-control"
                value={classname}
                disabled={isClass}
                onChange={onClassHandler}
              >
                <option value={defalutSelectValue}>Select Class</option>
                {<option value={props.standard}>Class {props.standard}</option>}
              </select>
            </div>
          </div>
        </Col>
      </Row>
      {props.showLoading ? (
        <>
          <Loader />
        </>
      ) : null}

      {categoty === "1" ? (
        <Row>
          {props.libraryScList.length > 0 && props.courseValidity ? (
            <Col xl={12}>
              <div className="subsciption_course_valitidy mt-4">
                <span>
                  <strong>Course Validity :</strong>{" "}
                  {getCourseValidityDateformat(props.courseValidity)}
                </span>
                {props.courseAvailable == 1 ? null : (
                  <h4 className="mt-3">
                    <i class="bi bi-info-circle-fill"></i> After the course
                    validity expires, you can't access any features (All online
                    exam and e-Library).
                  </h4>
                )}
              </div>
            </Col>
          ) : null}

          {props.libraryScList.length > 0 && props.courseValidity? (
            <>
              {props.libraryScList.map((item, index) => {
                return (
                  <Col xl={4} key={index}>
                    <LibraryScholasticCard
                      key={item.id}
                      id={item.id}
                      subjectName={item.subject_name}
                      card_id={item.combo_elibrary_subject_id}
                      subject_cards={scholasticSubscriptionSourceData}
                      combo_subject_ids={item.combo_elibrary_subject_id}
                      category_id={categoty}
                      board={board}
                      class_no={classname}
                      courseAvailable={props.courseAvailable}
                      checkProfile={props.checkProfile}
                      isComplete={isProfileUpdated}
                      library={item.library_price}
                      isPurchased={item.is_purchased}
                      sticker_text={item.sticker_text}
                      subject_image={item.subject_image}
                      isCompleteStatusUpdate={isCompleteStatusUpdate}
                    />
                  </Col>
                );
              })}
            </>
          ) : (
            <div class="basic-dropdown text_center_in_page">
              {board && classname && !props.showLoading ? (
                <h4>
                  Course not available at the moment. Don't worry, new courses
                  will be added soon!
                </h4>
              ) : null}
            </div>
          )}
        </Row>
      ) : categoty === "2" ? (
        <Row>
          {props.libraryCmList.length > 0 && props.courseValidity? (
            <Col xl={12}>
              <div className="subsciption_course_valitidy mt-4">
                <span>
                  <strong>Course Validity :</strong>{" "}
                  {getCourseValidityDateformat(props.courseValidity)}
                </span>
                {props.courseAvailable == 1 ? null : (
                  <h4 className="mt-3">
                    <i class="bi bi-info-circle-fill"></i> After the course
                    validity expires, you can't access any features (All online
                    exam and e-Library).
                  </h4>
                )}
              </div>
            </Col>
          ) : null}

          {props.libraryCmList.length > 0 && props.courseValidity? (
            <>
              {props.libraryCmList.map((item, index) => {
                return (
                  <Col xl={4} key={index}>
                    <LibraryCompetetiveCard
                      key={item.id}
                      id={item.id}
                      category_id={categoty}
                      type={type}
                      typeName={item.type_name}
                      class_no={classname}
                      courseAvailable={props.courseAvailable}
                      checkProfile={props.checkProfile}
                      isComplete={isProfileUpdated}
                      exam_type_id={item.exam_type_id}
                      library={item.library_price}
                      isPurchased={item.is_purchased}
                      sticker_text={item.sticker_text}
                      is_select_elibrary_from_subscription={
                        competitiveSubscriptionSourceData
                      }
                      isCompleteStatusUpdate={isCompleteStatusUpdate}
                    />
                  </Col>
                );
              })}
            </>
          ) : (
            <>
              {type !== "Select Exam Type" &&
              type !== "" &&
              !props.showLoading ? (
                <div class="basic-dropdown text_center_in_page">
                  {type == 2 && classname ? (
                    <h4>
                      Course not available at the moment. Don't worry, new
                      courses will be added soon!
                    </h4>
                  ) : null}
                  {type != 2 ? (
                    <h4>
                      Course not available at the moment. Don't worry, new
                      courses will be added soon!
                    </h4>
                  ) : null}
                </div>
              ) : null}
            </>
          )}
        </Row>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    standard: state.auth.standard,
    board: state.auth.board,
    board_name: state.auth.board_name,
    categorylist: state.category.examcategoryList,
    typelist: state.examtype.examTypeList,
    classlist: state.standard.classStandardList,
    boardlist: state.board.boardList,
    scsubjectlist: state.subscribe.scSubjectList,
    cmSubjectList: state.subscribe.cmSubjectList,
    libraryCmList: state.subscribe.eLibraryCmList,
    libraryScList: state.subscribe.eLibraryScList,
    showLoading: state.subscribe.showLoading,
    cartList: state.subscribe.cartList,
    courseValidity: state.subscribe.courseValidity,
    courseAvailable: state.subscribe.courseAvailable,
    checkProfile: state.subscribe.checkProfile,
  };
};
export default connect(mapStateToProps)(TabLibrary);
