import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom"

import {
	Row,
	Col,
	Modal,
} from "react-bootstrap";
import Loader from "../Loader"
import { Doughnut, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';

//Import Components
import ArchiveChartCard8Module from '../../pages/PerformanceScoreNewUI/performanceComponent/ArchiveChartCard8Module';

import setBodyImage from '../../../setBodyImage';
import dashboardBackground from '../../../images/light_background_1.png'
import performerBackground from '../../../images/score_background.png'

import { getScholasticGetsubjectwiseChaptersDataDetails, performanceRequestAction, getscholasticGetsubjectwiseChaptersAction } from '../../../store/actions/ArchivePerformanceScoreAction';
import * as utility from '../../../utility/Utility';

const ArchiveSubjectwiseAnalysisOnMock = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const chartRef = useRef();

	const [previousValue, setPrevousValue] = useState(location.state)
	const [showTableModal, setShowTableModal] = useState(false)

	const [satDetails, setSatDetails] = useState()
	const [subjects, setSubjects] = useState([])
	const [averagePercentage, seAaveragePercentage] = useState()


	useEffect(() => {
		dispatch(performanceRequestAction(true))
		dispatch(getScholasticGetsubjectwiseChaptersDataDetails(location.state.exam_type, location.state.group_subject_id,location.state.class_no, props.history))/* get Scholastic Get subject wise Chapters Data */
		if (location.state.exam_type == 'undefined' || location.state.exam_type == undefined) {
			history.push({ pathname: "/overall-scholastic-performance-score" })
		}
		setBodyImage(performerBackground)
		return () => {
			setBodyImage(dashboardBackground)
			dispatch(getscholasticGetsubjectwiseChaptersAction([])); /* data clear when quit from page */
		};

	}, []);

	useEffect(() => {
		if (props.scholasticGetsubjectwiseChaptersPiechart != '' && props.scholasticGetsubjectwiseChaptersPiechart != undefined) {
			let subjects = [];
			let dataVAlue = props.scholasticGetsubjectwiseChaptersPiechart
			Object.keys(dataVAlue).forEach(function (key) {
				let labels = [];
				let vals = [];
				let chapters = [];
				let subject = []
				let percentage = [];
				let color = []
				let hoverBackground = []
				Object.keys(dataVAlue[key]).forEach(function (key2) {
					labels.push(key2);
					vals.push(dataVAlue[key][key2][0]);
					chapters.push(dataVAlue[key][key2][1]);
					subject.push(dataVAlue[key][key2][2]);

					if (dataVAlue[key][key2][0] < 70) {
						hoverBackground.push('#982626')
					} else if (dataVAlue[key][key2][0] >= 70 && dataVAlue[key][key2][0] < 80) {
						hoverBackground.push('#f1c431')
					} else if (dataVAlue[key][key2][0] >= 80 && dataVAlue[key][key2][0] < 90) {
						hoverBackground.push('#5bba47')
					} else if (dataVAlue[key][key2][0] >= 90) {
						hoverBackground.push('#31c5f4')
					}

				});

				percentage.push(dataVAlue[key]['MOCK1'][3])
				color.push((dataVAlue[key]['MOCK1'][4]))

				let obj = {
					labelName: labels,
					value: vals,
					chapters: chapters,
					subject: subject,
					percentage: percentage,
					color: color,
					hoverBackground: hoverBackground,
				}
				subjects.push({
					[key]: obj
				})
			});

			setSubjects(subjects)
			dispatch(performanceRequestAction(false))

		} else if (props.scholasticGetsubjectwiseChaptersPiechart == undefined && props.scholasticGetsubjectwiseChaptersPiechart == '') {
			if (Object.keys(props.scholasticGetsubjectwiseChaptersPiechart).length === 0) {

				utility.showError("No Data Available");
				history.goBack();
			}

		}
	}, [props.scholasticGetsubjectwiseChaptersPiechart, props.scholasticGetsubjectwiseChaptersTabledata,]);

	const showTableData = () => {
		setShowTableModal(true)
	}
	const closeTableModal = () => {
		setShowTableModal(false)
	}

	const goPrevious = () => {
		dispatch(getscholasticGetsubjectwiseChaptersAction([]));
		history.goBack();
	}

	const onShowChartDataHandler = (chart,item) => {
		let _moudleIndex = chart[0].index;
		let chartIndex = chart[0].index;
		let pageHeading = Object.keys(item)[0];
		let subject = item[Object.keys(item)].subject;
		let chapters = item[Object.keys(item)].chapters;
		history.push({
			pathname: "/archive-chapterwise-analysis-mcl",
			state: {
				subject_id: subject[chartIndex],
				chapter_id: chapters[chartIndex],
				subject: pageHeading,
				moudleIndex: _moudleIndex,
				group_subject_id: location.state.group_subject_id,
				group_name: location.state.group_name,
				class_no: location.state.class_no,
			}
		})

	}

	return (
		<>

			<div className="row">
				<div className="col-md-12 mb-3">
					<div className='text_blue'>Archive Performance Score <i className="bi bi-chevron-right"></i> Archive Scholastic Performance Score({location.state.group_name}) <i className="bi bi-chevron-right"></i> Archive Overall Scholastic Performance Score <i className="bi bi-chevron-right"></i> Archive Subjectwise Scholastic Score <i className="bi bi-chevron-right"></i> Archive Subjectwise Analysis MCL</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-1">
					<div className="top_back_container" onClick={goPrevious}>
						<span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
						Back
					</div>
				</div>

			</div>
			<Row>
				{subjects && subjects.map((item, index) => {
					return (
						<Col xl={6} key={index}>
							<ArchiveChartCard8Module
								pageHeading={Object.keys(item)[0]}
								highlight={location.state.subName}
								showTableData={() => showTableData()}
								labelName={item[Object.keys(item)].labelName}
								value={item[Object.keys(item)].value}
								chapters={item[Object.keys(item)].chapters}
								subject={item[Object.keys(item)].subject}
								totalMarks={item[Object.keys(item)].percentage[0]}
								tableData={props.scholasticGetsubjectwiseChaptersTabledata}
								chartColor={item[Object.keys(item)].color[0]}
								hoverBackground={item[Object.keys(item)].hoverBackground}
								group_subject_id={location.state.group_subject_id}
								group_name={location.state.group_name}
								showChartData={(chartIndex) => onShowChartDataHandler(chartIndex,item)}
							/>
						</Col>
					)
				})}

			</Row>

			<div>
				{props.performanceLoader ?
					<Loader />
					: null}
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		performanceLoader: state.archivePerformance.loading,
		scholasticGetsubjectwiseChaptersPiechart: state.archivePerformance.scholasticGetsubjectwiseChaptersPiechart,
		scholasticGetsubjectwiseChaptersTabledata: state.archivePerformance.scholasticGetsubjectwiseChaptersTabledata,
	};
};
export default connect(mapStateToProps)(ArchiveSubjectwiseAnalysisOnMock);