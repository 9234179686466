import React, { useEffect } from 'react';
import { connect, useDispatch, } from 'react-redux';
import { useHistory } from "react-router-dom"

import ReactApexChart from "react-apexcharts";

import {
	Row,
	Col,
} from "react-bootstrap";


const ChartCard1 = (props) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const data1 = {

		series: [{
			// name: 'Mock',
			name: props.MockData.name,
			data: props.MockData.data,
		}, {
			name: props.ModuleData.name,
			data: props.ModuleData.data
		}, {
			name: props.SetData.name,
			data: props.SetData.data,
		}],
		/* series: [{
			// name: 'Mock',
			name: props.MockData.name,
			// data: [15, 25, 40, 33, 42, 55, 35, 55],
			data: props.MockData.data,
		}, {
			name: 'Module',
			data: [10, 20, 25, 24, 15, 30, 10, 30]
		}, {
			name: 'Set',
			data: [4, 5, 15, 12, 5, 5, 5, 20]
		}], */
		options: {
			chart: {
				height: 350,
				type: 'area'
			},
			dataLabels: {
				enabled: false
			},
			colors: ["#FFAC31", "#E988C1", "#8591CB",],
			stroke: {
				curve: 'smooth' // 'stepline',
			},
			xaxis: {
				type: 'text',
				// categories: ["Math", "Phy", "Chem", "Bio", "Geo", "His", "Civ", "Eco"]
				categories: props.scholasticLabel
			},
			yaxis: {
				min: 0,
				max: 100,
				tickAmount: 8,
			},
			fill: {
				type: ['solid'],
				colors: ["#FFAC31", "#E988C1", "#8591CB",],
				opacity: 1,
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: ["#FFAC31", "#E988C1", "#8591CB",],
				width: 2,
				dashArray: 0,
			},
			legend: {
				show: true,
				labels: {
					colors: ["#FFAC31", "#E988C1", "#8591CB",],
					useSeriesColors: true
				},
				onItemClick: {
					toggleDataSeries: false
				},
			},
			chart: {
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			}
			/* tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm'
				},
			}, */
		},
	};


	useEffect(() => {

	}, []);

	/* select button and go to particular  */
	const showDetails = (e, group_subject_id, group_name) => {
		props.showDetailsHandler(e, group_subject_id, group_name);
		//history.push({ pathname: "/subjectwise-scholastic-score", state: { cardno: e, group_subject_id: group_subject_id, group_name: group_name } })
	}

	return (
		<>
			<Row>
				<Col xl={12}>
					<div className="chart_card_parent">
						<div className="chart_card_heading">{props.pageHeading}</div>
						{props.MockData != '' && props.ModuleData != '' && props.SetData != '' && props.scholasticLabel ?
							<>
								<div className="chart_body">
									<ReactApexChart options={data1.options} series={data1.series} type="area" height={310} />
								</div>
								<div className="flex1 fdr jcc">
									<div className='per_button set_color cursorPointer' onClick={() => showDetails(3, props.group_subject_id, props.group_name)}>Ch Test</div>
									<div className='per_button module_color cursorPointer' onClick={() => showDetails(2, props.group_subject_id, props.group_name)}>Module</div>
									<div className='per_button mock_color cursorPointer' onClick={() => showDetails(1, props.group_subject_id, props.group_name)}>Mock</div>
								</div>
							</>
							: <div>
								No Data Available
							</div>
						}
					</div>
				</Col>
			</Row>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		scholasticOverAllPerformance: state.performance.scholasticOverAllPerformance,
	};
};
export default connect(mapStateToProps)(ChartCard1);