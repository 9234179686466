import React, { useEffect, useRef } from "react";
// import './App.css';

import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import { Button, Modal, } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';

import {
  Position,
  Tooltip,
  Viewer,
  Worker,
  DocumentLoadEvent,
  PrimaryButton,
  RenderPageProps,
  Plugin,
  PluginOnAnnotationLayerRender,
  createStore,
  Store
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { decode as base64_decode, encode as base64_encode } from 'base-64';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useState } from "react";

import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useLocation, useHistory } from "react-router-dom"
import { eliraryShowCallIcon, eliraryCategoryAction, eliraryScholasticCategoryAction, postStoreElibraryTimeSpendDetails, elibraryGetLastsubjectListAction, getgetCheckelibrarysubscriptionExistData } from '../../../store/actions/LibraryAction';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from "../../../store/actions/AuthActions";

let elibraryTitle;
let eCategory;
let elibraryItem;
let elibrarySubjectName
let elibrarySelectId

let interval;
let _visitTime = 0;
let backEventCall;

const AWS = require('aws-sdk');
AWS.config.update({
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
});

const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

export default function PdfViewElibrary() {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const history = useHistory();
  const loacation = useLocation();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);
  const [previousScreenData, setPreviousScreenData] = useState(loacation.state);
  const [pdfUrl, setPdfUrl] = useState();
  const [showFirstPageView, setShowFirstPageView] = useState(0);
  const [isDocumentLoaded, setDocKeywords] = useState(false)
  const [registrationModal, setRegistrationModal] = useState(false)
  const [conceptRegistrationModal, setConceptRegistrationModal] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(true)
  const [visitTime, setVisitTime] = useState(0)
  const [goOtherPage, setGoOtherPage] = useState(0)
  const [firstTimeVisit, setFirstTimeVisit] = useState(0)


  const firstTimeCallRef = useRef(true);

  const board_name = useSelector((state) => state.auth.board_name);
  const standard = useSelector((state) => state.auth.standard);
  const ElibraryCategory = useSelector((state) => state.elibrary.ElibraryCategory);
  const ElibraryScholasticCategory = useSelector((state) => state.elibrary.ElibraryScholasticCategory);
  const showLoading = useSelector(state => state.student.showLoading);

  const elibraryPermission = useSelector((state) => state.elibrary.elibraryPermission);

  const demoUserOrNot = useSelector(state => state.auth.user_id);

  useEffect(() => {
    let EbiraryLocalstorageDetails = localStorage.getItem('localstrageElibraryDetails');
    if (EbiraryLocalstorageDetails != null && EbiraryLocalstorageDetails != undefined && EbiraryLocalstorageDetails != '') {
      
      elibraryTitle = JSON.parse(EbiraryLocalstorageDetails).title;
      eCategory = JSON.parse(EbiraryLocalstorageDetails).category;
      elibraryItem = JSON.parse(EbiraryLocalstorageDetails).item;
      elibrarySubjectName = JSON.parse(EbiraryLocalstorageDetails).subjectName;
      elibrarySelectId = JSON.parse(EbiraryLocalstorageDetails).selectId;
    }
    if (ElibraryScholasticCategory == '') {
      dispatch(eliraryScholasticCategoryAction([elibraryItem, eCategory, elibrarySubjectName, elibrarySelectId]));
    }

    if (firstTimeCallRef.current) {
      setIsFullScreen(true)
    }
    firstTimeCallRef.current = false


    const urlPdfPathValue = window.location.pathname.split('/');
    const modalOpenForRegistration = window.location.href.split('#');

    let getData = localStorage.getItem('userDetails');
    let id = JSON.parse(getData).id;

    if (modalOpenForRegistration[1] == 8) {
      setRegistrationModal(true)
    }

    const endcodePdfValue = urlPdfPathValue[urlPdfPathValue.length - 1];
    let decodePdfUrl = base64_decode(endcodePdfValue);

    dispatch(getgetCheckelibrarysubscriptionExistData(decodePdfUrl.split("#")[0], history));

    const showIntialPageNumber = decodePdfUrl.split("=")[1];
    
    getPdfContentFromAWSS3BucketPrivately(decodePdfUrl);

    setShowFirstPageView(showIntialPageNumber == undefined ? 0 : showIntialPageNumber - 1);

    const handleContextmenu = e => {
      e.preventDefault()
    }

    dispatch(eliraryShowCallIcon(1))


    window.addEventListener('keydown', function (event) {
      if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey || window.chrome || window.opera)) {
        event.preventDefault();
        if (event.stopImmediatePropagation) {
          event.stopImmediatePropagation();
        } else {
          event.stopPropagation();
        }
        return;
      }
    }, true);

    document.addEventListener('contextmenu', handleContextmenu)

    return function cleanup() {
      dispatch(eliraryShowCallIcon(0))
      document.removeEventListener('contextmenu', handleContextmenu)
      dispatch(eliraryCategoryAction([]));
    }
  }, [])

  
  useEffect(() => {
    interval = setInterval(() => {
      _visitTime++
    }, 1000);

    return () => {
      clearInterval(interval);
      
      if (ElibraryCategory[1] != "Demo") {
        if (ElibraryScholasticCategory.length <= 0 && elibraryTitle == "") {
          dispatch(postStoreElibraryTimeSpendDetails(ElibraryScholasticCategory[3], _visitTime, elibraryItem.short_code, history));
          _visitTime = 0
        } else {
          dispatch(postStoreElibraryTimeSpendDetails(ElibraryScholasticCategory[3], _visitTime, ElibraryScholasticCategory[0].short_code, history));
          _visitTime = 0
        }
      } else {
        dispatch(postStoreElibraryTimeSpendDetails(0, _visitTime, "demo", history));
        _visitTime = 0
      }
    }
  }, [])

  /* pdf file download from S3 BUCKET */
  const getPdfContentFromAWSS3BucketPrivately = (url) => {
    //const S3_BUCKET_NAME = "crestest-dam-bucket"; //process.env.S3_BUCKET_NAME;
    const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;

    let parts = url.split("/");
    // href = parts[parts.length - 1];
    const pdfFileName = parts[parts.length - 1];

    let pdfFileWithoutPageNumber = pdfFileName.split("#");
    let finalPdfFileWithoutPageNumber = pdfFileWithoutPageNumber[0];

    // let fullPathPdf = `elibrary/${pdfFileName}`
    let fullPathPdf = `elibrary/${finalPdfFileWithoutPageNumber}`

    // console.log("$>>fullPathPdf>>", fullPathPdf)
    s3.getObject({
      Bucket: S3_BUCKET_NAME,
      // Key: "elibrary/1674811975750NTPHCH1CM.pdf",
      Key: fullPathPdf,
      ResponseContentType: 'Uint8Array',
    }, (err, data) => {
      if (err) {
        console.log("@@Error : " + err);
      } else {
        // console.log ( "@@data.Body : " + data.Body );
        setPdfUrl(data.Body);
      }
    });
  }


  /* fullscreen off and on */
  const fullScreeTtoggleChange = () => {
    setIsFullScreen(!isFullScreen)
  }

  /* Open PDF Link */
  const openLinksPlugin = () => {

    const findLinks = (e) => {

      e.container.querySelectorAll('a:not([data-annotation-link-dest])').forEach((link) => {
        
        let href = link.getAttribute('href');
        
        if (href && href != "") {
          
          link.addEventListener('click', handleClick);
        }
      });
    };

    /* Open Link  */
    const handleClick = (e) => {
      if (demoUserOrNot == 0) {
        e.preventDefault();
        setConceptRegistrationModal(true)
      } else {
        e.preventDefault();
        dispatch(postStoreElibraryTimeSpendDetails(ElibraryScholasticCategory[3], _visitTime, ElibraryScholasticCategory[0].short_code, history));
        // dispatch(postStoreElibraryTimeSpendDetails(ElibraryScholasticCategory[0].branch_id, _visitTime, ElibraryScholasticCategory[0].short_code, history));
        _visitTime = 0
        let href = (e.target).href;

        setGoOtherPage(1)

        const pageDetails = {
          goOtherPage: 1
        }

        localStorage.setItem('goToDetailsPage', JSON.stringify(pageDetails));

        // console.log("href", href);

        let domainName = process.env.REACT_APP_PDFURL;
        let midPath = "demo-e-library-pdf"
        if (href.indexOf("http") >= 0) {
          //this is an old link and has the full path along with domain name
          let parts = href.split("/");
          href = parts[parts.length - 1];
        }
        let finalURL = domainName + midPath + "/" + href;
        // console.log("href", href);
        // console.log("finalURL", finalURL);
        // window.open(finalURL, '_self', 'noreferrer');

        setTimeout(function () { window.open(finalURL, '_self', 'noreferrer'); }, 1000);

      };
    }

    return {
      onAnnotationLayerRender: findLinks,
    }
  }

  const openLinksPluginInstance = openLinksPlugin();

  // const findLinks = (e) => {
  //   e.container.querySelectorAll('a[data-target="external"]').forEach((link) => {
  //     link.addEventListener('click', handleClick);
  //   });
  // };

  /* const openLinksPlugin = () => {
    const store = React.useMemo(() => createStore < StoreProps > ({}), []);
  }; */


  const zoomPluginInstance = zoomPlugin();

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

  let noteId = notes.length;

  const renderHighlightTarget = (props) => {
    const highlightContent = () => {
      const note = {
        id: ++noteId,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText
      };
      setNotes([...notes, note]);
      props.toggle();
    };
    return (
      <div
        style={{
          background: "#eee",
          display: "flex",
          position: "absolute",
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: "translate(0, 8px)",
        }}
      >
        <Tooltip
          position={Position.TopCenter}
          target={
            <Button onClick={highlightContent}>
              <MessageIcon />
            </Button>
          }
          content={() => <div style={{ width: "100px" }}>Highlight</div>}
          offset={{ left: 0, top: -8 }}
        />
      </div>
    );
  };

  /* PDF Highlights  */
  const renderHighlights = (props) => (
    <div>
      {notes.map((note) => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter((area) => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
              />
            ))}
        </React.Fragment>
      ))}
    </div>
  );

    /* selection off a PDF file */
  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlights
  });

  /* PDF file rendering */
  const renderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        <div style={{ userSelect: 'none' }}>
          {props.textLayer.children}
        </div>
        {props.annotationLayer.children}
      </>
    );
  };

  /* back to previous page */
  const backToPrevious = async () => {
    // return

    let goPageDetails = localStorage.getItem('goToDetailsPage');

    if (goPageDetails != null && goPageDetails != undefined && goPageDetails != '') {
      backEventCall = JSON.parse(goPageDetails).goOtherPage
    }

    if (backEventCall == 1) {
      dispatch(postStoreElibraryTimeSpendDetails(elibraryItem.subject_id, _visitTime, elibraryItem.short_code, history));
      _visitTime = 0
      dispatch(elibraryGetLastsubjectListAction(0));
      const pageDetails = {
        goOtherPage: 0
      }
      localStorage.setItem('goToDetailsPage', JSON.stringify(pageDetails));
    }
    

    if (backEventCall == undefined || backEventCall == 0) {
      dispatch(elibraryGetLastsubjectListAction(1));
    }

    history.goBack()
  }

  const handleDocumentLoad = (e) => {
    setDocKeywords({
      isDocumentLoaded: true,
      // keywords
    });
  };

  /* registration modal close */
  const closeConceptRegistrationModal = () => {
    setConceptRegistrationModal(false)
  }

  /* go to registraction page */
  const registeredNow = () => {
    setConceptRegistrationModal(false)
    dispatch(logout(history));
    history.replace("/page-register");
  }



  return (
    <>
      <div className={`App ${isFullScreen ? "fullscreen" : ''}`}>
        <div className="pdf_fullScreen">
          <div className="top_back_container_pdf" onClick={() => backToPrevious()}>
            <span className="back_arrow"><i className="bi bi-chevron-left"></i></span>
            Back 
          </div>
          {ElibraryCategory[1] == "Demo" ?
            ElibraryCategory.length > 0 ?
              <div>
                
                <h5>{ElibraryCategory[0]} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {ElibraryCategory[1]} </h5>
              </div>
              : null
            :
            ElibraryScholasticCategory.length > 0 && ElibraryScholasticCategory[0] != '' ?
              <div>
                <h5>{ElibraryScholasticCategory[1]} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {ElibraryScholasticCategory[2]} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {ElibraryScholasticCategory[0]?.branch_name == ElibraryScholasticCategory[2] ? null : <>{ElibraryScholasticCategory[0]?.branch_name} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i></>} {ElibraryScholasticCategory[0]?.sub_heading}</h5>
              </div>
              : ElibraryCategory.length <= 0 && elibraryTitle == "Demo" ?
                <div>
                  
                  <h5>{eCategory} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {elibraryTitle} </h5>
                </div>
                : ElibraryScholasticCategory.length <= 0 && elibraryTitle == "" ?
                  <div>
                    
                    <h5>{eCategory} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {elibrarySubjectName} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {elibraryItem.branch_name == elibrarySubjectName ? null : <>{elibraryItem.branch_name} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i></>} {elibraryItem.sub_heading}</h5>
                  </div> :
                  ElibraryScholasticCategory.length > 0 && ElibraryScholasticCategory[0] == '' ?
                    <div>
                      
                      <h5>{ElibraryScholasticCategory[1]} <i className="fa fa-angle-right assessment_icon_color" aria-hidden="true"></i> {`Demo`} </h5>
                    </div>
                    :
                    null
          }

          <div className="pdf_right_side_container cursorPointer" onClick={() => fullScreeTtoggleChange()}>
            <div className="fullScreen">
              {!isFullScreen ? <i className="bi bi-arrows-fullscreen text_blue"></i> : <i className="bi bi-fullscreen-exit text_blue"></i>}

            </div>
            <div className="icon_description text_blue">
              {!isFullScreen ? "Full Screen" : "Normal Screen"}
            </div>
          </div>
        </div>
        
        {pdfUrl != undefined && pdfUrl != '' ?
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js">
            <div
              style={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                padding: '4px',
              }}
            >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
            <div className="pdf_viewer_container"
            // style={{ height: "700px", border: "1px solid rgba(250, 0, 250, 1)" }}
            // style={{ height: ("100vh" - "100px"), border: "1px solid rgba(250, 0, 250, 1)" }}
            >
              <Viewer
                // onDocumentLoad={handleDocumentLoad}
                initialPage={showFirstPageView}
                fileUrl={pdfUrl}
                renderPage={renderPage}
                // plugins={[zoomPluginInstance]}
                plugins={[openLinksPluginInstance, highlightPluginInstance, zoomPluginInstance]}
              // plugins={[openLinksPluginInstance, zoomPluginInstance]}
              // plugins={[zoomPluginInstance]}
              /* plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
            ]} */
              />
            </div>
          </Worker>
          : null}
      </div>
      <Modal className="fade" show={registrationModal} onHide={() => setRegistrationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="modal_title">Hello { }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="readDisplay user_decision">
              To access “Ask Me” for framing question and getting the solution, please subscribe to get your own course materials.
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_right_container show_right">
            <Button
              variant="secondary text-center m-1"
              onClick={() => history.push({ pathname: "/dashboard" })}
            >
              Cancel
            </Button>
            <Button
              variant="warning text-center m-1"
              onClick={() => history.replace({ pathname: "/page-register" })}
            >
              Registration
            </Button>
          </div>
        </Modal.Footer>
      </Modal>


      <Modal className="fade" show={conceptRegistrationModal} size="lg" onHide={() => setConceptRegistrationModal(false)}>

        <Modal.Header closeButton>
          <Modal.Title className="modal_title">Hello Guest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            To access the hyperlink for viewing the detailed content please register to get your credentials.
          </div>

        </Modal.Body>

        <Modal.Footer>
          <div className="modal_footer_container_info">
            <div className="modal_footer_right_container">

              <Button
                variant="danger text-center modal_footer_button"
                onClick={() => closeConceptRegistrationModal()}
              >
                Close
              </Button>

              <Button
                variant="warning text-center modal_footer_button1"
                onClick={registeredNow}
                disabled={showLoading ? true : false}
              >
                Register Now
              </Button>


            </div>
          </div>

        </Modal.Footer>
      </Modal>
      <div><ToastContainer /></div>
    </>
  );
}
